export enum CommonColumnIdsEnum {
  productAllocation = 'productAllocation',
  assetAllocation = 'assetAllocation',
  purchasingPower = 'purchasingPower',
  aus = 'aus',
  loansOutstanding = 'loansOutstanding',
  brokerageAssets = 'brokerageAssets',
  aum = 'aum',
  annuity = 'annuity',
  legalEntityName = 'legalEntityName',
  positions = 'positions',
  netNewMoney = 'netNewMoney',
  netNewAssets = 'netNewAssets',
  team = 'team',
  clientAdvisor = 'clientAdvisor',
  clientAdvisorId = 'clientAdvisorId',
  householdId = 'householdId',
  householdName = 'householdName'
}
