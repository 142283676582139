import { css } from '@emotion/react'
import { useMemo } from 'react'
import { theme } from 'shared/theme'
import { constants } from './theme'

const getTableStyles = () => {
  const table = css({
    tableLayout: 'fixed',
    minWidth: 0,
    width: '100%',
    boxSizing: 'border-box',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    ['th, td']: {
      backgroundClip: 'padding-box'
    }
  })

  const cell = css({
    padding: '10px 8px',
    verticalAlign: 'top',
    '&:first-of-type': {
      paddingLeft: '25px'
    },
    '&:last-of-type': {
      paddingRight: '25px'
    }
  })

  const headerRow = css({
    th: [
      cell,
      {
        paddingTop: '20px',
        paddingBottom: '20px'
      }
    ]
  })

  const subHeaderRow = css({
    border: '1px solid #EBEBEB',
    background: ' #F9FBFC',
    th: cell
  })

  const bodyRow = css({
    td: cell
  })

  const subRow = css({
    border: '1px solid rgba(19, 39, 43, 0.08)'
  })

  const subTableContainerCell = css({
    border: 'none',
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent'
  })

  const l1GroupRow = css({
    td: {
      paddingTop: '20px',
      paddingBottom: '20px'
    }
  })

  const l2GroupRow = css({
    td: {
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  })

  const sizesRow = css({
    '& > th': {
      border: 0,
      padding: 0,
      margin: 0,
      height: 0
    }
  })

  const groupsContainer = css({
    padding: '5px 0'
  })

  const groupSection = css({
    marginBottom: '5px',
    boxSizing: 'border-box',
    '&:last-of-type': {
      marginBottom: 0
    }
  })

  const l2GroupSection = css({
    padding: '0 8px'
  })

  const headerContainer = css({
    position: 'sticky',
    top: constants.headerOffsetPx,
    zIndex: '2',
    marginBottom: '3px'
  })

  const icon = css({
    width: '12px',
    height: '12px',
    display: 'inline'
  })

  const cursorPointor = css({
    cursor: 'pointer'
  })

  const mrl = css({
    margin: '0 4px'
  })

  const nowrap = css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  })

  const tablePartition = css({
    'td:nth-of-type(7), th:nth-of-type(7)': {
      borderRight: `2px solid ${theme.colors.extraGray1}`
    },
    'td:nth-of-type(2), th:nth-of-type(2)': {
      borderLeft: `2px solid ${theme.colors.extraGray1}`
    }
  })

  return {
    table,
    headerRow,
    subHeaderRow,
    subRow,
    bodyRow,
    subTableContainerCell,
    sizesRow,
    groupsContainer,
    groupSection,
    l2GroupSection,
    l1GroupRow,
    l2GroupRow,
    headerContainer,
    icon,
    cursorPointor,
    mrl,
    nowrap,
    tablePartition
  }
}

const colors = {
  background: '#fff',
  rowBackground: '#fff',
  altRowBackground: '#F9FBFC',
  border: '#E6E6E6', // 'rgba(19, 39, 43, 0.08)'
  headerBackground: '#EDF1F4',
  headerBorder: '#EBEBEB',
  l1GroupBackground: '#EAF6FC',
  l2selectedGroupBackground: '#EAF6FC',
  l2GroupBackground: '#F3F7FA',
  text: '#676767',
  headerText: '#06262D',
  secondaryHeaderText: '#01486D',
  groupText: '#06262D',
  secondaryGroupText: '#01486D',
  lightText: '#7B7F80',
  linkText: '#377EA3',
  green: '#008000',
  l3HeaderBackground: '#F9FBFC',
  l3HeaderBorder: '#EBEBEB'
}

const getThemedStyles = () => {
  const bodyContainer = css({
    color: colors.text
  })

  const headerContainer = css({
    backgroundColor: colors.headerBackground,
    fontSize: '13px',
    fontWeight: '500',
    color: colors.headerText,
    border: `solid 1px ${colors.headerBorder}`
  })

  const tableBody = css({
    backgroundColor: colors.background
  })

  const groupRow = css({
    fontSize: '13px',
    fontWeight: '500',
    color: colors.groupText,
    td: {
      borderBottom: `solid 1px ${colors.border}`,
      borderTop: `solid 1px ${colors.border}`,
      '&:first-of-type': {
        borderLeft: `solid 1px ${colors.border}`
      },
      '&:last-of-type': {
        borderRight: `solid 1px ${colors.border}`
      }
    }
  })

  const groupRowExpanded = css({
    td: {
      borderBottom: `solid 1px ${colors.border}`
    }
  })

  const l1GroupRow = css({
    backgroundColor: colors.l1GroupBackground
  })

  const l2GroupRow = css({
    backgroundColor: colors.l2GroupBackground
  })

  const securityRow = css({
    fontSize: '12px',
    fontWeight: '500',
    td: {
      borderBottom: `solid 1px ${colors.border}`
    },
    '&:last-of-type td': {
      borderBottom: 'none'
    },
    '&:nth-of-type(odd) td': {
      backgroundColor: colors.rowBackground
    },
    '&:nth-of-type(even) td': {
      backgroundColor: colors.altRowBackground
    }
  })

  const bodyRow = css({
    fontSize: '13px',
    '&& td': {
      verticalAlign: 'middle'
    }
  })

  const subTableContainerCell = css({
    border: `solid 1px ${colors.border}`,
    borderTop: 'none'
  })

  const totalRow = css({
    '&& td': {
      backgroundColor: '#CDE2F2',
      paddingBottom: 22,
      fontWeight: '600',
      paddingTop: 22
    }
  })

  return {
    subTableContainerCell,
    bodyContainer,
    tableBody,
    l1GroupRow,
    l2GroupRow,
    securityRow,
    groupRow,
    groupRowExpanded,
    bodyRow,
    headerContainer,
    totalRow
  }
}

export const rdot360TableStyles = getTableStyles()
export const useRdot360ThemedTableStyles = () => {
  const styles = useMemo(() => getThemedStyles(), [])
  return styles
}
