import { CDMv2Account } from 'api/nickname.types'
import { HighlightSearchText } from './HighlightSearchText'

export const AddressRow: React.FC<{
  addressLine?: string
  searchText?: string
}> = ({ addressLine, searchText }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'row' }}>
      {addressLine && (
        <HighlightSearchText text={addressLine} search={searchText} />
      )}
    </div>
  )
}

export const AccountStencil: React.FC<{
  account?: CDMv2Account
  searchText?: string
}> = ({ account, searchText }) => {
  const address = account?.addressMappings?.[0]?.address
  return (
    address && (
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <AddressRow
          addressLine={address?.addressLine1}
          searchText={searchText}
        />
        <AddressRow
          addressLine={address?.addressLine2}
          searchText={searchText}
        />
        <AddressRow
          addressLine={address?.addressLine3}
          searchText={searchText}
        />
        <AddressRow
          addressLine={address?.addressLine4}
          searchText={searchText}
        />
        <AddressRow
          addressLine={address?.addressLine5}
          searchText={searchText}
        />
        <AddressRow
          addressLine={address?.addressLine6}
          searchText={searchText}
        />
      </div>
    )
  )
}
