import { datahubApi } from 'store/api/datahub'

export interface IPeerCompAnalyticsResponse {
  meta?: ITeamAnalyticsMeta
  peerGroupDefinition?: PeerGroupDefinition
  teamWithRank?: ITeamAnalyticsWithRank
  peers?: ITeamAnalyticsTeam
  division?: ITeamAnalyticsTeam
  pwm?: ITeamAnalyticsTeam
}
export interface ITeamAnalyticsMeta {
  peers: string[]
  division: string[]
  pwm: string[]
  asOfDate: string
  minDate: Date
}
export interface PeerGroupDefinition {
  name: string
  min: number
  max: number
}
export interface ITeamAnalyticsWithRank {
  team?: ITeamAnalyticsTeam
  aumRank?: ITeamAnalyticsRank
  t12RevenueRank?: ITeamAnalyticsRank
  annualizedRevenueRank?: ITeamAnalyticsRank
}
export interface ITeamAnalyticsRank {
  teamName?: string
  total?: number
  totalRank?: number
  conversionRate?: number
  conversionRateRank?: number
}
export interface ITeamAnalyticsTeam {
  totalBorrowingCertificateAUM?: number
  teamName?: string
  aumDetHistory: IAumCategoryItem[]
  t12RevenueDetHistory: IRevenueCategoryItem[]
  annualizedRevenueDetHistory: IRevenueCategoryItem[]
  totalProdRevenue?: number
  onBoardingDate?: string
  regionName?: string
  dataAsOf?: string
}

export interface IAumCategoryItem {
  productType?: string
  accountType?: string
  aum?: number
}
export interface IRevenueCategoryItem {
  assetType?: string
  revenue?: number
}

export interface ITeam {
  teamName: string
  regionName: string
}

export interface ISummaryItem {
  category?: string
  marketvalue?: number
  percentOfTotal?: number
}

export interface IBusinessSummary {
  asOfDate?: string
  totalAum?: number
  managedTotal?: number
  brokerageTotal?: number
  aiTotal?: number
  otherTotal?: number
  managedSplit?: ISummaryItem[]
  managedGrouped?: ISummaryItem[]
  brokerageSplit?: ISummaryItem[]
  brokerageGrouped?: ISummaryItem[]
  managedAi?: ISummaryItem[]
  brokerageAi?: ISummaryItem[]
  other?: ISummaryItem[]
  allGroups?: ISummaryItem[]
}

export interface IYTDNMM {
  teamName: string
  division?: string
  aus?: number
  organicGrowth?: number
  perc?: number
  dataAsOf?: string
}

export interface ISegmentation {
  team?: ISegmentationResponse[]
  division?: ISegmentationResponse[]
  segment?: ISegmentationResponse[]
  pwm?: ISegmentationResponse[]
  teamName?: string
  divisionName?: string
  segmentName?: string
}
export interface ISegmentationResponse {
  entityName?: string
  hhSegmentId?: number
  envBillableValue?: number
  envClientFee?: number
  envVelocity?: number
  envVelocityAsOf?: string
  advMetricsAus?: number
  advMetricsAum?: number
  advMetricsHhSegmentCount?: number
  advMetricsAsOf?: string
  t12Revenue?: number
}

export interface IAllProducts {
  hhSegmentName?: string
  hhSegmentId?: number
  advMetricsHhSegmentCount?: number
  hhPerc?: number
  ausPerc?: number
  pwmPerc?: number
  t12Perc?: number
  t12PwmPerc?: number
  feeBasedAumPerc?: number
  feeBasedPwm?: number
}
export interface IManagedOnly {
  hhSegmentName?: string
  hhSegmentId?: number
  feeBasedBPS?: number
  PWMBPS?: number
  DivisionalBPS?: number
  DeltavsPWM?: number
  DeltavsDivisional?: number
  DeltavsSegment?: number
  TeamSegmentVelocity?: number
}

export interface IColumnTotal {
  velocityTotal?: number
  deltavsPWMTotal?: number
  deltavsDivisonTotal?: number
  deltavsSegmentTotal?: number
  pwmVelocityTotal?: number
  divisonVelocityTotal?: number
  segmentVelocityTotal?: number
}
export type TeamsResponse = ITeam[]

export const peerReviewAnalyticsApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<TeamsResponse, void>({
      query: () => `teams/visibility`
    }),
    getPeerGroupAnalyticsByTeamName: builder.query<
      IPeerCompAnalyticsResponse,
      { teamName: string; asOfdate?: Date }
    >({
      query: (payload) =>
        `teams/${payload.teamName}/peerGroupAnalytics${
          payload.asOfdate ? `d=${payload.asOfdate.toISOString()}` : ''
        }`
    }),
    getBusinessSummaryByTeamName: builder.query<
      IBusinessSummary,
      { teamName: string }
    >({
      query: (payload) => `teams/${payload.teamName}/businessSummary`
    }),
    getPWMAverageBusinessSummary: builder.query<IBusinessSummary, undefined>({
      query: () => `teams/pwmAverageSummary`
    }),
    getSegmentationByTeamName: builder.query<
      ISegmentation,
      { teamName: string }
    >({
      query: (payload) => `teams/${payload.teamName}/segmentationData`
    }),
    getYTDNMMSummary: builder.query<IYTDNMM[], undefined>({
      query: () => `teams/YTDNMM`
    })
  })
})

export const {
  useGetTeamsQuery,
  useGetPeerGroupAnalyticsByTeamNameQuery,
  useGetBusinessSummaryByTeamNameQuery,
  useGetPWMAverageBusinessSummaryQuery,
  useGetSegmentationByTeamNameQuery,
  useGetYTDNMMSummaryQuery
} = peerReviewAnalyticsApi
