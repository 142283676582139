import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { ISystemViewDefinition } from 'features/Lists/hooks/useListPreferences'
import { constructInitialPositionListColumnState } from 'features/Lists/positions'
import { PositionColumnIds } from 'features/Lists/positions/ColumnDefinitions'
import { getAiDashboardDefaulViewtFilters } from '../getListFilters'

const initialColumns: (keyof PositionColumnIds)[] = [
  'householdName',
  'accountNumber',
  'partyname',
  'symbolCusipDesc',
  'securitySubtype',
  'assetTypeDetail',
  'DrawdownNonDrawdown',
  'vintageYear',
  'capitalCommitment',
  'CapitalCall',
  'Distributions',
  'TotalIncome',
  'RecallableDistributions',
  'Unfunded_Value',
  'ai_dividends_cash',
  'ai_dividends_reinvest',
  'marketvalue',
  'TotalValue',
  'tvpi',
  'NetIRRAnnualized',
  'NetIRRCumulative',
  'originalOrderDate',
  'OriginalOrderAmount',
  'RemainingOriginalAmount',
  'marketvalue',
  'ValuationDate',
  'ai_valuationReceivedDate',
  'netInvestmentGainLoss',
  'ai_totalReturnPercentage',
  'ai_expectedTiming',
  'ai_k1EstimateAvailable',
  'ai_taxReporting',
  'OnPlatform_OffPlatform'
]

const defaultUiState: IListsUiState = {
  searchText: '',
  columnState: constructInitialPositionListColumnState(initialColumns),
  filters: getAiDashboardDefaulViewtFilters(),
  orderBy: {
    columnId: PositionColumnIds.partyname,
    direction: 'asc'
  }
}

export const combinedSystemViews = [
  {
    key: 'COMBINED_DEFAULT_SYSTEM_VIEW',
    label: 'Default System View',
    config: defaultUiState
  }
] as ISystemViewDefinition[]
