import { format, isFuture } from 'date-fns'
import { PaymentMM } from '../../Dashboard/modules/Dashboard/store/types'
import { IMMSummarySI } from '../api/types'
import { RecipientType } from '../EFT/Constants'
import { JournalTransferTypeList } from '../Journal/Constants'
import {
  IJournalInitiationForm,
  IJournalRequest,
  IMMConfig
} from '../store/Journal/types'
import { IPeriodicPayment } from '../store/PeriodicPayment/types'
import {
  IMMValidateRequest,
  IPastPayment,
  ISenderFormDetails,
  ITransInfo,
  IVerificationDetails
} from '../store/types'
import { getCommonValidateRequest, processDate } from './Utilities'
import { getPeriodicPaymentDataForEdit } from './UtilitiesPeriodicPmt'

export const prepareJournalDataFromPastPaymnet = (
  item: IPastPayment,
  senderDetails?: ISenderFormDetails,
  config?: IMMConfig,
  periodicPayment?: IPeriodicPayment
): IJournalInitiationForm => {
  return {
    senderDetails: senderDetails,
    periodicPayment: periodicPayment,
    executingCustodian: item?.executingCustodian || config?.custodianID,
    journalTransferType: {
      partialOrFull: undefined,
      withinAccount: item?.tranInfo?.withinAccount,
      withResiduals: item?.tranInfo?.withResiduals,
      fromMonetaryType:
        item?.tranInfo?.fromCashType ?? config?.fromMonetaryType,
      isBlindJournal: item?.tranInfo?.blindCode === 'F',
      giftInheritanceCode: item?.tranInfo?.giftCode,
      fromAdditionalDescription: item?.tranInfo?.memo
    },
    journalDetails: {
      toAccount: item?.tranInfo?.beneficiaryAcctId,
      toAccountRegType: item?.tranInfo?.beneficiaryAcctType || '',
      isToRetirementAccount: item?.tranInfo?.isToAccountIRA,
      toName: item?.tranInfo?.beneficiaryName,
      toAddressLine1: item?.tranInfo?.beneficiaryNameAddrLine1,
      toAddressLine2: item?.tranInfo?.beneficiaryNameAddrLine2,
      toAddressLine3: item?.tranInfo?.beneficiaryNameAddrLine3,
      toMonetaryType: item?.tranInfo?.toCashType ?? config?.toMonetaryType,
      statementCode: item?.tranInfo?.statementCode,
      toAdditionalDescription: item?.tranInfo?.memoto
    },

    wireAmount: {
      currency: 'USD',
      recipientType: getrecipient(
        item?.tranInfo?.isThirdPartyCommon,
        item?.tranInfo?.isThirdParty
      ),
      useExistingPP: true,
      useExistingSI: false
    }
  }
}

export const prepareJournalDataFromSI = (
  item: IMMSummarySI,
  senderDetails?: ISenderFormDetails,
  periodicPayment?: IPeriodicPayment,
  executingCustodian?: string
): IJournalInitiationForm => {
  return {
    senderDetails: senderDetails,
    periodicPayment: periodicPayment,
    wireAmount: {
      currency: 'USD',
      useExistingSI: true,
      useExistingPP: false,
      standingInstructionId: item?.standinginstructionid,
      recipientType: getrecipient(item?.isthirdpartycommon, item?.isthirdparty)
    },
    journalDetails: {
      toAccount: item?.bankaccountnumber,
      toName: item?.beneficiarynameline1,
      toAddressLine1: item?.beneficiarynameline2,
      toAddressLine2: item?.beneficiarynameline3,
      toAddressLine3: item?.beneficiarynameline3
    },
    journalTransferType: {},
    executingCustodian: executingCustodian
  }
}

export const generateMMValidateRequestForJournal = (
  journalData: IJournalInitiationForm
): IMMValidateRequest => {
  const today = new Date()
  const currentYear = format(today, 'yyyy')
  const prevYear = (Number(currentYear) - 1).toString()
  const isFromIRA = journalData?.senderDetails?.isRetirementAccount
  const isToIRA = journalData?.journalDetails?.isToRetirementAccount
  const request: IMMValidateRequest = {
    ...getCommonValidateRequest(
      journalData?.senderDetails?.account,
      journalData?.executingCustodian
    ),
    ismodeone: false,
    isjnl: true,
    fromaccount: {
      accountnumber: journalData?.senderDetails?.account || undefined,
      isira: isFromIRA || false
    },
    toaccount: {
      accountnumber: journalData?.journalDetails?.toAccount || undefined,
      accountname: journalData?.journalDetails?.toName || undefined,
      bankidtype: 'F',
      abanumber: undefined,
      swiftcode: undefined,
      bankname: undefined,
      isira: isToIRA || false,
      isthirdparty:
        journalData?.wireAmount?.recipientType === RecipientType.ThirdParty
          ? true
          : false,
      isthirdpartycommon:
        journalData?.wireAmount?.recipientType ===
        RecipientType.ThirdPartyCommon,
      // isexternal: true,
      siid: journalData?.wireAmount?.standingInstructionId,
      isprioryear:
        journalData?.taxWithholdings?.conPromptYearInd === 'Y' &&
        journalData?.taxWithholdings?.year === prevYear
          ? true
          : false
    },
    amount: Number(journalData?.wireAmount?.amount) || 0,

    closeout: getCloseoutValue(journalData),
    CurrencyType: 'USD',
    destinationcountry: 'US',
    transactiondate: processDate(journalData),
    isira: isFromIRA || isToIRA,
    overrideReason: journalData?.wireAmount?.hasInsufFund
      ? journalData?.wireAmount?.insufFundSolutionId
      : 'na',
    blindCode: journalData?.journalTransferType?.isBlindJournal
      ? 'F'
      : undefined,
    giftCode:
      journalData?.journalTransferType?.withinAccount === 'Y'
        ? ''
        : journalData?.journalTransferType?.giftInheritanceCode,
    statementCode: journalData?.journalDetails?.statementCode,
    fromCashType: journalData?.journalTransferType?.fromMonetaryType,
    toCashType: journalData?.journalDetails?.toMonetaryType
  }
  if (journalData?.journalTransferType?.withinAccount === 'Y') {
    return request
  }
  if (!isFromIRA && isToIRA) {
    request.retcontribreason = journalData?.taxWithholdings?.conReasonCode
      ? [
          {
            reasoncode: journalData?.taxWithholdings?.conReasonCode,
            reasondesc: journalData?.taxWithholdings?.conReasonDesc,
            reasonsubcode: journalData?.taxWithholdings?.conReasonSubcode,
            promptyearind: journalData?.taxWithholdings?.conPromptYearInd
          }
        ]
      : undefined
    request.currentyear =
      journalData?.taxWithholdings?.conPromptYearInd === 'Y' &&
      journalData?.taxWithholdings?.year === currentYear
        ? currentYear
        : undefined

    request.prioryear =
      journalData?.taxWithholdings?.conPromptYearInd === 'Y' &&
      journalData?.taxWithholdings?.year === prevYear
        ? prevYear
        : undefined
  } else if (isFromIRA || isToIRA) {
    request.fedwithholdpct = journalData?.taxWithholdings?.fedTaxRate
      ? Number(journalData?.taxWithholdings?.fedTaxRate)
      : undefined
    request.statewithholdpct = journalData?.taxWithholdings?.stateTaxRate
      ? Number(journalData?.taxWithholdings?.stateTaxRate)
      : undefined
    request.istrnamtincludetaxwithhold = !(
      journalData?.taxWithholdings?.includeTax || false
    )
    request.fedwithholdminpct = journalData?.taxWithholdings?.fedwithholdminpct
    request.fedwithholdmaxpct = journalData?.taxWithholdings?.fedwithholdmaxpct
    request.statewithholdminpct =
      journalData?.taxWithholdings?.statewithholdminpct
    request.statewithholdmaxpct =
      journalData?.taxWithholdings?.statewithholdmaxpct
    // request.AMAacknowledgement =
    //   journalData?.taxWithholdings?.AMAacknowledgement
    request.AMAacknowledgement =
      journalData?.taxWithholdings?.retDistCode !== '3' &&
      journalData?.taxWithholdings?.retDistCode !== 'G'
        ? journalData?.taxWithholdings?.AMAacknowledgement
        : undefined
    request.retdistreason = journalData?.taxWithholdings?.retDistReason
      ? [
          {
            reasoncode: journalData?.taxWithholdings?.retDistCode,
            reasondesc: journalData?.taxWithholdings?.retDistReason,
            reasonsubcode: journalData?.taxWithholdings?.retDistSubCode
          }
        ]
      : undefined
  }

  return request
}

type createJournalRequestProps = {
  journalInfo: IJournalInitiationForm
  approvalDetail?: IVerificationDetails
  status?: string
  ismarginpaperworkrequired?: boolean
}

export const createJournalRequest = ({
  journalInfo,
  approvalDetail = {},
  status = 'DRFT',
  ismarginpaperworkrequired = false
}: createJournalRequestProps) => {
  const request: IJournalRequest = {
    mmInstID: journalInfo?.wirePaymentId,
    ExecutingCustodian: journalInfo?.executingCustodian,
    tranInfo: {
      SrvTrnID: 0,
      ExecutingCustodian: journalInfo?.executingCustodian,
      isRecurringPaymentPlan:
        journalInfo?.periodicPayment?.isRecurringPaymentPlan,
      availablecashtowithdraw:
        journalInfo?.senderDetails?.availablecashtowithdraw || undefined,
      UseExistingSI: journalInfo?.wireAmount?.useExistingSI || false,
      StandingInstructionId: journalInfo.wireAmount?.standingInstructionId,
      IsThirdParty:
        journalInfo?.wireAmount?.recipientType === RecipientType.ThirdParty,
      isThirdPartyCommon:
        journalInfo?.wireAmount?.recipientType ===
        RecipientType.ThirdPartyCommon,
      BankAcctFromAcctId: journalInfo?.senderDetails?.account || '',
      BankAcctFromName: journalInfo?.senderDetails?.name || '',

      BeneficiaryAcctId: journalInfo?.journalDetails?.toAccount || '',
      BeneficiaryName: journalInfo?.journalDetails?.toName || '',
      BeneficiaryNameAddrLine1:
        journalInfo?.journalDetails?.toAddressLine1 || '',
      BeneficiaryNameAddrLine2:
        journalInfo?.journalDetails?.toAddressLine2 || '',
      BeneficiaryNameAddrLine3:
        journalInfo?.journalDetails?.toAddressLine3 || '',
      CreateNewSI: journalInfo?.journalDetails?.createNewSI || false,
      Ismarginpaperworkrequired: ismarginpaperworkrequired,

      BankAcctFromAcctType: journalInfo?.senderDetails?.registrationtype,
      BeneficiaryAcctType: journalInfo?.journalDetails?.toAccountRegType,
      isfuturedated: false,
      isIRA: journalInfo?.senderDetails?.isRetirementAccount,
      isToAccountIRA: journalInfo?.journalDetails?.isToRetirementAccount,

      TrnAmt: parseFloat(journalInfo?.wireAmount?.amount || '0'),
      AMAacknowledgement:
        journalInfo?.taxWithholdings?.retDistCode !== '3' &&
        journalInfo?.taxWithholdings?.retDistCode !== 'G'
          ? journalInfo?.taxWithholdings?.AMAacknowledgement
          : undefined,
      Memo: journalInfo?.journalTransferType?.fromAdditionalDescription,
      Memoto: journalInfo?.journalDetails?.toAdditionalDescription,
      TrnDateTime: journalInfo?.wireAmount?.tranDate
        ? format(journalInfo?.wireAmount?.tranDate, 'MM/dd/yyyy')
        : '',
      UseType9Income: journalInfo?.wireAmount?.useType9Income,
      AccountCloseout:
        journalInfo?.journalTransferType?.partialOrFull ===
          JournalTransferTypeList.Full &&
        journalInfo?.journalTransferType?.withResiduals === 'Y',
      isPartial:
        journalInfo?.journalTransferType?.partialOrFull ===
        JournalTransferTypeList.Partial,
      withinAccount:
        journalInfo?.journalTransferType?.partialOrFull ===
        JournalTransferTypeList.Partial
          ? journalInfo?.journalTransferType?.withinAccount
          : undefined,

      withResiduals:
        journalInfo?.journalTransferType?.partialOrFull ===
        JournalTransferTypeList.Full
          ? journalInfo?.journalTransferType?.withResiduals ?? 'N'
          : undefined,
      fromCashType: journalInfo?.journalTransferType?.fromMonetaryType,
      toCashType: journalInfo?.journalDetails?.toMonetaryType,
      statementCode: journalInfo?.journalDetails?.statementCode,
      journalType: 'Cash Only',
      giftCode: journalInfo?.journalTransferType?.giftInheritanceCode,
      blindCode: journalInfo?.journalTransferType?.isBlindJournal
        ? 'F'
        : undefined,
      overrideReason: journalInfo?.wireAmount?.hasInsufFund
        ? journalInfo?.wireAmount?.insufFundSolutionId
        : 'na',
      userEditedTransaction: journalInfo?.userEditedTransaction
    },
    expirationDate:
      journalInfo?.wireAmount?.tranDate &&
      isFuture(journalInfo?.wireAmount?.tranDate) &&
      journalInfo?.wireAmount?.expirationDate
        ? format(journalInfo?.wireAmount?.expirationDate, 'MM/dd/yyyy')
        : '',
    apprDetail: approvalDetail,
    intlMMinfo: {
      toCurrency: 'USD',
      USDAmount: parseFloat(journalInfo?.wireAmount?.amount || '0')
    },
    mmStatus: status,
    xferType: 'JNL'
  }
  if (journalInfo?.journalTransferType?.withinAccount === 'Y') {
    return request
  }
  const today = new Date()
  const currentYear = format(today, 'yyyy')
  const prevYear = (Number(currentYear) - 1).toString()
  const isFromIRA = journalInfo?.senderDetails?.isRetirementAccount
  const isToIRA = journalInfo?.journalDetails?.isToRetirementAccount
  const showContrib = !isFromIRA && isToIRA
  const showJournalCode = isFromIRA && isToIRA
  // const showDistReason = isFromIRA && !isToIRA
  if (showContrib) {
    const taxWithholdings: ITransInfo = {
      conreasoncode: journalInfo?.taxWithholdings?.conReasonCode,
      conreasonsubcode: journalInfo?.taxWithholdings?.conReasonSubcode,
      conpromptyearind: journalInfo?.taxWithholdings?.conPromptYearInd,
      conreasondesc: journalInfo?.taxWithholdings?.conReasonDesc,
      currentyear:
        journalInfo?.taxWithholdings?.conPromptYearInd === 'Y' &&
        journalInfo?.taxWithholdings?.year === currentYear
          ? currentYear
          : '',

      prioryear:
        journalInfo?.taxWithholdings?.conPromptYearInd === 'Y' &&
        journalInfo?.taxWithholdings?.year === prevYear
          ? prevYear
          : undefined
    }
    request.tranInfo = { ...request.tranInfo, ...taxWithholdings }
  } else if (isFromIRA || isToIRA) {
    const taxWithholdings: ITransInfo = {
      canfedwithhold: journalInfo?.taxWithholdings?.fedTaxOption || undefined,
      canstatewithhold:
        journalInfo?.taxWithholdings?.stateTaxOption || undefined,
      fedwithholdpct: Number(journalInfo?.taxWithholdings?.fedTaxRate || 0),
      statewithholdpct: Number(journalInfo?.taxWithholdings?.stateTaxRate || 0),
      fedwithholdmaxpct: Number(
        journalInfo?.taxWithholdings?.fedwithholdmaxpct || 0
      ),
      fedwithholdminpct: Number(
        journalInfo?.taxWithholdings?.fedwithholdminpct || 0
      ),
      statewithholdmaxpct: Number(
        journalInfo?.taxWithholdings?.statewithholdmaxpct || 0
      ),
      statewithholdminpct: Number(
        journalInfo?.taxWithholdings?.statewithholdminpct || 0
      ),
      istrnamtincludetaxwithhold: !(
        journalInfo?.taxWithholdings?.includeTax || false
      ),
      totalamtincludetaxwithhold:
        journalInfo?.taxWithholdings?.totalamtincludetaxwithhold,
      statewithholdamt: journalInfo?.taxWithholdings?.statewithholdamt,
      fedwithholdamt: journalInfo?.taxWithholdings?.fedwithholdamt,
      reasoncode: journalInfo?.taxWithholdings?.retDistCode,
      reasondesc: journalInfo?.taxWithholdings?.retDistReason,
      reasonsubcode: journalInfo?.taxWithholdings?.retDistSubCode,

      reasonForJournal: showJournalCode
        ? journalInfo?.taxWithholdings?.retDistCode
        : undefined
    }
    request.tranInfo = { ...request.tranInfo, ...taxWithholdings }
  }

  return request
}

export const prepareJournalDataForEdit = (
  paymentMM: PaymentMM | undefined
): IJournalInitiationForm => {
  const formData = {
    wirePaymentId: paymentMM?.mmInstID,
    pmmId: paymentMM?.pmmID,
    paymentType: paymentMM?.tranType,
    executingCustodian:
      paymentMM?.executingCustodian || paymentMM?.tranInfo?.executingCustodian,
    docInfo: paymentMM?.docInfo,
    mmStatusDescription: paymentMM?.mmStatusDescription,
    mmStatus: paymentMM?.mmStatus,
    hasRecurringPaymentToday: paymentMM?.hasRecurringPaymentToday,
    senderDetails: {
      account: paymentMM?.tranInfo?.bankAcctFromAcctId || '',
      name: paymentMM?.tranInfo?.bankAcctFromName,
      isRetirementAccount: paymentMM?.tranInfo?.isIRA,
      availablecashtowithdraw: paymentMM?.tranInfo?.availablecashtowithdraw,
      registrationtype: paymentMM?.tranInfo?.bankAcctFromAcctType || ''
    },

    journalTransferType: {
      partialOrFull: paymentMM?.tranInfo?.isPartial
        ? JournalTransferTypeList.Partial
        : JournalTransferTypeList.Full,
      withinAccount: paymentMM?.tranInfo?.withinAccount,
      withResiduals: paymentMM?.tranInfo?.withResiduals,
      fromMonetaryType: paymentMM?.tranInfo?.fromCashType,
      isBlindJournal: paymentMM?.tranInfo?.blindCode === 'F',
      giftInheritanceCode: paymentMM?.tranInfo?.giftCode,
      fromAdditionalDescription: paymentMM?.tranInfo?.memo
    },
    journalDetails: {
      toAccount: paymentMM?.tranInfo?.beneficiaryAcctId,
      toAccountRegType: paymentMM?.tranInfo?.beneficiaryAcctType || '',
      isToRetirementAccount: paymentMM?.tranInfo?.isToAccountIRA,
      toName: paymentMM?.tranInfo?.beneficiaryName,
      toAddressLine1: paymentMM?.tranInfo?.beneficiaryNameAddrLine1,
      toAddressLine2: paymentMM?.tranInfo?.beneficiaryNameAddrLine2,
      toAddressLine3: paymentMM?.tranInfo?.beneficiaryNameAddrLine3,
      toMonetaryType: paymentMM?.tranInfo?.toCashType,
      statementCode: paymentMM?.tranInfo?.statementCode,
      toAdditionalDescription: paymentMM?.tranInfo?.memoto,
      createNewSI: paymentMM?.tranInfo?.createNewSI
    },

    wireAmount: {
      useExistingSI: paymentMM?.tranInfo?.useExistingSI,
      standingInstructionId: paymentMM?.tranInfo?.standingInstructionId,

      amount: paymentMM?.tranInfo?.trnAmt?.toString() || '',
      recipientType: getrecipient(
        paymentMM?.tranInfo?.isThirdPartyCommon,
        paymentMM?.tranInfo?.isThirdParty
      ),
      tranDate: paymentMM?.tranDate ? new Date(paymentMM?.tranDate) : undefined,
      expirationDate: paymentMM?.expirationDate
        ? new Date(paymentMM?.expirationDate)
        : undefined,
      useType9Income: paymentMM?.tranInfo?.useType9Income,
      accountCloseout: paymentMM?.tranInfo?.accountCloseout,
      insufFundSolutionId: paymentMM?.apprDetail?.insufFundSolution,
      insufFundComments: paymentMM?.apprDetail?.insufFund
    },
    taxWithholdings: {
      retDistCode: paymentMM?.tranInfo?.reasoncode,
      retDistReason: paymentMM?.tranInfo?.reasondesc,
      retDistSubCode: paymentMM?.tranInfo?.reasonsubcode,
      conReasonCode: paymentMM?.tranInfo?.conreasoncode,
      conReasonDesc: paymentMM?.tranInfo?.conreasondesc,
      conReasonSubcode: paymentMM?.tranInfo?.conreasonsubcode,
      conPromptYearInd: paymentMM?.tranInfo?.conpromptyearind,
      year: paymentMM?.tranInfo?.currentyear || paymentMM?.tranInfo?.prioryear,
      fedTaxOption: paymentMM?.tranInfo?.canfedwithhold,
      stateTaxOption: paymentMM?.tranInfo?.canstatewithhold,
      fedTaxPct: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxPct: paymentMM?.tranInfo?.statewithholdpct,
      fedTaxRate: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxRate: paymentMM?.tranInfo?.statewithholdpct,
      includeTax: !(paymentMM?.tranInfo?.istrnamtincludetaxwithhold || false),
      AMAacknowledgement: paymentMM?.tranInfo?.amAacknowledgement,
      fedwithholdmaxpct: paymentMM?.tranInfo?.fedwithholdmaxpct,
      fedwithholdminpct: paymentMM?.tranInfo?.fedwithholdminpct,
      statewithholdmaxpct: paymentMM?.tranInfo?.statewithholdmaxpct,
      statewithholdminpct: paymentMM?.tranInfo?.statewithholdminpct
    }
  }
  if (paymentMM?.tranInfo?.isRecurringPaymentPlan) {
    const periordicPaymentData = getPeriodicPaymentDataForEdit(paymentMM)
    return {
      ...formData,
      periodicPayment: periordicPaymentData,
      wireAmount: { ...formData.wireAmount, tranDate: new Date() }
    }
  }
  return formData
}

export const getrecipient = (
  isThirdPartCommon?: boolean,
  isThirdParty?: boolean
) => {
  if (isThirdPartCommon) {
    return RecipientType.ThirdPartyCommon
  } else if (isThirdParty) {
    return RecipientType.ThirdParty
  } else {
    return RecipientType.FirstParty
  }
}

export const getCloseoutValue = (journalData: IJournalInitiationForm) => {
  if (
    journalData?.journalTransferType?.partialOrFull ===
    JournalTransferTypeList.Full
  ) {
    if (journalData?.journalTransferType?.withResiduals === 'Y') {
      return 'R'
    } else {
      return 'Y'
    }
  }
  return undefined
}
