import { css } from '@emotion/react'
import { performanceDetailTableColumnNames } from './PerformanceTableColumns'
import { colNames as reportDetailTableColumnNames } from './Reports/ReportsDetailTable/columnDefinitions'

const commonCellStyles = {
  left: css({
    textAlign: 'left'
  }),
  right: css({
    textAlign: 'right'
  }),
  center: css({
    textAlign: 'center'
  }),
  ellipsis: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }),
  narrow: css({
    width: 400
  }),
  thin: css({
    width: 250
  }),
  thinner: css({
    width: 150
  }),
  thinnest: css({
    width: 50
  }),
  pointer: css({
    cursor: 'pointer'
  })
}

export const reportCellStyles = {
  [reportDetailTableColumnNames.reportName]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis
  ]),
  [reportDetailTableColumnNames.reportDate]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis,
    commonCellStyles.thin
  ]),
  [reportDetailTableColumnNames.accountKey]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis,
    commonCellStyles.thin
  ]),
  [reportDetailTableColumnNames.clientName]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis,
    commonCellStyles.narrow
  ]),
  [reportDetailTableColumnNames.shareReport]: css([commonCellStyles.thinner]),
  [reportDetailTableColumnNames.action]: css([
    commonCellStyles.center,
    commonCellStyles.thinnest
  ])
}

export const cellStyles = {
  [performanceDetailTableColumnNames.group]: css([
    commonCellStyles.left,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.inceptionDate]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.startValue]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.netFlows]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.investmentEarnings]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.endingValue]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRMTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRQTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRYTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetTWRITD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrMTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrQTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrYTD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ]),
  [performanceDetailTableColumnNames.NetIrrITD]: css([
    commonCellStyles.right,
    commonCellStyles.ellipsis
  ])
}
