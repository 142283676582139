import { combineReducers } from 'redux'
import {
  boardMemeberAccountsFetchReducer,
  boardMemeberAccountsFetchSagas
} from './accountsFetch'

export const boardMemberAccountModuleReducer = combineReducers({
  accountsFetch: boardMemeberAccountsFetchReducer
})

export const boardMemberAccountModuleSagas = [...boardMemeberAccountsFetchSagas]
