import { getHouseholdVisibility } from 'api/datahub'
import { flow } from 'lodash/fp'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  ISearchParams,
  ISearchResult
} from '../../../../../../../api/common.types'
import { IHousehold } from '../../../../../../../api/household.types'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../../../../../api/odata'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../store/shared/asyncStore'
import {
  getRockefellerApiOptions,
  safeSearch
} from '../../../../../../../store/shared/sagas'
import { getHomeOfficeModuleState } from '../../../shared/selectors'

export const boardMemeberAccountsFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@bda/@boardMemberAccounts/REQUEST',
  '@modules/@advisory/@modules/@bda/@boardMemberAccounts/SUCCESS',
  '@modules/@advisory/@modules/@bda/@boardMemberAccounts/FAILURE'
)<undefined, IHousehold[] | undefined, Error>()

export const boardMemeberAccountsFetchReducer = createAsyncReducer(
  boardMemeberAccountsFetchActions
)

const rootSelector = flow(
  getHomeOfficeModuleState,
  (x) => x?.modules.boardMemberAccounts.accountsFetch
)

export const {
  getError: getBoardMemeberAccountsFetchError,
  getIsLoading: getBoardMemeberAccountsFetchLoading,
  getResult: getBoardMemeberAccountsFetchResult
} = createAsyncSelectors(rootSelector)

const fetchHouseholdVisibility = function* () {
  const apiOptions = yield* call(getRockefellerApiOptions)
  const householdVisibility = yield* call(getHouseholdVisibility, apiOptions)
  return householdVisibility
}

const fetchAccounts = function* () {
  try {
    const householdVisibility = yield* call(fetchHouseholdVisibility)
    if (!householdVisibility.length) {
      throw new Error('Insufficient access')
    }
    const filter: OdataPropertyFilterGroup = {
      or: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          path: 'householdId',
          type: 'string',
          value: householdVisibility
        }
      ]
    }
    const params: ISearchParams = {
      filters: [filter]
    }
    const result: ISearchResult<IHousehold> = yield call(
      safeSearch,
      'household' as const,
      {
        ...params,
        searchFields: ['householdName', 'id', 'householdId'],
        select: [
          'householdName',
          'householdId',
          'Advisors',
          'ausHistory',
          'revenue/ttmrevenue',
          'householdKPI/AumManaged',
          'householdKPI/AumBrokerage',
          'householdKPI/AumTotal',
          'Account'
        ]
      }
    )

    yield put(boardMemeberAccountsFetchActions.success(result.value))
  } catch (e: any) {
    yield put(boardMemeberAccountsFetchActions.failure(e))
  }
}

export const boardMemeberAccountsFetchSagas = [
  () => takeLatest(boardMemeberAccountsFetchActions.request, fetchAccounts)
]
