import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { ISystemViewDefinition } from 'features/Lists/hooks/useListPreferences'
import { constructInitialPositionListColumnState } from 'features/Lists/positions'
import { PositionColumnIds } from 'features/Lists/positions/ColumnDefinitions'
import { getAiDashboardDefaulViewtFilters } from '../getListFilters'

const initialColumns: (keyof PositionColumnIds)[] = [
  'householdName',
  'accountNumber',
  'partyname',
  'symbolCusipDesc',
  'securitySubtype',
  'assetTypeDetail',
  'originalOrderDate',
  'OriginalOrderAmount',
  'RemainingOriginalAmount',
  'Distributions',
  'TotalIncome',
  'ai_dividends_cash',
  'ai_dividends_reinvest',
  'marketvalue',
  'ValuationDate',
  'ai_valuationReceivedDate',
  'netInvestmentGainLoss',
  'ai_totalReturnPercentage',
  'OnPlatform_OffPlatform'
]

const defaultUiState: IListsUiState = {
  searchText: '',
  columnState: constructInitialPositionListColumnState(initialColumns),
  filters: getAiDashboardDefaulViewtFilters(),
  orderBy: {
    columnId: PositionColumnIds.OriginalOrderAmount,
    direction: 'desc'
  }
}

export const nonDrawdownSystemViews = [
  {
    key: 'NONDRAWDOWN_DEFAULT_SYSTEM_VIEW',
    label: 'Default System View',
    config: defaultUiState
  }
] as ISystemViewDefinition[]
