import { Callout, ITheme } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { createRef, FC } from 'react'
import { useClasses } from 'shared/hooks/useClasses'

const getThemedClasses = (theme: ITheme) => {
  return {
    shareReportCallout: {
      boxShadow: theme.effects.elevation16,
      padding: 10,
      width: 360
    },
    calloutLabel: {
      cursor: 'pointer',
      color: 'rgb(0, 120, 212)'
    },
    scrollBox: {
      maxHeight: 250,
      overflow: 'auto'
    }
  }
}

interface IShareReportCalloutProps {
  share: string
}

export const ShareReportCallout: FC<IShareReportCalloutProps> = ({ share }) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false)
  const target = createRef<HTMLSpanElement>()
  const classes = useClasses(getThemedClasses)

  // DEBUG: mock data
  const data = [
    {
      date: '8/22/2024 1:35 PM',
      names: ['Heather King', 'Carrie King']
    },
    {
      date: '8/24/2024 2:23 PM',
      names: ['Andrew Ashforth', 'Connan Ashforth']
    },
    {
      date: '8/11/2024 8:30 AM',
      names: [
        'Michael Chorches',
        'Anne Chorches',
        'Kevin Arnovitz',
        'Betsy Perlman',
        'Aaron Rudolf'
      ]
    },
    {
      date: '8/2/2024 10:47 PM',
      names: ['Cindy Hopkins']
    },
    {
      date: '7/12/2024 11:49 AM',
      names: [
        'Linda Silberman',
        'Mark Silberman',
        'John Dietz',
        'Maya Sunshine'
      ]
    },
    {
      date: '7/11/2024 5:57 PM',
      names: ['Michael Sunshine']
    },
    {
      date: '6/28/2024 4:31 PM',
      names: ['Megan Osborn']
    },
    {
      date: '6/27/2024 3:22 PM',
      names: [
        'Brenda Hinnefeld',
        'Jaime Wender',
        'David Wender',
        'Montez Carter',
        'Tiffany Carter',
        'Alistair Johnston',
        'Sarah Johnston',
        'Sanford Fitch',
        'Susan Fitch'
      ]
    },
    {
      date: '5/4/2024 12:01 PM',
      names: ['Justine Cohen', 'Andre Cohen', 'Maxine Perlman']
    },
    {
      date: '4/4/2024 4:44 PM',
      names: [
        'Jerry Easley',
        'Susan Easley',
        'Adam Smith',
        'Jens Fog',
        'Leslie Fog'
      ]
    },
    {
      date: '1/15/2024 09:30 AM',
      names: ['April Gay']
    }
  ]

  return (
    <>
      {share === 'No' ? (
        'No'
      ) : (
        <>
          <span
            onMouseOver={toggleIsCalloutVisible}
            className={classes.calloutLabel}
            ref={target}
          >
            {share}
          </span>
          {isCalloutVisible && (
            <Callout
              target={target}
              className={classes.shareReportCallout}
              onDismiss={toggleIsCalloutVisible}
              setInitialFocus
            >
              <div className={classes.scrollBox}>
                {data.map((item, index) => (
                  <p key={index}>
                    <div>{item.date}</div>
                    <div>{item.names.join(', ')}</div>
                  </p>
                ))}
              </div>
            </Callout>
          )}
        </>
      )}
    </>
  )
}
