import {
  startOfQuarter,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subBusinessDays,
  subDays,
  subMonths,
  subYears
} from 'date-fns/fp'
import { flow } from 'lodash/fp'

export const parseDateISOStringInLocalTimezone = (dateString: string) => {
  if (!dateString) {
    return new Date()
  }
  const [year, month, day] = dateString
    .split(/\D/)
    .slice(0, 3)
    .map((x) => parseInt(x, 10))
  return new Date(year, month - 1, day)
}

export enum PastDateRangesEnum {
  Yesterday = 'Yesterday',
  LastBusinessDay = 'Last Business Day',
  Prior10Days = 'Prior 10 Days',
  Prior30Days = 'Prior 30 Days',
  Prior6Months = 'Prior 6 Months',
  Prior12Months = 'Prior 12 Months',
  YTD = 'Year to Date',
  QTD = 'Quarter to Date',
  PriorYear = 'Prior Year'
}
export enum PastDaysEnum {
  InLast3Days = 'In Last 3 Days',
  InLast7Days = 'In Last 7 Days'
}
export const PastDateRanges = {
  ...PastDateRangesEnum
}
export type PastDateRanges = typeof PastDateRanges

export enum PresentDateRangesEnum {
  Today = 'Today',
  ThisWeek = 'This Week',
  ThisMonth = 'This Month',
  ThisYear = 'This Year'
}
export const PresentDateRanges = { ...PresentDateRangesEnum }
export type PresentDateRanges = typeof PresentDateRanges

export enum FutureDateRangesEnum {
  Tomorrow = 'Tomorrow',
  NextMonth = 'Next Month',
  Next6Months = 'Next 6 Months'
}

export const FutureDateRanges = { ...FutureDateRangesEnum }
export type FutureDateRanges = typeof FutureDateRanges

export enum CustomDateRangesEnum {
  Custom = 'Custom'
}

export const CustomDateRanges = { ...CustomDateRangesEnum }
export type CustomDateRanges = typeof CustomDateRanges

export const DateRanges = {
  ...PastDateRanges,
  ...PastDaysEnum,
  ...PresentDateRanges,
  ...FutureDateRanges,
  ...CustomDateRanges
}

export type DateRanges =
  | PastDateRangesEnum
  | PastDaysEnum
  | PresentDateRangesEnum
  | FutureDateRangesEnum
  | CustomDateRangesEnum

export const convertDateRangeToDates = (range: DateRanges): [Date, Date] => {
  const now = new Date()

  let from: Date = now
  let to: Date = now

  switch (range) {
    case DateRanges.Today:
      from = startOfDay(now)
      to = endOfDay(now)
      break
    case DateRanges.Yesterday:
      from = flow(startOfDay, subDays(1))(now)
      to = endOfDay(from)
      break
    case DateRanges.LastBusinessDay:
      from = flow(startOfDay, subBusinessDays(1))(now)
      to = endOfDay(from)
      break
    case DateRanges.InLast7Days:
      from = flow(startOfDay, subDays(7))(now)
      to = endOfDay(subDays(1)(now))
      break
    case DateRanges.InLast3Days:
      from = flow(startOfDay, subDays(3))(now)
      to = endOfDay(subDays(1)(now))
      break
    case DateRanges.Prior12Months:
      from = flow(startOfDay, subMonths(12))(now)
      to = endOfDay(now)
      break
    case DateRanges.Prior6Months:
      from = flow(startOfDay, subMonths(6))(now)
      to = endOfDay(now)
      break
    case DateRanges.Prior10Days:
      from = flow(startOfDay, subDays(10))(now)
      to = endOfDay(now)
      break
    case DateRanges.Prior30Days:
      from = flow(startOfDay, subDays(30))(now)
      to = endOfDay(now)
      break
    case DateRanges.YTD:
      from = startOfYear(now)
      to = endOfDay(now)
      break
    case DateRanges.QTD:
      from = startOfQuarter(now)
      to = endOfDay(now)
      break
    case DateRanges.PriorYear:
      from = flow(subYears(1), startOfYear)(now)
      to = flow(subYears(1), endOfYear)(now)
      break
    case DateRanges.ThisWeek:
      from = startOfWeek(now)
      to = endOfWeek(now)
      break
    case DateRanges.ThisMonth:
      from = startOfMonth(now)
      to = endOfMonth(now)
      break
    case DateRanges.ThisYear:
      from = startOfYear(now)
      to = endOfYear(now)
      break
  }

  return [from, to]
}

export const convertToUtcMidnight = (date?: Date) => {
  if (!date) {
    return undefined
  }

  const timestamp = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  )

  return new Date(timestamp)
}
