import { useTheme } from '@emotion/react'
import { Callout, Link } from '@fluentui/react'
import { useState } from 'react'
import { Icon } from '../features/Icons/Icon'

interface IPrintDropdownMenuProps {
  disclaimer?: string
  print: (masked?: boolean) => void
  isDisabled: boolean
}

export const PrintDropdownMenu: React.FC<IPrintDropdownMenuProps> = ({
  disclaimer,
  print,
  isDisabled
}) => {
  const [showPrinterDropdown, setShowPrinterDropdown] = useState(false)
  const theme = useTheme()
  const width = disclaimer ? '380px' : 'auto'
  const showPrinterDropdownMenu = () => setShowPrinterDropdown(true)
  return (
    <>
      <div
        id="PrintButton"
        css={{
          width: 24,
          height: 24
        }}
      >
        <Icon
          type="Print"
          width={24}
          height={24}
          color={theme.colors.extraBlue2}
          onClick={isDisabled ? undefined : showPrinterDropdownMenu}
          isDisabled={isDisabled}
        />
      </div>
      {showPrinterDropdown && (
        <Callout
          target="#PrintButton"
          role="dialog"
          onDismiss={() => setShowPrinterDropdown(false)}
          styles={{
            beak: {
              display: 'none'
            }
          }}
        >
          <div
            css={{
              backgroundColor: '#F9FBFC',
              padding: '12px',
              borderBottom: '1px solid #EBEBEB',
              fontWeight: '500',
              fontSize: '16px'
            }}
          >
            Print PDF
          </div>
          <div
            css={{
              padding: '20px 13px',
              borderBottom: '1px solid #EEE'
            }}
          >
            <Link
              onClick={() => {
                setShowPrinterDropdown(false)
                print(false)
              }}
              style={{
                color: '#377EA3',
                textDecoration: 'none',
                fontSize: '14px'
              }}
              target="_blank"
            >
              Unmasked Account Numbers
            </Link>
          </div>
          <div
            css={{
              padding: '20px 13px 11px 13px'
            }}
          >
            <Link
              onClick={() => {
                setShowPrinterDropdown(false)
                print(true)
              }}
              style={{
                color: '#377EA3',
                textDecoration: 'none',
                fontSize: '14px'
              }}
              target="_blank"
            >
              Masked Account Numbers
            </Link>
          </div>
          <div
            style={{
              fontSize: '12px',
              color: '#61646B',
              width: `${width}`,
              padding: '0 12px 15px 12px'
            }}
          >
            {disclaimer ? disclaimer : ''}
          </div>
        </Callout>
      )}
    </>
  )
}
