import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import {
  createProfileApiContextStore,
  createProfileApiContextSlice
} from '../profileApi'

const {
  slice: selectedAccountsApiContextSlice,
  apiKey: selectedAccountsApiContextKey
} = createProfileApiContextSlice('selected')

export { selectedAccountsApiContextKey }

export const apiContextReducer = combineReducers({
  selected: selectedAccountsApiContextSlice.reducer
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.apiContext

const selectedSelector = createSelector(
  rootSelector,
  ({ selected }) => selected
)
const {
  useProfileApiContext: useRdot360SelectedAccountsApiContext,
  saga_watchContext: saga_watchSelectedContext,
  selectApiKey,
  selectContextId
} = createProfileApiContextStore(
  selectedSelector,
  selectedAccountsApiContextSlice.actions
)

const apiContextSagas = [saga_watchSelectedContext]

export {
  useRdot360SelectedAccountsApiContext,
  apiContextSagas,
  selectApiKey,
  selectContextId
}
