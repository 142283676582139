import { css } from '@emotion/react'
//import { Link, Stack } from '@fluentui/react'
import { memo, useMemo } from 'react'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import PerformanceDetailTable from './PerformanceDetailTable'
import PerformanceFilters from './PerformanceFilters'
// import ReportFilters from './Reports/ReportFilters'
// import { ReportsDetailTable } from './Reports/ReportsDetailTable/ReportsDetailTable'

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px'
  }),
  headerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  }),
  filterContainer: css({
    display: 'flex',
    flexDirection: 'row',
    columnGap: 16,
    justifyContent: 'space-between'
  }),
  filterContainerReports: css({
    flexGrow: 1,
    paddingLeft: 50
  }),
  tabs: css({
    paddingLeft: 50,
    paddingBottom: 5,
    width: 300,
    'span:first-of-type': {
      marginRight: 25
    },
    button: {
      color: 'black!important',
      textDecoration: 'none!important'
    },
    '& .selected': {
      fontWeight: 'bold',
      borderBottom: '3px solid black'
    }
  }),
  tab: css({
    color: 'black',
    padding: '0 6px 6px 6px',
    borderBottom: '3px solid transparent',
    fontSize: 17
  }),
  leftContainer: css({
    display: 'flex',
    alignItems: 'flex-end'
  })
})

const PerformanceDetailView: React.FC = memo(() => {
  //const [reportsView, setReportsView] = useState(false)
  const classes = useMemo(() => getClasses(), [])
  return (
    <div css={classes.container}>
      <div css={classes.headerContainer}>
        <div css={classes.leftContainer}>
          <DetailsNavigator />
        </div>
        <div css={[classes.filterContainer]}>
          <PerformanceFilters />
        </div>
      </div>
      <PerformanceDetailTable />
    </div>
  )
})

//   return (
//     <div css={classes.container}>
//       <div css={classes.headerContainer}>
//         <div css={classes.leftContainer}>
//           <DetailsNavigator />
//           <Stack horizontal tokens={{ childrenGap: 20 }} css={classes.tabs}>
//             <div
//               css={classes.tab}
//               className={!reportsView ? 'selected' : undefined}
//             >
//               <Link onClick={() => setReportsView(false)}>Performance</Link>
//             </div>
//             <div
//               css={classes.tab}
//               className={reportsView ? 'selected' : undefined}
//             >
//               <Link onClick={() => setReportsView(true)}>Reports</Link>
//             </div>
//           </Stack>
//         </div>
//         <div
//           css={[
//             classes.filterContainer,
//             reportsView ? classes.filterContainerReports : undefined
//           ]}
//         >
//           {reportsView ? <ReportFilters /> : <PerformanceFilters />}
//         </div>
//       </div>
//       {reportsView ? <ReportsDetailTable /> : <PerformanceDetailTable />}
//     </div>
//   )

export default PerformanceDetailView
