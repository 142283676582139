import { format } from 'date-fns'
import type { PlotColumnOptions, SeriesColumnOptions } from 'highcharts'
import { orderBy } from 'lodash'
import { FC, useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { HighchartsComponent, highcharts } from 'shared/highcharts'
import { IIncomeSummaryChartResponseSubTotal } from '../../store/holdingsApi/IIncomeSummaryResponse'

const getIncomeChartOptions = (
  categories?: string[],
  series?: SeriesColumnOptions[],
  columnOptions?: PlotColumnOptions
): Highcharts.Options => ({
  chart: {
    type: 'column',
    zooming: {
      type: 'xy'
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    categories,
    tickWidth: 1,
    tickmarkPlacement: 'on'
  },
  yAxis: {
    tickLength: 0,
    gridLineColor: '#d6d6d6',
    title: {
      text: ''
    }
  },
  tooltip: {
    formatter: function formatTooltip() {
      const y = this.y ?? 0
      const total = this.total ?? 0
      const modifier = y < 0 ? -1 : 1
      return [
        `${this.x}`,
        `${this.series.name} : $${highcharts?.numberFormat(
          y * modifier,
          0,
          '.',
          ','
        )}`,
        `Total : ${y < 0 ? '-' : ''}$${highcharts?.numberFormat(
          total * modifier,
          0,
          '.',
          ','
        )}`
      ]
        .filter(isNotNullOrUndefined)
        .join('</br>')
    }
  },
  plotOptions: {
    column: {
      pointWidth: 10,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      },
      ...(columnOptions ?? {})
    }
  },
  legend: {
    backgroundColor: undefined,
    itemStyle: {
      color: 'black'
    },
    symbolRadius: 0
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 300
        }
      }
    ]
  },
  series
})

const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%' }
}

const seriesDefinitions: (Partial<SeriesColumnOptions> & {
  isOptionPremium?: boolean
  getValue: (item: IIncomeSummaryChartResponseSubTotal) => number
})[] = [
  {
    name: 'Interest',
    colorIndex: 0,
    color: '#45BEAA',
    getValue: ({ interest, interestReceived }) =>
      interest ?? interestReceived ?? 0
  },
  {
    name: 'Dividends',
    colorIndex: 1,
    color: '#3E8DDD',
    getValue: ({ dividend }) => dividend ?? 0
  },
  {
    name: 'Capital Gains Distribution',
    colorIndex: 2,
    color: '#977AE9',
    getValue: ({ capitalGains, capGain }) => capitalGains ?? capGain ?? 0
  },
  {
    name: 'Option Premiums Paid',
    isOptionPremium: true,
    colorIndex: 3,
    color: '#C4E1B2',
    getValue: ({ optionPremiumsPaid }) => optionPremiumsPaid ?? 0
  },
  {
    name: 'Option Premiums Received',
    isOptionPremium: true,
    colorIndex: 4,
    color: '#7BC46C',
    getValue: ({ optionPremiumsReceived }) => optionPremiumsReceived ?? 0
  }
]
const defaultYear = new Date().getFullYear()
const getItemDate = ({
  year = defaultYear,
  monSeq,
  monseq
}: IIncomeSummaryChartResponseSubTotal) =>
  new Date(year, (monSeq ?? monseq ?? 0) - 1, 1)
export const IncomeChart: FC<{
  data?: IIncomeSummaryChartResponseSubTotal[]
  includeOptionPremiums?: boolean
  columnOptions?: PlotColumnOptions
}> = ({ data = [], includeOptionPremiums = true, columnOptions }) => {
  const orderedData = useMemo(() => orderBy(data, getItemDate, 'asc'), [data])
  const series = useMemo(() => {
    return seriesDefinitions
      .filter(
        ({ isOptionPremium }) => !isOptionPremium || includeOptionPremiums
      )
      .map(
        ({ name, colorIndex, color, getValue }): SeriesColumnOptions => ({
          type: 'column',
          name,
          colorIndex,
          color,
          data: orderedData.map(getValue)
        })
      )
      .filter(
        ({ data, colorIndex = 3 }) => colorIndex < 2 || data?.some((x) => x)
      )
  }, [includeOptionPremiums, orderedData])

  const categories = useMemo(() => {
    return orderedData.map((item) => format(getItemDate(item), `MMM ''yy`))
  }, [orderedData])

  const chartOptions = useMemo(
    () => getIncomeChartOptions(categories, series, columnOptions),
    [categories, series, columnOptions]
  )

  return (
    <HighchartsComponent
      containerProps={containerProps}
      options={chartOptions}
    />
  )
}
