import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { pushNotification } from '../../../../../../../../features/Notifications'
import { downloadUrlAsFile } from '../../../../../../../../shared/downloads'
import { AppState } from '../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../store/shared/asyncStore'
import { getAllPagedOdataApiResults } from '../../../../../../../../store/shared/sagas/odata'
import { HurdleDefinitionExportWorker } from './export.worker'
import { fetchHurdles, hurdleDefinitionListUiSelectors } from './store'

export const hurdleDefinitionExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@hurdleDefinition/@export/REQUEST',
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@hurdleDefinition/@export/SUCCESS',
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@hurdleDefinition/@export/FAILURE'
)<undefined, undefined, Error>()

export const hurdleDefinitionExportReducer = createAsyncReducer(
  hurdleDefinitionExportActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features
    .hurdleDefinitionList.export
export const {
  getError: getHurdleDefinitionExportError,
  getIsLoading: getIsHurdleDefinitionExportLoading
} = createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const request = yield* select(
      hurdleDefinitionListUiSelectors.getOdataRequest
    )
    const results = yield* call(() =>
      getAllPagedOdataApiResults(
        {
          ...request,
          expand: [
            'measurements($expand=metrics($expand=payouts,probability, progressMeasurementStatuses))'
          ]
        },
        fetchHurdles
      )
    )

    const worker = new Worker(new URL('./export.worker.ts', import.meta.url))
    const { createAndDownloadHurdleDefinitionExport } =
      wrap<HurdleDefinitionExportWorker>(worker)

    const result = yield* call(createAndDownloadHurdleDefinitionExport, results)

    const filename = `Hurdles Definition ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(hurdleDefinitionExportActions.success())
  } catch (e: unknown) {
    yield put(hurdleDefinitionExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof hurdleDefinitionExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const hurdleDefitionExportSagas = [
  () => takeLatest(hurdleDefinitionExportActions.request, onExport),
  () => takeLatest(hurdleDefinitionExportActions.failure, onFailure)
]
