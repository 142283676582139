import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useRdot360SelectedAccountsApiContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useGetOpenTaxLotsByAccountAndSecurityQuery } from 'modules/Advisory/modules/Rdot360/store/tasApi'

export const useGetOpenLots = (accountId?: string, secId?: string) => {
  const { apiKey } = useRdot360SelectedAccountsApiContext()
  return useGetOpenTaxLotsByAccountAndSecurityQuery(
    accountId && secId
      ? {
          contextId: apiKey,
          accountId,
          secId
        }
      : skipToken
  )
}
