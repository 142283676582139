import { IAnnuityPPLIAssets } from 'api/datahub'
import type exceljs from 'exceljs'
import { IOdataListColumnDefinition } from 'features/OdataList/common/types'
import { excelStyle } from '../export/exportFormat'
import { AnnuityPPLIAssetsColumnName } from './columnNameTypes'

export interface IAnnuityPPLIAssetsColumnDefinition
  extends IOdataListColumnDefinition {
  name: AnnuityPPLIAssetsColumnName
  getExportValue?: (item: IAnnuityPPLIAssets) => Partial<exceljs.CellValue>
  exportFormat?: Partial<exceljs.Style>
}

const commonCollumnProps: Partial<IAnnuityPPLIAssetsColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const annuityPPLIAssetsColumns: IAnnuityPPLIAssetsColumnDefinition[] = [
  {
    ...commonCollumnProps,
    name: 'Contract Type',
    dataPath: 'contractType',
    type: 'string',
    width: 140,
    getExportValue: ({ contractType }) => contractType,
    facetable: true,
    searchFields: [
      'contractType',
      'householdName',
      'accountNumber',
      'legalEntityName',
      'contractNumber'
    ]
  },
  {
    ...commonCollumnProps,
    name: 'Household Name',
    dataPath: 'householdName',
    type: 'string',
    width: 200,
    getExportValue: ({ householdName }) => householdName
  },
  {
    ...commonCollumnProps,
    name: 'Account Number',
    dataPath: 'accountNumber',
    type: 'string',
    width: 100,
    getExportValue: ({ accountNumber }) => accountNumber
  },
  {
    ...commonCollumnProps,
    name: 'Legal Entity Name',
    dataPath: 'legalEntityName',
    type: 'string',
    width: 130,
    getExportValue: ({ legalEntityName }) => legalEntityName
  },
  {
    ...commonCollumnProps,
    name: 'Contract Number',
    dataPath: 'contractNumber',
    type: 'string',
    width: 100,
    getExportValue: ({ contractNumber }) => contractNumber
  },
  {
    ...commonCollumnProps,
    name: 'Product Type',
    dataPath: 'productType',
    type: 'string',
    width: 200,
    getExportValue: ({ productType }) => productType,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Carrier',
    dataPath: 'carrierName',
    type: 'string',
    width: 200,
    getExportValue: ({ carrierName }) => carrierName
  },
  {
    ...commonCollumnProps,
    name: 'Description',
    dataPath: 'description',
    type: 'string',
    width: 200,
    getExportValue: ({ description }) => description
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Security CUSIP',
    dataPath: 'assetCusip',
    type: 'string',
    width: 100,
    getExportValue: ({ assetCusip }) => assetCusip
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Security Description',
    dataPath: 'assetDescription',
    type: 'string',
    width: 200,
    getExportValue: ({ assetDescription }) => assetDescription
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Asset Class Level-1',
    dataPath: 'assetClassL1',
    type: 'string',
    width: 100,
    getExportValue: ({ assetClassL1 }) => assetClassL1
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Asset Sub Type',
    dataPath: 'assetSubType',
    type: 'string',
    width: 100,
    getExportValue: ({ assetSubType }) => assetSubType,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Market Value',
    dataPath: 'marketValue',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ marketValue }) => marketValue
  },
  {
    ...commonCollumnProps,
    name: 'Holding %',
    dataPath: 'fundPercent',
    type: 'number',
    exportFormat: excelStyle.PERCENT,
    width: 70,
    getExportValue: ({ fundPercent }) => fundPercent
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Asset Class Level-2',
    dataPath: 'assetClassL2',
    type: 'string',
    width: 100,
    getExportValue: ({ assetClassL2 }) => assetClassL2
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Asset Class Level-3',
    dataPath: 'assetClassL3',
    type: 'string',
    width: 100,
    getExportValue: ({ assetClassL3 }) => assetClassL3
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Asset Class Level-4',
    dataPath: 'assetClassL4',
    type: 'string',
    width: 100,
    getExportValue: ({ assetClassL4 }) => assetClassL4
  },
  {
    ...commonCollumnProps,
    name: 'Underlying Asset Type',
    dataPath: 'assetType',
    type: 'string',
    width: 120,
    getExportValue: ({ assetType }) => assetType,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Team Name',
    dataPath: 'teamName',
    type: 'string',
    width: 120,
    getExportValue: ({ teamName }) => teamName
  },
  {
    ...commonCollumnProps,
    name: 'Division Name',
    dataPath: 'regionName',
    type: 'string',
    width: 125,
    getExportValue: ({ regionName }) => regionName
  },
  {
    ...commonCollumnProps,
    name: 'Advisor Id',
    dataPath: 'registeredRep',
    type: 'string',
    width: 70,
    getExportValue: ({ registeredRep }) => registeredRep
  },
  {
    ...commonCollumnProps,
    name: 'Advisor Name',
    dataPath: 'registeredRepName',
    type: 'string',
    width: 120,
    getExportValue: ({ registeredRepName }) => registeredRepName
  },
  {
    ...commonCollumnProps,
    name: 'Clean Carrier Name',
    dataPath: 'cleanCarrierName',
    type: 'string',
    width: 200,
    getExportValue: ({ cleanCarrierName }) => cleanCarrierName
  }
]
