import { Link } from '@fluentui/react'
import { sumBy, orderBy, union, difference } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { IndeterminateProgressIndicator } from '../../components/shared'
import { SnackBar } from '../../components/shared/Snackbar'
import { IBalanceDetailResponseValueItem } from '../../store/balancesApi/IBalanceDetailResponse'
import { ICustomAccountGroup } from '../../store/dynamics'
import {
  IRdot360Account,
  getAccountKey,
  useRdot360AccountContext
} from '../../store/rdot360Context'
import {
  getTodaysChange,
  useRdot360BalancesContext
} from '../../store/rdot360Context/useRdot360BalancesContext'
import { useRdot360CagsContext } from '../../store/rdot360Context/useRdot360CagsContext'
import { AccountSelectorAccount } from './AccountSelectorAccount'
import { AccountSelectorGroup } from './AccountSelectorGroup'
import { useDeleteCustomGroupStore } from './features/DeleteCustomAccountGroup'
import { DeleteCustomGroupModal } from './features/DeleteCustomAccountGroup/DeleteCustomGroupModal'
import { useAccountSelectorTableStore } from './store/store'
import { accountSelectorStyles } from './styles'

export const CustomGroupSelector: React.FC = () => {
  const { cagsWithUngrouped, filteredCagsWithUngrouped, isFetching, error } =
    useRdot360CagsContext()
  const [expandedIds, setExpandedIds] = useState<string[]>([])
  useEffect(
    () =>
      setExpandedIds(
        cagsWithUngrouped
          ?.map((x) => x?.rcm_customaccountgroupid)
          ?.filter(isNotNullOrUndefined) || []
      ),
    [cagsWithUngrouped]
  )
  const { groupToDelete } = useDeleteCustomGroupStore()
  const isAllExpanded = useMemo(
    () =>
      filteredCagsWithUngrouped?.every(
        (x) =>
          x?.rcm_customaccountgroupid &&
          expandedIds?.includes(x?.rcm_customaccountgroupid)
      ),
    [expandedIds, filteredCagsWithUngrouped]
  )
  const toggleExpandAll = useCallback(() => {
    const displayedIds = filteredCagsWithUngrouped
      ?.map((x) => x?.rcm_customaccountgroupid)
      ?.filter(isNotNullOrUndefined)
    setExpandedIds(
      isAllExpanded
        ? difference(expandedIds, displayedIds)
        : union(expandedIds, displayedIds)
    )
  }, [expandedIds, filteredCagsWithUngrouped, isAllExpanded])
  const toggleIsExpanded = useCallback(
    (id?: string) => {
      if (!id) {
        return
      }
      if (expandedIds.includes(id)) {
        setExpandedIds(expandedIds.filter((x) => x !== id))
      } else {
        setExpandedIds([...expandedIds, id])
      }
    },
    [expandedIds]
  )
  return (
    <>
      <div
        css={{
          marginBottom: '5px',
          cursor: 'pointer',
          justifyContent: 'flex-end',
          display: 'flex'
        }}
      >
        <Link onClick={toggleExpandAll}>
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </Link>
      </div>
      <div>
        {isFetching && <IndeterminateProgressIndicator />}
        {error ? (
          <div css={{ paddingBottom: '10px' }}>
            <SnackBar
              type="Failure"
              message={(error as Error)?.message || 'An unknown error occurred'}
            />
          </div>
        ) : null}
        {!filteredCagsWithUngrouped && (
          <div css={accountSelectorStyles.container}>
            <div css={[accountSelectorStyles.groupItem]}>
              <AccountSelectorGroup
                onChange={() => undefined}
                subrowsCount={0}
                selectedSubrowsCount={0}
                toggleExpanded={() => undefined}
                groupBalance={0}
                groupBalanceChange={0}
              />
            </div>
          </div>
        )}
        {filteredCagsWithUngrouped && (
          <>
            {filteredCagsWithUngrouped?.map((group, i) => (
              <CustomGroupRow
                group={group}
                isExpanded={
                  !!group?.rcm_customaccountgroupid &&
                  expandedIds.includes(group?.rcm_customaccountgroupid)
                }
                toggleIsExpanded={toggleIsExpanded}
                key={i}
              />
            ))}
          </>
        )}
      </div>
      {groupToDelete && <DeleteCustomGroupModal />}
    </>
  )
}

const CustomGroupRow: React.FC<{
  group: ICustomAccountGroup
  isExpanded: boolean
  toggleIsExpanded?: (id?: string) => void
}> = ({ group, isExpanded, toggleIsExpanded }) => {
  const { selectedIdsLookup, selectedIds, setSelectedIds } =
    useAccountSelectorTableStore()
  const { setGroupToDelete } = useDeleteCustomGroupStore()
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const groupAccounts = useMemo(
    () =>
      group?.rcm_cag_rcm_financialaccount
        ?.map((x) => accountLookupByAccountIdOrKey[x.rcm_cdmaccountid || ''])
        ?.filter(isNotNullOrUndefined),
    [accountLookupByAccountIdOrKey, group?.rcm_cag_rcm_financialaccount]
  )
  const groupAccountsSelected = useMemo(
    () => groupAccounts?.filter((x) => selectedIdsLookup[x.id || '']),
    [groupAccounts, selectedIdsLookup]
  )
  const { balanceLookupByKey } = useRdot360BalancesContext()
  const balances = useMemo(
    () =>
      groupAccountsSelected?.map((x) => balanceLookupByKey[getAccountKey(x)]),
    [balanceLookupByKey, groupAccountsSelected]
  )
  const totalBalance = useMemo(
    () => sumBy(balances, (x) => x?.netWorth || 0),
    [balances]
  )

  const todaysChange = useMemo(() => {
    const balancesTodaysChange = getTodaysChange(
      balances as IBalanceDetailResponseValueItem[]
    )
    return balancesTodaysChange
  }, [balances])

  const totalChange = useMemo(
    () => sumBy(balances, (x) => x?.netWorthChange || 0),
    [balances]
  )

  const accountIds = useMemo(
    () => groupAccounts?.map((x) => x?.id)?.filter(isNotNullOrUndefined),
    [groupAccounts]
  )

  const isAllSelected = useMemo(
    () => groupAccountsSelected?.length === accountIds?.length,
    [accountIds?.length, groupAccountsSelected?.length]
  )
  const onSelectionChanged = useCallback(() => {
    const otherAccounts = selectedIds.filter((x) => !accountIds?.includes(x))
    if (isAllSelected) {
      setSelectedIds(otherAccounts)
    } else {
      setSelectedIds([...otherAccounts, ...(accountIds || [])])
    }
  }, [accountIds, isAllSelected, selectedIds, setSelectedIds])

  const onDelete = useCallback(
    () => setGroupToDelete(group),
    [group, setGroupToDelete]
  )

  const orderedAccounts = useMemo(
    () =>
      orderBy(
        groupAccounts,
        (x) => balanceLookupByKey[getAccountKey(x)]?.netWorth || 0,
        'desc'
      ),
    [balanceLookupByKey, groupAccounts]
  )
  const toggleExpaned = useCallback(() => {
    toggleIsExpanded?.(group?.rcm_customaccountgroupid)
  }, [group?.rcm_customaccountgroupid, toggleIsExpanded])

  return (
    <div css={accountSelectorStyles.container}>
      <div
        css={[
          accountSelectorStyles.groupItem,
          isExpanded &&
            groupAccounts?.length &&
            accountSelectorStyles.expandedGroupItem
        ]}
      >
        <AccountSelectorGroup
          title={group.rcm_name}
          checked={isAllSelected}
          indeterminate={!isAllSelected && !!groupAccountsSelected?.length}
          onChange={onSelectionChanged}
          selectedSubrowsCount={groupAccountsSelected?.length || 0}
          subrowsCount={groupAccounts?.length || 0}
          groupBalance={totalBalance}
          groupBalanceChange={totalChange}
          onDeleteGroup={group?.rcm_customaccountgroupid ? onDelete : undefined}
          isExpanded={isExpanded}
          toggleExpanded={toggleExpaned}
          todaysChange={todaysChange}
          cagId={
            group?.rcm_customaccountgroupid !== 'Ungrouped Accounts'
              ? group?.rcm_customaccountgroupid
              : undefined
          }
        />
      </div>

      {isExpanded && !!orderedAccounts.length && (
        <div css={accountSelectorStyles.itemsContainer}>
          {orderedAccounts?.map((account, i) => (
            <AccountRow account={account} key={i} />
          ))}
        </div>
      )}
    </div>
  )
}

const AccountRow: React.FC<{
  account: IRdot360Account
}> = ({ account }) => {
  const { balanceLookupByKey } = useRdot360BalancesContext()
  const { setSelectedIds, selectedIdsLookup, selectedIds } =
    useAccountSelectorTableStore()
  const isSelected = useMemo(
    () => selectedIdsLookup?.[account?.id || ''] || false,
    [account?.id, selectedIdsLookup]
  )
  const onCheckboxClicked = useCallback(() => {
    if (!account?.id) {
      return
    }
    if (isSelected) {
      setSelectedIds(selectedIds?.filter((x) => x !== account?.id))
    } else {
      setSelectedIds([...selectedIds, account?.id])
    }
  }, [account?.id, isSelected, selectedIds, setSelectedIds])
  const balance = balanceLookupByKey[getAccountKey(account)]
  const { netWorth, netWorthChange, todayschange } = balance || {}
  return (
    <div css={accountSelectorStyles.accountItem}>
      <AccountSelectorAccount
        checked={isSelected}
        onChange={onCheckboxClicked}
        accountNumber={account?.accountId}
        balance={netWorth}
        change={netWorthChange}
        todaysChange={todayschange}
      />
    </div>
  )
}
