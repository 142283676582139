import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { flow } from 'lodash/fp'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getClientOnlineApiConfig } from 'store/system'
import { axiosBaseQuery } from './shared'

export const Rdot360ApiSliceKey = 'api.rdot360'
export type Rdot360ApiReducerState = {
  [Rdot360ApiSliceKey]: ReturnType<typeof rdot360Api.reducer>
}

const getApiBaseUrl = (state: AppState) => {
  const base = flow(getClientOnlineApiConfig, (x) => x?.root)(state)
  const v1 = new URL('', base)
  return v1.href
}

const getApiAuthToken = (state: AppState) => {
  const scopes = flow(getClientOnlineApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(scopes)
}

export type Rdot360ApiTagType = 'rdot360'
export const rdot360ApiTagTypes: Rdot360ApiTagType[] = ['rdot360']
export const rdot360Api = createApi({
  reducerPath: Rdot360ApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getApiBaseUrl(state),
    getAuthToken: (state) => getApiAuthToken(state)
  }),
  endpoints: () => ({}),
  tagTypes: rdot360ApiTagTypes
})
