import { css, Theme, useTheme } from '@emotion/react'
import { IDropdownOption } from '@fluentui/react'
import { format, lastDayOfMonth, subDays } from 'date-fns'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { isNotNullOrEmpty } from 'shared/guards'
import { Dropdown } from '../../../components/shared'
import { buttonStyles } from '../../../components/shared/Buttons'
import DatePicker from '../../../components/shared/DatePicker'
import {
  downloadAttachment,
  openPreview
} from '../../../hooks/useDownloadAttachment'
import {
  useLazyRetrieveCombinedDocumentQueryForSelectedAccounts,
  useLazyRetrieveIndividualDocumentQueryForSelectedAccounts
} from '../../../store/rdot360Context/useRdot360DocumentsContext'
import { DownloadMenuCallout } from './DownloadMenuCallout'
import {
  documentFilterActions,
  getDateRange,
  getSelectedDateRangeDropDownOption,
  getIsDownloading,
  getDocumentSelection,
  useDocumentModuleStore
} from './store'

export const getClasses = (theme: Theme) => ({
  dateRangeContainer: css({
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.primaryBlack
  }),
  dateRangeDropdown: css({
    width: '184px',
    marginTop: '4px'
  }),
  iconContainer: css({
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    justifyContent: 'end',
    gap: 15
  }),
  iconStyleDisable: css({
    width: 24,
    opacity: '0.5',
    marginBottom: 4,
    pointerEvents: 'none'
  }),
  iconStyle: css({
    width: 24,
    cursor: 'pointer',
    marginBottom: 4
  }),
  datePickerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    '& .ms-TextField-fieldGroup': {
      height: '100%',
      padding: 5,
      borderRadius: 8,
      width: '200px'
    }
  })
})

const currentYear = new Date().getFullYear()
const parseDate = (date?: string) =>
  date ? parseDateISOStringInLocalTimezone(date) : undefined

const taxDateRangeOptions: IDropdownOption[] = [
  { key: currentYear, text: `${currentYear}` },
  {
    key: currentYear - 1,
    text: `${currentYear - 1}`
  },
  {
    key: currentYear - 2,
    text: `${currentYear - 2}`
  },
  {
    key: currentYear - 3,
    text: `${currentYear - 3}`
  },
  {
    key: currentYear - 4,
    text: `${currentYear - 4}`
  },
  {
    key: currentYear - 5,
    text: `${currentYear - 5}`
  }
]

const DocumentFilter: FC<{ documentTabType: string }> = ({
  documentTabType
}) => {
  const statementDateRangeOptions: IDropdownOption[] = [
    {
      key: 'mostRecent',
      text: documentTabType === 'Statements' ? 'Last 60 Days' : 'Last 30 Days'
    },
    {
      key: 'priorMonth',
      text: 'Prior Month'
    },
    {
      key: 'prior3Months',
      text: 'Prior 3 Months'
    },
    {
      key: 'prior6Months',
      text: 'Prior 6 Months'
    },
    {
      key: 'prior12Months',
      text: 'Prior 12 Months'
    },
    {
      key: 'priorYear',
      text: 'Prior Year'
    },
    {
      key: 'currentYear',
      text: 'Current Year'
    },
    {
      key: 'customRange',
      text: 'Custom Range'
    }
  ]
  const documentRangeOptions: { [key: string]: IDropdownOption[] } = {
    Statements: statementDateRangeOptions,
    'Tax Documents': taxDateRangeOptions,
    Correspondence: statementDateRangeOptions,
    'Trade Confirmations': statementDateRangeOptions
  }
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const year = new Date().getFullYear()
  const month = new Date().getMonth()
  const dispatch = useDispatch()
  const { startDate, setStartDate, endDate, setEndDate } =
    useDocumentModuleStore()
  const { setIsDownloading } = documentFilterActions
  const selectedDropDownOption = useSelector(
    getSelectedDateRangeDropDownOption(documentTabType)
  )
  const selectedDateRange = useSelector(getDateRange(documentTabType))
  const isDownloading = useSelector(getIsDownloading)

  const dispatchDateRange = useCallback(
    (documentTabType: string, dateRange: number) => {
      dispatch(
        documentFilterActions.setDateRange({
          documentTabType,
          selectBeginDate: format(
            new Date(year, month - dateRange),
            'yyyy-MM-01'
          ),
          selectedEndDate: format(new Date(), 'yyyy-MM-dd')
        })
      )
    },
    [dispatch, month, year]
  )

  const handlDateRangeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      dispatch(
        documentFilterActions.setSelectedDateRangeDropDownOption({
          documentTabType,
          selectedOption: option?.key
        })
      )
      if (documentTabType !== 'Tax Documents') {
        if (option) {
          switch (option?.key) {
            case 'mostRecent':
              dispatch(
                documentFilterActions.setDateRange({
                  documentTabType,
                  selectBeginDate: format(
                    subDays(
                      new Date(),
                      documentTabType === 'Statements' ? 60 : 30
                    ),
                    'yyyy-MM-dd'
                  ),
                  selectedEndDate: format(new Date(), 'yyyy-MM-dd')
                })
              )
              break
            case 'priorMonth':
              dispatchDateRange(documentTabType, 1)
              break
            case 'prior3Months':
              dispatchDateRange(documentTabType, 3)
              break
            case 'prior6Months':
              dispatchDateRange(documentTabType, 6)
              break
            case 'prior12Months':
              dispatchDateRange(documentTabType, 12)
              break
            case 'priorYear':
              dispatch(
                documentFilterActions.setDateRange({
                  documentTabType,
                  selectBeginDate: format(
                    new Date(),
                    `${currentYear - 1}-01-01`
                  ),
                  selectedEndDate: format(
                    new Date(),
                    `${currentYear - 1}-12-31`
                  )
                })
              )
              break
            case 'currentYear':
              dispatch(
                documentFilterActions.setDateRange({
                  documentTabType,
                  selectBeginDate: format(new Date(), 'yyyy-01-01'),
                  selectedEndDate: format(new Date(), 'yyyy-MM-dd')
                })
              )
              break
            case 'customRange':
              dispatch(
                documentFilterActions.setDateRange({
                  documentTabType,
                  selectBeginDate:
                    startDate &&
                    format(
                      parseDateISOStringInLocalTimezone(startDate),
                      'yyyy-MM-dd'
                    ),
                  selectedEndDate:
                    endDate &&
                    format(
                      parseDateISOStringInLocalTimezone(endDate),
                      'yyyy-MM-dd'
                    )
                })
              )
              break
          }
        }
      } else if (documentTabType === 'Tax Documents') {
        if (option) {
          dispatch(
            documentFilterActions.setDateRange({
              documentTabType,
              selectBeginDate: format(new Date(+option.key, 0), 'yyyy-01-01'),
              selectedEndDate: format(
                lastDayOfMonth(new Date(+option.key, 11)),
                'yyyy-MM-dd'
              )
            })
          )
        }
      }
    },
    [dispatch, dispatchDateRange, documentTabType, endDate, startDate]
  )

  const handleCustomSelection = useCallback(() => {
    const beginDate =
      startDate &&
      format(parseDateISOStringInLocalTimezone(startDate), 'yyyy-MM-dd')
    const toDate =
      endDate &&
      format(parseDateISOStringInLocalTimezone(endDate), 'yyyy-MM-dd')

    dispatch(
      documentFilterActions.setDateRange({
        documentTabType,
        selectBeginDate: beginDate,
        selectedEndDate: toDate
      })
    )
  }, [startDate, endDate, dispatch, documentTabType])

  const [triggerIndividualDownload] =
    useLazyRetrieveIndividualDocumentQueryForSelectedAccounts()

  const [triggerCombinedDownload] =
    useLazyRetrieveCombinedDocumentQueryForSelectedAccounts()

  const selection = useSelector(getDocumentSelection)
  const items = selection
    .map((x) => {
      const [first, ...rest] = x.split(':')
      return [first, rest?.join(':')].filter(isNotNullOrEmpty)
    })
    .filter((x) => x.length === 2)
    .map(([key, val]) => ({ [key]: val }))

  const onIndividualDownloadClick = useCallback(async () => {
    if (!selection.length) {
      return
    }
    dispatch(setIsDownloading(true))

    const { data } = await triggerIndividualDownload({
      VirtualTable: 'NF_STATEMENTS',
      PrimaryType: 'Account',
      PrimaryValue: items
    })

    downloadAttachment(data || '', 'application/zip', 'Individual Downloads')
    dispatch(setIsDownloading(false))
  }, [dispatch, items, selection, setIsDownloading, triggerIndividualDownload])

  const onCombinedDownloadClick = useCallback(async () => {
    if (!selection.length) {
      return
    }
    dispatch(setIsDownloading(true))

    const { data } = await triggerCombinedDownload({
      VirtualTable: 'NF_STATEMENTS',
      PrimaryType: 'Account',
      PrimaryValue: items
    })

    downloadAttachment(
      data?.ContentStream || '',
      'application/pdf',
      'Combined Document'
    )
    dispatch(setIsDownloading(false))
  }, [dispatch, items, selection, setIsDownloading, triggerCombinedDownload])

  const onPrintClick = useCallback(async () => {
    if (!selection.length) {
      return
    }

    dispatch(setIsDownloading(true))
    const { data } = await triggerCombinedDownload({
      VirtualTable: 'NF_STATEMENTS',
      PrimaryType: 'Account',
      PrimaryValue: items
    })
    dispatch(setIsDownloading(false))

    if (!data?.ContentStream) {
      alert('Unable to load document')
      return
    }
    openPreview(data?.ContentStream || '', 'application/pdf')
  }, [dispatch, items, selection, setIsDownloading, triggerCombinedDownload])

  useEffect(() => {
    if (selectedDateRange.searchDateBegin) {
      return
    }

    if (documentTabType === 'Tax Documents') {
      dispatch(
        documentFilterActions.setDateRange({
          documentTabType: 'Tax Documents',
          selectBeginDate: format(new Date(), 'yyyy-01-01'),
          selectedEndDate: format(new Date(), 'yyyy-12-31')
        })
      )
      return
    }

    dispatch(
      documentFilterActions.setDateRange({
        documentTabType,
        selectBeginDate: format(
          subDays(new Date(), documentTabType === 'Statements' ? 60 : 30),
          'yyyy-MM-dd'
        ),
        selectedEndDate: format(new Date(), 'yyyy-MM-dd')
      })
    )
  }, [dispatch, dispatchDateRange, documentTabType, selectedDateRange])

  return (
    <>
      <div css={classes.dateRangeContainer}>
        <div>Date Range</div>
        <div css={classes.dateRangeDropdown}>
          <Dropdown
            options={documentRangeOptions[documentTabType]}
            selectedKey={selectedDropDownOption}
            onChange={handlDateRangeChange}
          />
        </div>
      </div>
      {documentTabType !== 'Tax Documents' &&
        selectedDropDownOption === 'customRange' && (
          <div
            css={{
              display: 'flex',
              flexWrap: 'nowrap',
              columnGap: 10,
              flexGrow: 1
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 3
              }}
            >
              <div css={{ fontSize: '14px', fontWeight: 500 }}>From Date</div>
              <div
                css={{
                  fontSize: '14px',
                  lineHeight: '1.5',
                  width: '140px'
                }}
              >
                <DatePicker
                  value={startDate ? parseDate(startDate) : undefined}
                  onSelectDate={(date) =>
                    date && setStartDate(format(date, 'yyyy-MM-dd'))
                  }
                  formatDate={(date) =>
                    date ? format(date, 'yyyy-MM-dd') : ''
                  }
                  maxDate={endDate ? parseDate(endDate) : new Date()}
                  placeholder="YYYY-MM-DD"
                />
              </div>
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 3
              }}
            >
              <div css={{ fontSize: '14px', fontWeight: 500 }}>To Date</div>
              <div
                css={{
                  fontSize: '14px',
                  lineHeight: '1.5',
                  width: '140px'
                }}
              >
                <DatePicker
                  value={endDate ? parseDate(endDate) : undefined}
                  onSelectDate={(date) =>
                    date && setEndDate(format(date, 'yyyy-MM-dd'))
                  }
                  formatDate={(date) =>
                    date ? format(date, 'yyyy-MM-dd') : ''
                  }
                  minDate={startDate ? parseDate(startDate) : undefined}
                  maxDate={new Date()}
                  placeholder="YYYY-MM-DD"
                />
              </div>
            </div>
            <button
              css={[buttonStyles.primary, { width: '80px', marginTop: 20 }]}
              onClick={handleCustomSelection}
              disabled={!startDate || !endDate}
            >
              Apply
            </button>
          </div>
        )}
      <div
        css={[
          !!selection.length && !isDownloading
            ? classes.iconStyle
            : classes.iconStyleDisable,
          classes.iconContainer
        ]}
      >
        <DownloadMenuCallout
          onIndividualDownloadClick={onIndividualDownloadClick}
          onCombinedDownloadClick={onCombinedDownloadClick}
          onPrintClick={onPrintClick}
          selection={selection}
        />
      </div>
    </>
  )
}

export default DocumentFilter
