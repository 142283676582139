import { css } from '@emotion/react'
import { intersectionWith } from 'lodash'
import { useCallback, useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import { useIncomeFeatureStore } from '../../../features/Income'
import { WidgetTable } from '../../../features/WidgetTable/WidgetTable'
import { IndeterminateCheckbox } from '../../../shared/IndeterminateCheckbox'
import {
  IIncomeCategory,
  IIncomeSummaryChartViewResponse
} from '../../../store/holdingsApi/IIncomeSummaryChartViewResponse'
import { useGetIncomeSummaryQueryForSelectedAccounts } from '../../../store/rdot360Context'
import { IncomeDisclamer } from './IncomeDisclamer'
import { getIncomeWidgetTableColumns } from './IncomeWidgetTableColumns'

export const getClasses = () => ({
  checkboxContainer: css({
    margin: '4px 0px',
    display: 'flex',
    flexDirection: 'row'
  }),
  checkboxLabel: css({
    marginLeft: '4px',
    fontSize: '12px'
  }),
  incomeTable: css({
    minHeight: 0,
    marginTop: 4,
    'tr td, tr th': {
      padding: '8px 3px'
    },
    'td:first-of-type, th:first-of-type': { paddingLeft: '6px' },
    'td:last-of-type, th:last-of-type': { paddingRight: '6px' }
  })
})

const excludedCategories = [
  'principal',
  'interestPurchasedSold',
  'principalPayments',
  'interestPurchased(-) /Sold'
]

const optionPremiumIds = ['optionPremiumsPaid', 'optionPremiumsReceived']

const emptyData = {
  IncomeCategories: [
    {
      id: 'interestReceived',
      Description: 'Interest Received'
    },
    {
      id: 'dividends',
      Description: 'Dividends'
    },
    {
      id: 'TOTCAPGAIN',
      Description: 'Capital Gains Distribution'
    },
    {
      id: 'principalPayments',
      Description: 'Principal Payments'
    },
    {
      id: 'optionPremiumsPaid',
      Description: 'Option Premiums Paid'
    },
    {
      id: 'optionPremiumsReceived',
      Description: 'Option Premiums Received'
    }
  ],
  Total: {}
} as IIncomeSummaryChartViewResponse

export const IncomeWidgetTable: React.FC = () => {
  const {
    data: apiData,
    isFetching,
    isLoading,
    isUninitialized,
    isError
  } = useGetIncomeSummaryQueryForSelectedAccounts()
  const classes = useMemo(() => getClasses(), [])
  const columns = useMemo(getIncomeWidgetTableColumns, [])
  const { includeOptionPremiums, setIncludeOptionPremiums } =
    useIncomeFeatureStore()

  const data = useMemo(
    () => (isUninitialized || isLoading || isError ? emptyData : apiData),
    [apiData, isLoading, isUninitialized, isError]
  )

  const principalData = useMemo(() => {
    const categories = ['principal', 'principalPayments']

    return intersectionWith(
      data?.IncomeCategories,
      categories,
      (a, b) => a.id === b
    )
  }, [data?.IncomeCategories])

  const isOptionsPremiumsAvailable = useMemo(
    () =>
      isUninitialized ||
      isLoading ||
      !!data?.IncomeCategories.some((x) => {
        return (
          optionPremiumIds.includes(x.id) &&
          (x.YTD || x.Last12Months || x.Next12Months)
        )
      }),
    [data?.IncomeCategories, isLoading, isUninitialized]
  )

  const tableData = useMemo(() => {
    let incomeData: IIncomeCategory[] = []

    if (data) {
      const { IncomeCategories } = data

      if (includeOptionPremiums && isOptionsPremiumsAvailable) {
        incomeData = IncomeCategories.map((item) => ({
          ...item,
          Next12Months: optionPremiumIds.includes(item.id)
            ? undefined
            : item.Next12Months
        }))
      } else {
        incomeData = IncomeCategories.filter(
          (item) => !optionPremiumIds.includes(item.id)
        )
      }

      incomeData = incomeData.filter(
        (item) => !excludedCategories.includes(item.id)
      )
    }

    return incomeData
  }, [data, includeOptionPremiums, isOptionsPremiumsAvailable])

  const handleCheckboxChange = useCallback(
    (e: any) => {
      setIncludeOptionPremiums(e.target.checked)
    },
    [setIncludeOptionPremiums]
  )

  return (
    <>
      {isOptionsPremiumsAvailable && (
        <div css={classes.checkboxContainer}>
          <div>
            <IndeterminateCheckbox
              id="optionPremiumsLabelCheckbox"
              className="checkbox"
              checked={includeOptionPremiums}
              onChange={handleCheckboxChange}
            />
          </div>
          <label
            htmlFor="optionPremiumsLabelCheckbox"
            css={classes.checkboxLabel}
          >
            Include Option Premiums
          </label>
        </div>
      )}
      <div css={classes.incomeTable}>
        <WidgetTable
          data={tableData}
          columns={columns}
          isLoading={isFetching}
          isFooter={true}
        />
      </div>
      {!!principalData?.length && (
        <div css={classes.incomeTable}>
          <table
            css={{
              width: '100%',
              tableLayout: 'fixed',
              borderCollapse: 'collapse'
            }}
          >
            <tbody>
              {principalData.map((item) => (
                <tr
                  key={item.Description}
                  css={{
                    fontSize: 12
                  }}
                >
                  <td css={{ width: 100 }}>{item.Description}</td>
                  <td align="right" css={{ width: 60 }}>
                    {item.YTD ? (
                      <USD
                        value={item.YTD}
                        fractionDigits={0}
                        currencySign="standard"
                      />
                    ) : (
                      '--'
                    )}
                  </td>
                  <td align="right" css={{ width: 60 }}>
                    {item.Last12Months ? (
                      <USD
                        value={item.Last12Months}
                        fractionDigits={0}
                        currencySign="standard"
                      />
                    ) : (
                      '--'
                    )}
                  </td>
                  <td align="right" css={{ width: 60 }}>
                    {item.Next12Months ? (
                      <USD
                        value={item.Next12Months}
                        fractionDigits={0}
                        currencySign="standard"
                      />
                    ) : (
                      '--'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <IncomeDisclamer />
      <div
        css={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      >
        {isFetching && <IndeterminateProgressIndicator />}
      </div>
    </>
  )
}
