import { IOdataListColumnDefinition } from 'features/OdataList/common/types'

export type RetirementHoldingColumnName =
  | 'Advisor ID'
  | 'Asset Class Level 1'
  | 'Asset Class Level 2'
  | 'Asset Class Level 3'
  | 'Asset Class Level 4'
  | 'Company Name'
  | 'Contract Number'
  | 'Market Value'
  | 'Plan Name'
  | 'Plan Provider'
  | 'Plan Type'
  | 'Ticker'
  | 'Underlying CUSIP'
  | 'Underlying Holdings Name'
  | 'Valuation Date'

export interface IRetirementHoldingColumnDefinition
  extends IOdataListColumnDefinition {
  name: RetirementHoldingColumnName
}

export const retirementHoldingsColumns: IRetirementHoldingColumnDefinition[] = [
  {
    name: 'Advisor ID',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/repId',
    expand: ['RetirementPlan($select=repId)'],
    filterable: true,
    type: 'string',
    width: 60
  },
  {
    name: 'Contract Number',
    dataPath: 'contractNumber',
    filterable: true,
    searchFields: ['contractNumber'],
    select: ['contractNumber'],
    sortable: true,
    type: 'string',
    width: 80
  },
  {
    name: 'Company Name',
    collectionPath: 'retirementFund',
    dataPath: 'retirementFund/companyName',
    expand: ['RetirementFund($select=companyName)'],
    filterable: true,
    searchFields: ['RetirementFund/companyName'],
    sortable: true,
    type: 'string',
    width: 240
  },
  {
    name: 'Plan Name',
    collectionPath: 'retirementFund',
    dataPath: 'retirementFund/planName',
    expand: ['RetirementFund($select=planName)'],
    searchFields: ['retirementFund/planName'],
    sortable: true,
    filterable: true,
    type: 'string',
    width: 280
  },
  {
    name: 'Plan Provider',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/planProvider',
    expand: ['RetirementPlan($select=planProvider)'],
    facetable: true,
    filterable: true,
    searchFields: ['retirementPlan/planProvider'],
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Underlying CUSIP',
    dataPath: 'cusip',
    type: 'string',
    select: ['cusip'],
    width: 90,
    sortable: true,
    filterable: true
  },
  {
    name: 'Underlying Holdings Name',
    dataPath: 'holdingsName',
    filterable: true,
    type: 'string',
    select: ['holdingsName'],
    searchFields: ['holdingsName', 'security/securityDescription'],
    sortable: true,
    expand: ['Security($select=securityDescription)'],
    width: 250
  },
  {
    name: 'Ticker',
    collectionPath: 'security',
    dataPath: 'security/symbol',
    expand: ['Security($select=symbol)'],
    filterable: true,
    searchFields: ['security/symbol'],
    sortable: true,
    type: 'string',
    width: 65
  },
  {
    name: 'Asset Class Level 1',
    collectionPath: 'security',
    dataPath: 'security/l1Description',
    expand: ['Security($select=l1Description)'],
    facetable: true,
    filterable: true,
    searchFields: ['security/l1Description'],
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Asset Class Level 2',
    collectionPath: 'security',
    dataPath: 'security/l2Description',
    expand: ['Security($select=l2Description)'],
    facetable: true,
    filterable: true,
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Asset Class Level 3',
    collectionPath: 'security',
    dataPath: 'security/l3Description',
    expand: ['Security($select=l3Description)'],
    facetable: true,
    filterable: true,
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Asset Class Level 4',
    collectionPath: 'security',
    dataPath: 'security/l4Description',
    expand: ['Security($select=l4Description)'],
    facetable: true,
    filterable: true,
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Market Value',
    dataPath: 'marketValue',
    filterable: true,
    type: 'number',
    select: ['marketValue'],
    sortable: true,
    width: 120
  },
  {
    name: 'Valuation Date',
    dataPath: 'asOfDate',
    facetable: true,
    filterable: true,
    select: ['asOfDate'],
    sortable: true,
    type: 'date-only',
    width: 90
  }
]
