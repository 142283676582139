import { ExpandedState } from '@tanstack/react-table'
import { getRandomColor } from 'api/random'
import { sumBy } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { HighchartsComponent } from 'shared/highcharts'
import { getViewByKey } from '../../modules/Investments/features/InvestmentsTable/shared'
import { useInvestmentsTableStore } from '../../modules/Investments/features/InvestmentsTable/store'
import { getTaxonomyColorLookupByType } from '../../shared/taxonomy'
import { ICategoryPositionResponseValue } from '../../store/holdingsApi/ICategoryPostitionResponse'

const getAssetsAllocationOptions = (
  data: ICategoryPositionResponseValue[] = [],
  totalValue = 0,
  onLegendClick: (assetClass: string) => void,
  colorLookup: Record<string, string> = {}
): Highcharts.Options => ({
  title: {
    text:
      totalValue === 0
        ? ''
        : totalValue > 0
        ? 'Total<br> $' + Math.round(totalValue).toLocaleString('en-US')
        : 'Total<br> -$' +
          Math.round(Math.abs(totalValue)).toLocaleString('en-US'),
    align: 'center',
    verticalAlign: 'middle',
    x: -90,
    y: 10
  },
  chart: {
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat:
      '<p>{point.isNegative}${point.y}</p>' +
      '<br>{point.isNegative}{point.percentage:.1f}%</br>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },

  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: true
        }
      }
    },
    pie: {
      events: {
        click: (ev) => {
          ev?.preventDefault && ev.preventDefault()
          const assetClassChartValue = ev?.point?.name
          onLegendClick(assetClassChartValue)
        }
      },
      allowPointSelect: true,
      cursor: 'pointer',
      point: {
        events: {
          legendItemClick: (ev) => {
            ev?.preventDefault && ev.preventDefault()
            const assetClassValue = ev?.target?.name
            onLegendClick(assetClassValue)
          }
        }
      },
      showInLegend: true,
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [
    {
      type: 'pie',
      innerSize: '80%',
      data: data
        ?.map((item) => ({
          name: item.assetcat === 'Cash' ? 'Cash & Equivalents' : item.assetcat,
          y: Math.round(Math.abs(item.mktval || 0) || 0),
          color: `#${
            colorLookup[item.assetcat || 'Others'] ?? getRandomColor()
          }`,
          value: item.mktval,
          isNegative: item.mktval && item.mktval < 0 ? '-' : ''
        }))
        .sort((a, b) => (b.value || 0) - (a.value || 0))
    }
  ],
  credits: {
    enabled: false
  },
  legend: {
    backgroundColor: undefined,
    itemStyle: {
      color: 'black'
    },
    enabled: true,
    symbolRadius: 0,
    layout: 'vertical',
    align: 'right',
    itemWidth: 150,
    verticalAlign: 'middle'
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 300
        }
      }
    ]
  }
})

const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%' }
}
export const AssetsAllocationChart: FC<{
  data?: ICategoryPositionResponseValue[]
  selectedType: string
}> = ({ data = [], selectedType }) => {
  const navigate = useNavigate()
  const { setDefaultExpanded, setInvestmentsViewByKey } =
    useInvestmentsTableStore()
  const totalValue = useMemo(
    () => sumBy(data, ({ mktval = 0 }) => mktval),
    [data]
  )
  const onLegendClick = useCallback(
    (assetClassValue: string) => {
      const viewByKey = getViewByKey(selectedType)
      const parameter: ExpandedState = {
        [`${viewByKey}:${assetClassValue}`]: true
      }
      navigate('../investments')
      setDefaultExpanded(parameter)
      setInvestmentsViewByKey(viewByKey)
    },
    [navigate, selectedType, setDefaultExpanded, setInvestmentsViewByKey]
  )

  const colorLookup = getTaxonomyColorLookupByType(selectedType)

  const assetsAllocationOptions = useMemo(() => {
    return getAssetsAllocationOptions(
      data,
      totalValue,
      onLegendClick,
      colorLookup
    )
  }, [data, totalValue, onLegendClick, colorLookup])

  return (
    <HighchartsComponent
      containerProps={containerProps}
      options={assetsAllocationOptions}
    />
  )
}
