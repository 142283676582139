import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api, Rdot360ApiTagType } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import { ICategoryPositionResponseValue } from './ICategoryPostitionResponse'
import { IIncomeMonthlySummaryResponse } from './IIncomeMonthlySummaryResponse'
import { IIncomeSummaryChartViewResponse } from './IIncomeSummaryChartViewResponse'
import {
  IIncomeSummaryChartResponse,
  IIncomeSummaryResponse
} from './IIncomeSummaryResponse'
import { ITop10HoldingResponseValue } from './ITop10HoldingResponse'

export interface IHoldingApiBaseValueResponse<T> {
  value: T[]
}

export interface IHoldingApiBaseRequest {
  accounts?: string[]
  contextId: string
}

export type AssetClassLevel = 'L1' | 'L2' | 'L3' | 'L4'

export type PositionCategoryType = AssetClassLevel | 'P' | 'S'

const { cacheTime } = apiConstants

type HoldingsApiTagType =
  | 'top10Holdings'
  | 'positionByCategory'
  | 'categoryPosition'
  | 'historicalIncomeChart'
  | 'projectedIncomeChart'
  | 'incomeDetailBySecurity'
  | 'incomeSummary'
  | 'incomeTaxSummary'

const holdingsApiTags: HoldingsApiTagType[] = [
  'top10Holdings',
  'positionByCategory',
  'categoryPosition',
  'historicalIncomeChart',
  'projectedIncomeChart',
  'incomeDetailBySecurity',
  'incomeSummary',
  'incomeTaxSummary'
]
const holdingsApiWithTags = rdot360Api.enhanceEndpoints({
  addTagTypes: holdingsApiTags
})

export type IncomeApiMode = 'historical' | 'projected'

const getDefaultTags = (
  type: HoldingsApiTagType,
  req: IHoldingApiBaseRequest
): TagDescription<HoldingsApiTagType | Rdot360ApiTagType>[] => [
  'rdot360',
  { type: 'rdot360', id: req.contextId },
  type,
  { type, id: req.contextId }
]

const getFinancialYear = (financialYear?: number) =>
  `${
    financialYear === 12
      ? new Date().getFullYear().toString() + ',12'
      : financialYear
  }`

const baseTransformResponse = <T>(response: IHoldingApiBaseValueResponse<T>) =>
  response?.value?.[0]

const baseTransformResponseArray = <T>(
  response: IHoldingApiBaseValueResponse<T>[]
) => response?.[0]?.value

const providesTags =
  (type: HoldingsApiTagType) =>
  (_: unknown, _1: unknown, req: IHoldingApiBaseRequest) =>
    getDefaultTags(type, req)

const holdingsApi = holdingsApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTop10Holdings: builder.query<
      ITop10HoldingResponseValue | undefined,
      IHoldingApiBaseRequest
    >({
      query: ({ contextId }) => {
        return {
          url: "holdings/odata/Position/Top10Holding('',null)",
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponse,
      providesTags: providesTags('top10Holdings')
    }),
    getPositionByCategory: builder.query<
      ICategoryPositionResponseValue[] | undefined,
      IHoldingApiBaseRequest & { category?: PositionCategoryType }
    >({
      query: ({ contextId, category }) => {
        return {
          url: `holdings/odata/Position/GetPositionByCategorySecurity('',null,${category})`,
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponseArray,
      providesTags: providesTags('positionByCategory')
    }),
    getCategoryPosition: builder.query<
      ICategoryPositionResponseValue[] | undefined,
      IHoldingApiBaseRequest & { category: PositionCategoryType }
    >({
      query: ({ contextId, category }) => {
        return {
          url: `holdings/odata/Position/CategoryPosition('',null,${category})`,
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponseArray,
      providesTags: providesTags('categoryPosition')
    }),
    getIncomeSummary: builder.query<
      IIncomeSummaryChartViewResponse | undefined,
      IHoldingApiBaseRequest
    >({
      query: ({ contextId }) => ({
        url: `holdings/odata/Position/GetIncomeSummaryChartView(true)`,
        headers: { contextjson: contextId, profilejson: contextId }
      }),
      keepUnusedDataFor: cacheTime,
      providesTags: providesTags('incomeSummary')
    }),
    getHistoricalIncomeChart: builder.query<
      IIncomeMonthlySummaryResponse | undefined,
      IHoldingApiBaseRequest & { financialYear?: number }
    >({
      query: ({ contextId, financialYear }) => {
        return {
          url: `holdings/odata/Position/GetIncomeMonthlySummary(${getFinancialYear(
            financialYear
          )})`,
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      transformResponse: (
        response: IIncomeMonthlySummaryResponse[]
      ): IIncomeMonthlySummaryResponse | undefined => {
        return response?.[0]
      },
      providesTags: providesTags('historicalIncomeChart'),
      keepUnusedDataFor: cacheTime
    }),
    getProjectedIncomeChart: builder.query<
      IIncomeSummaryChartResponse | undefined,
      IHoldingApiBaseRequest & { financialYear?: number }
    >({
      query: ({ contextId, financialYear }) => {
        return {
          url: `holdings/odata/Position/GetProjectedIncomeSummary(C,${financialYear})`,
          headers: { contextjson: contextId, profilejson: contextId }
        }
      },
      transformResponse: (response: IIncomeSummaryChartResponse[]) => {
        return response?.[0]
      },
      providesTags: providesTags('projectedIncomeChart'),
      keepUnusedDataFor: cacheTime
    }),
    getIncomeDetailBySecurity: builder.query<
      IIncomeSummaryResponse | undefined,
      IHoldingApiBaseRequest & { type: IncomeApiMode; financialYear?: number }
    >({
      query: ({ contextId, financialYear, type = 'historical' }) => ({
        url: `holdings/odata/Position/${
          type === 'historical'
            ? 'GetEstimatedIncomeSummary'
            : 'GetProjectedIncomeSummary'
        }(S,${financialYear})`,
        headers: { contextjson: contextId, profilejson: contextId }
      }),
      transformResponse: baseTransformResponse,
      providesTags: providesTags('incomeDetailBySecurity'),
      keepUnusedDataFor: cacheTime
    }),
    getIncomeTaxSummary: builder.query<
      IIncomeSummaryResponse | undefined,
      IHoldingApiBaseRequest & { type: IncomeApiMode; financialYear?: number }
    >({
      query: ({ contextId, financialYear, type = 'historical' }) => ({
        url: `holdings/odata/Position/${
          type === 'historical'
            ? 'GetEstimatedIncomeSummary'
            : 'GetProjectedIncomeSummary'
        }(A2,${financialYear})`,
        headers: { contextjson: contextId, profilejson: contextId }
      }),
      transformResponse: baseTransformResponse,
      providesTags: providesTags('incomeTaxSummary'),
      keepUnusedDataFor: cacheTime
    })
  })
})

export const {
  useGetTop10HoldingsQuery,
  useGetPositionByCategoryQuery,
  useGetCategoryPositionQuery,
  useGetHistoricalIncomeChartQuery,
  useGetIncomeSummaryQuery,
  useGetProjectedIncomeChartQuery,
  useGetIncomeDetailBySecurityQuery,
  useGetIncomeTaxSummaryQuery
} = holdingsApi

export const useHoldingsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<HoldingsApiTagType>[]) =>
      dispatch(holdingsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
