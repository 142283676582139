import {
  AccessorFnColumnDef,
  CellContext,
  HeaderContext,
  Row,
  createColumnHelper
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { AccountNumberCell } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/AccountNumberCell'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { IIncomeSummaryResponseItem } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/IIncomeSummaryResponse'
import { FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { incomeAccountsColumnNames } from './ColumnNames'

const columnHelper = createColumnHelper<IIncomeSummaryResponseItem>()

const numberSorting = (a: any, b: any, columnId: string) => {
  const sortValue = a.getValue(columnId) > b.getValue(columnId) ? 1 : -1

  return sortValue
}

const getDefaultValue = (value: any) => (value != null ? '--' : '')

const renderAmountField = (props: CellContext<any, any>) => {
  return (
    <div
      css={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }}
    >
      {props.getValue() ? (
        <USD
          value={props.getValue()}
          fractionDigits={2}
          currencySign="standard"
        />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </div>
  )
}

const SumFooter: React.FC<HeaderContext<any, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return (
    <div
      css={{
        textAlign: 'right',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }}
    >
      {column.id === 'quantity' ? (
        <FormattedNumber
          value={total}
          currencySign="accounting"
          maximumFractionDigits={2}
          minimumFractionDigits={2}
        />
      ) : (
        <USD value={total} fractionDigits={2} currencySign={'standard'} />
      )}
    </div>
  )
}

const renderExpnadCollapseIcon = (row: Row<any>) => {
  const icon = row.subRows.length ? (
    <Icon type="Add" width={14} height={14} color="black" />
  ) : (
    ''
  )

  return (
    <div css={{ width: 14, height: 14, margin: '0 12px' }}>
      {row.getIsExpanded() ? (
        <Icon type="Subtract" width={14} height={14} color="black" />
      ) : (
        icon
      )}
    </div>
  )
}

export const getColumns = (
  searchText: string,
  isHistorical: boolean,
  includeOptionPremiums: boolean
) =>
  [
    columnHelper.accessor((row) => row.ACCT, {
      id: incomeAccountsColumnNames.accounts,
      header: incomeAccountsColumnNames.accounts,
      enableGlobalFilter: true,
      cell: (props: any) => {
        const { row } = props
        return props.getValue() ? (
          <div
            {...{
              onClick: row.getToggleExpandedHandler()
            }}
            css={{
              cursor: row.subRows.length ? 'pointer' : '',
              display: 'flex'
            }}
          >
            {renderExpnadCollapseIcon(row)}
            <div
              css={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
            >
              <AccountNumberCell
                accountIdOrKey={props.getValue()}
                search={searchText}
              />
            </div>
          </div>
        ) : (
          ''
        )
      },
      footer: 'Total',
      minSize: 250
    }),
    columnHelper.accessor((row) => row.SECNAME || row.CUSIPNUM, {
      id: incomeAccountsColumnNames.symbolCUSIP,
      header: incomeAccountsColumnNames.symbolCUSIP,
      enableGlobalFilter: true,
      cell: (props) => (
        <HighlightSearchText
          text={props.getValue() || ''}
          search={searchText}
        />
      ),
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor((row) => row.SECDESCRIPTION, {
      id: incomeAccountsColumnNames.secDescription,
      header: incomeAccountsColumnNames.secDescription,
      cell: (props) => (
        <HighlightSearchText
          text={props.getValue() || ''}
          search={searchText}
        />
      ),
      enableGlobalFilter: true,
      minSize: 50,
      size: 250
    }),
    columnHelper.accessor((row) => row.TotalTaxExemptDiv, {
      id: incomeAccountsColumnNames.taxExemptDividends,
      header: incomeAccountsColumnNames.taxExemptDividends,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 80,
      size: 150
    }),
    columnHelper.accessor((row) => row.TotalQualifiedDiv, {
      id: incomeAccountsColumnNames.qualifiedDividends,
      header: incomeAccountsColumnNames.qualifiedDividends,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 80,
      size: 150
    }),
    columnHelper.accessor((row) => row.TotalNonQualifiedDiv, {
      id: incomeAccountsColumnNames.nonQualifiedDividends,
      header: incomeAccountsColumnNames.nonQualifiedDividends,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 80,
      size: 150
    }),
    columnHelper.accessor((row) => row.TotalDiv, {
      id: incomeAccountsColumnNames.totalDividends,
      header: incomeAccountsColumnNames.totalDividends,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 80,
      size: 150
    }),
    columnHelper.accessor((row) => row.TotalTaxExemptInt, {
      id: incomeAccountsColumnNames.taxExemptInterest,
      header: incomeAccountsColumnNames.taxExemptInterest,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 80,
      size: 150
    }),
    columnHelper.accessor((row) => row.TotalTaxableInt, {
      id: incomeAccountsColumnNames.taxableInterest,
      header: incomeAccountsColumnNames.taxableInterest,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 80,
      size: 150
    }),
    ...(includeOptionPremiums && isHistorical
      ? [
          columnHelper.accessor(
            (row) =>
              (row.TotalOptionPremiumsPaid || 0) +
              (row.TotalOptionPremiumsReceived || 0) +
              (row.interestReceived || 0) +
              (row.capitalGains || 0),
            {
              id: incomeAccountsColumnNames.optionPremiumsPaidReceived,
              header: incomeAccountsColumnNames.optionPremiumsPaidReceived,
              sortingFn: numberSorting,
              cell: renderAmountField,
              enableGlobalFilter: false,
              footer: SumFooter,
              minSize: 80,
              size: 150
            }
          )
        ]
      : []),
    columnHelper.accessor((row) => row.TotalInt, {
      id: incomeAccountsColumnNames.totalInterest,
      header: incomeAccountsColumnNames.totalInterest,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 80,
      size: 150
    }),
    ...(!isHistorical
      ? []
      : [
          columnHelper.accessor((row) => row.TotalCap, {
            id: incomeAccountsColumnNames.capitalGainsDistribution,
            header: incomeAccountsColumnNames.capitalGainsDistribution,
            sortingFn: numberSorting,
            cell: renderAmountField,
            enableGlobalFilter: false,
            footer: SumFooter,
            minSize: 80,
            size: 150
          })
        ]),
    columnHelper.accessor(
      (row) =>
        !includeOptionPremiums && isHistorical
          ? row.TotalExcludingOptionPremiums
          : row.Total,
      {
        id: !includeOptionPremiums
          ? incomeAccountsColumnNames.totalExcludingOptionPremiums
          : incomeAccountsColumnNames.totalIncome,
        header: incomeAccountsColumnNames.totalIncome,
        sortingFn: numberSorting,
        cell: renderAmountField,
        enableGlobalFilter: false,
        footer: SumFooter,
        minSize: 80,
        size: 150
      }
    ),

    ...(!isHistorical
      ? []
      : [
          columnHelper.accessor((row) => row.TotalPrincipal, {
            id: incomeAccountsColumnNames.principalPayments,
            header: incomeAccountsColumnNames.principalPayments,
            sortingFn: numberSorting,
            cell: renderAmountField,
            enableGlobalFilter: false,
            footer: SumFooter,
            minSize: 80,
            size: 150
          })
        ])
  ] as AccessorFnColumnDef<IIncomeSummaryResponseItem | undefined>[]
