import {
  CellContext,
  HeaderContext,
  createColumnHelper
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { HeaderContainer } from '../../../components/shared/DetailTables/HeaderContainer'
import { USDCell } from '../../../features/WidgetTable/WidgetTable'
import { IIncomeCategory } from '../../../store/holdingsApi/IIncomeSummaryChartViewResponse'

const projectedIncomeColumnsNames = {
  description: 'Description',
  ytd: 'YTD',
  last12Months: 'Last 12 Months',
  next12Months: 'Next 12 Months'
}

const discriptionMapping = {
  interestReceived: 'Interest Received',
  dividends: 'Dividends',
  TOTCAPGAIN: 'Capital Gains Distribution',
  optionPremiumsPaid: 'Option Premiums Paid',
  optionPremiumsReceived: 'Option Premiums Received',
  principalPayments: 'Principal Payments',
  interestPaid: 'Interest Paid',
  interestPurchased: 'Interest Purchased',
  interestSold: 'Interest Sold',
  interestPurchasedSold: 'Interest Purchased/Sold',
  interestReceivedPaid: 'Interest Received/Paid',
  interestReceivedSold: 'Interest Received/Sold',
  interestReceivedPurchased: 'Interest Received/Purchased',
  interestReceivedPurchasedSold: 'Interest Received/Purchased/Sold',
  principal: 'Principal'
}

const columnHelper = createColumnHelper<IIncomeCategory>()

const USDTableCell: React.FC<CellContext<IIncomeCategory, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  return <USDCell value={value} defaultValue="--" />
}

const SumFooter: React.FC<
  HeaderContext<IIncomeCategory, number | undefined>
> = ({ table, column }) => {
  const total = sum(
    table
      .getRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return total !== 0 ? <USDCell value={total} /> : ''
}

const Header: React.FC<
  HeaderContext<IIncomeCategory, number | string | undefined>
> = ({ column }) => (
  <HeaderContainer
    title={column.id}
    isSorted={column.getIsSorted()}
    rightAlign={column.id !== projectedIncomeColumnsNames.description}
  />
)

export const getIncomeWidgetTableColumns = () => {
  const columns = [
    columnHelper.accessor(({ id }) => id, {
      id: projectedIncomeColumnsNames.description,
      header: Header,
      size: 100,
      cell: (props) => discriptionMapping[props.getValue()],
      footer: 'Total'
    }),
    columnHelper.accessor(({ YTD }) => YTD, {
      id: projectedIncomeColumnsNames.ytd,
      header: Header,
      size: 60,
      cell: USDTableCell,
      footer: SumFooter
    }),
    columnHelper.accessor(({ Last12Months }) => Last12Months, {
      id: projectedIncomeColumnsNames.last12Months,
      header: Header,
      size: 60,
      cell: USDTableCell,
      footer: SumFooter
    }),
    columnHelper.accessor(({ Next12Months }) => Next12Months, {
      id: projectedIncomeColumnsNames.next12Months,
      header: Header,
      size: 60,
      cell: USDTableCell,
      footer: SumFooter
    })
  ]

  return columns
}
