import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getNfsProfiles, INfsProfile } from '../../../api/datahub'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../store/shared/sagas'

export const accountLinkingNfsProfileFetchActions = createAsyncAction(
  '@features/@accountLinking/@features/@accountLinkingRequestView/@nfsProfileFetch/REQUEST',
  '@features/@accountLinking/@features/@accountLinkingRequestView/@nfsProfileFetch/SUCCESS',
  '@features/@accountLinking/@features/@accountLinkingRequestView/@nfsProfileFetch/FAILURE'
)<string, INfsProfile, Error>()

export const accountLinkingNfsProfileFetchReducer = createAsyncReducer(
  accountLinkingNfsProfileFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.accountLinking.nfsProfileFetch

export const {
  getError: getAccountLinkingNfsProfileError,
  getIsLoading: getIsAccountLinkingNfsProfileLoading,
  getResult: getAccountLinkingNfsProfileResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof accountLinkingNfsProfileFetchActions.request>
) {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)

    const result = yield* call(getNfsProfiles, apiOptions, {
      top: 1,
      filters: [`wsportaluserid eq '${action.payload}'`]
    })

    const [first] = result?.value || []
    if (!first) {
      throw new Error('Profile not found')
    }
    yield put(accountLinkingNfsProfileFetchActions.success(first))
  } catch (e: unknown) {
    yield put(accountLinkingNfsProfileFetchActions.failure(e as Error))
  }
}

export const accountLinkingNfsProfileFetchSagas = [
  () => takeLatest(accountLinkingNfsProfileFetchActions.request, onRequest)
]
