import { useDomainStore } from 'features/Domain/store/domain'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IAiDashboardFilterState,
  aiDashboardActions,
  selectAiDasbhoardFilterFacets,
  selectAiDashboardFilterDisplayValues,
  selectAiDashboardFilteredPositionData,
  selectAiDashboardFilters,
  selectAiDashboardRequest
} from './aiDashboard'
import { useFetchAllAiPositionsQuery } from './aiDashboardPositionsApi'

export const useAiPostions = () => {
  const { isFetching: isDomainFetching, error: domainError } = useDomainStore()

  const request = useSelector(selectAiDashboardRequest)

  const {
    data,
    isFetching: isPositionsFetching,
    error: positionsError
  } = useFetchAllAiPositionsQuery(request)

  const filteredData = useSelector(selectAiDashboardFilteredPositionData)
  const filterFacets = useSelector(selectAiDasbhoardFilterFacets)
  const filterDisplayValues = useSelector(selectAiDashboardFilterDisplayValues)
  const filters = useSelector(selectAiDashboardFilters)

  const dispatch = useDispatch()
  const updateFilters = useCallback(
    (filters?: Partial<IAiDashboardFilterState>) => {
      dispatch(aiDashboardActions.updateFilters(filters))
    },
    [dispatch]
  )

  const resetFilters = useCallback(() => {
    dispatch(aiDashboardActions.resetFilters())
  }, [dispatch])

  return {
    data,
    isFetching: isDomainFetching || isPositionsFetching,
    error: domainError || positionsError,
    filteredData,
    filterFacets,
    filterDisplayValues,
    filters,
    updateFilters,
    resetFilters
  }
}
