import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api, Rdot360ApiTagType } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import {
  IBalanceDetailResponseValue,
  IBalanceDetailResponseValueItem
} from './IBalanceDetailResponse'

export interface IBalancesApiBaseValueResponse<T> {
  value: T[]
}

export interface IBalancesApiBaseRequest {
  contextId: string
  contextSessionKey: string
}

export interface IBalancesAssetAllocationApiRequest
  extends IBalancesApiBaseRequest {
  contextAccounts: string[]
  payload: {
    fromdate: string
    todate: string
    grain: 'Day' | 'Month'
  }
}

export interface IBalancesAssetAllocation {
  accountNumber: string
  accountKey: string
  asOfDate: string
  assetType: string
  code: string
  calendarYear: number
  month: number
  dayOfMonth: number
  marketValue: number
}
export interface IRealTimeBalanceApiRequest {
  accountNumber?: string
}

const { cacheTime } = apiConstants

type BalancesApiTagType = 'balancesDetail'
const balancesApiTags: BalancesApiTagType[] = ['balancesDetail']
const balancesApiWithTags = rdot360Api.enhanceEndpoints({
  addTagTypes: balancesApiTags
})

const getDefaultTags = (
  type: BalancesApiTagType,
  req: IBalancesApiBaseRequest
): TagDescription<BalancesApiTagType | Rdot360ApiTagType>[] => [
  'rdot360',
  { type: 'rdot360', id: req.contextId },
  type,
  { type, id: req.contextId }
]

const baseTransformResponse = <T>(response: IBalancesApiBaseValueResponse<T>) =>
  response?.value?.[0]

const providesTags =
  (type: BalancesApiTagType) =>
  (_: unknown, _1: unknown, req: IBalancesApiBaseRequest) =>
    getDefaultTags(type, req)

export const balancesApi = balancesApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getBalancesDetail: builder.query<
      IBalanceDetailResponseValue | undefined,
      IBalancesApiBaseRequest
    >({
      query: ({ contextSessionKey, contextId }) => {
        return {
          url: `balance/odata/balancedetails?Retrieve=AllAccts&contextId=${contextId}`,
          headers: {
            contextjson: contextSessionKey,
            profilejson: contextSessionKey
          }
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponse,
      providesTags: providesTags('balancesDetail')
    }),
    getBalancesAssetallocation: builder.query<
      IBalancesAssetAllocation[] | undefined,
      IBalancesAssetAllocationApiRequest
    >({
      query: ({ contextSessionKey, payload }) => {
        return {
          url: `assetallocapi/api/assetallocation`,
          headers: {
            contextjson: contextSessionKey
          },
          method: 'POST',
          data: payload
        }
      },
      keepUnusedDataFor: cacheTime,
      providesTags: providesTags('balancesDetail')
    }),
    getRealTimeBalance: builder.query<
      IBalanceDetailResponseValueItem,
      IRealTimeBalanceApiRequest
    >({
      query: ({ accountNumber }) =>
        `balance/odata/balancedetails?AccountList=${accountNumber}`,
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponse
    })
  })
})

export const {
  useGetBalancesDetailQuery,
  useGetBalancesAssetallocationQuery,
  useGetRealTimeBalanceQuery
} = balancesApi

export const useBalancesApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<BalancesApiTagType>[]) =>
      dispatch(balancesApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
