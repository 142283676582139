import type { IAnnuityPPLIContract } from 'api/datahub'
import type { CellValue, Style } from 'exceljs'
import type { IOdataListColumnDefinition } from 'features/OdataList/common/types'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { isNotNullOrUndefined } from 'shared/guards'
import { excelStyle } from '../export/exportFormat'
import type { AnnuityPPLIName } from './columnNameTypes'

export interface IAnnuityContractColumnDefinition
  extends IOdataListColumnDefinition {
  name: AnnuityPPLIName
  getExportValue?: (item: IAnnuityPPLIContract) => Partial<CellValue>
  exportFormat?: Partial<Style>
}

const commonCollumnProps: Partial<IAnnuityContractColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const annuityPPLIColumns: IAnnuityContractColumnDefinition[] = [
  {
    ...commonCollumnProps,
    name: 'Household Name',
    dataPath: 'householdName',
    type: 'string',
    width: 200,
    getExportValue: ({ householdName }) => householdName,
    searchFields: [
      'householdName',
      'accountNumber',
      'legalEntityName',
      'contractNumber',
      'cusipNumber',
      'securityDescription'
    ]
  },
  {
    ...commonCollumnProps,
    name: 'Account Number',
    dataPath: 'accountNumber',
    type: 'string',
    width: 100,
    getExportValue: ({ accountNumber }) => accountNumber,
    select: [
      'manuallyLinkedPartyId',
      'contractType',
      'contractNumber',
      'clientName',
      'insuredAnnuitants'
    ]
  },
  {
    ...commonCollumnProps,
    name: 'Legal Entity Name',
    dataPath: 'legalEntityName',
    type: 'string',
    width: 130,
    getExportValue: ({ legalEntityName }) => legalEntityName
  },
  {
    ...commonCollumnProps,
    name: 'Contract Type',
    dataPath: 'contractType',
    type: 'string',
    width: 125,
    getExportValue: ({ contractType }) => contractType
  },
  {
    ...commonCollumnProps,
    name: 'Contract Number',
    dataPath: 'contractNumber',
    type: 'string',
    width: 100,
    getExportValue: ({ contractNumber }) => contractNumber
  },
  {
    ...commonCollumnProps,
    name: 'CUSIP',
    dataPath: 'cusipNumber',
    type: 'string',
    width: 100,
    getExportValue: ({ cusipNumber }) => cusipNumber
  },
  {
    ...commonCollumnProps,
    name: 'Description',
    dataPath: 'securityDescription',
    type: 'string',
    width: 200,
    getExportValue: ({ securityDescription }) => securityDescription
  },
  {
    ...commonCollumnProps,
    name: 'State',
    dataPath: 'partyState',
    type: 'string',
    width: 75,
    getExportValue: ({ partyState }) => partyState,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Product Type',
    dataPath: 'productType',
    type: 'string',
    width: 200,
    getExportValue: ({ productType }) => productType,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Carrier',
    dataPath: 'carrierName',
    type: 'string',
    width: 200,
    getExportValue: ({ carrierName }) => carrierName
  },
  {
    ...commonCollumnProps,
    name: 'Clean Carrier Name',
    dataPath: 'cleanCarrierName',
    type: 'string',
    width: 200,
    getExportValue: ({ cleanCarrierName }) => cleanCarrierName
  },
  {
    ...commonCollumnProps,
    name: 'Issue Date',
    dataPath: 'issueDate',
    type: 'date-only',
    exportFormat: excelStyle.DATE,
    width: 100,
    getExportValue: ({ issueDate }) =>
      issueDate && parseDateISOStringInLocalTimezone(issueDate)
  },
  {
    ...commonCollumnProps,
    name: 'Policy Status',
    dataPath: 'contractStatusDesc',
    type: 'string',
    width: 80,
    getExportValue: ({ contractStatusDesc }) => contractStatusDesc,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Premium',
    dataPath: 'premiumPaidSinceInception',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ premiumPaidSinceInception }) => premiumPaidSinceInception
  },
  {
    ...commonCollumnProps,
    name: 'Last Updated',
    dataPath: 'valuationDate',
    type: 'date-only',
    exportFormat: excelStyle.DATE,
    width: 100,
    getExportValue: ({ valuationDate }) =>
      valuationDate && parseDateISOStringInLocalTimezone(valuationDate)
  },
  {
    ...commonCollumnProps,
    name: 'Cash Value',
    dataPath: 'cashValue',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ cashValue }) => cashValue
  },
  {
    ...commonCollumnProps,
    name: 'Death Benefit',
    dataPath: 'deathbenefitAmt',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ deathBenefitAmt }) => deathBenefitAmt
  },
  {
    ...commonCollumnProps,
    name: 'Surrender Value',
    dataPath: 'surrenderValue',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ surrenderValue }) => surrenderValue
  },
  {
    ...commonCollumnProps,
    name: 'Surrender Charge',
    dataPath: 'surrendercharge',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ surrenderCharge }) => surrenderCharge
  },
  {
    ...commonCollumnProps,
    name: 'Surrender Charge Expiration',
    dataPath: 'surrenderDate',
    type: 'date-only',
    exportFormat: excelStyle.DATE,
    width: 100,
    getExportValue: ({ surrenderDate }) =>
      surrenderDate && parseDateISOStringInLocalTimezone(surrenderDate)
  },
  {
    ...commonCollumnProps,
    name: 'RMDs',
    dataPath: 'rmdAmount',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ rmdAmount }) => rmdAmount
  },
  {
    ...commonCollumnProps,
    name: 'Maturity Date',
    dataPath: 'maturityDate',
    type: 'date-only',
    exportFormat: excelStyle.DATE,
    width: 100,
    getExportValue: ({ maturityDate }) =>
      maturityDate && parseDateISOStringInLocalTimezone(maturityDate)
  },
  {
    ...commonCollumnProps,
    name: 'Policy Loan amount',
    dataPath: 'loanamount',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ loanAmount }) => loanAmount,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Insured / Annuitants',
    dataPath: 'insuredAnnuitants',
    type: 'string',
    width: 100,
    getExportValue: ({ insuredAnnuitants }) => insuredAnnuitants,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Product Name',
    dataPath: 'productName',
    type: 'string',
    width: 100,
    getExportValue: ({ productName }) => productName,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Address',
    dataPath: 'partyAddressLine1',
    type: 'string',
    width: 250,
    getExportValue: ({ partyAddressLine1, partyAddressLine2 }) => {
      const address = [partyAddressLine1, partyAddressLine2]

      return address.filter(isNotNullOrUndefined).join(', ')
    },
    select: ['partyAddressLine1', 'partyAddressLine2']
  },
  {
    ...commonCollumnProps,
    name: 'High Benefit Name',
    dataPath: 'highBenefitName',
    type: 'string',
    width: 100,
    getExportValue: ({ highBenefitName }) => highBenefitName
  },
  {
    ...commonCollumnProps,
    name: 'Max Benefit',
    dataPath: 'maxBenefit',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ maxBenefit }) => maxBenefit
  },
  {
    ...commonCollumnProps,
    name: 'Income Benefit (GMIB)',
    dataPath: 'incomeBenefitAmt',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ incomeBenefitAmt }) => incomeBenefitAmt
  },
  {
    ...commonCollumnProps,
    name: 'Fee Type',
    dataPath: 'feeType',
    type: 'string',
    width: 100,
    getExportValue: ({ feeType }) => feeType,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'TBF Percentage',
    dataPath: 'tbfPercentAmt',
    type: 'number',
    exportFormat: excelStyle.PERCENT,
    width: 100,
    getExportValue: ({ tbfPercentAmt }) => tbfPercentAmt
  },
  {
    ...commonCollumnProps,
    name: 'Agents',
    dataPath: 'agents',
    type: 'string',
    width: 100,
    getExportValue: ({ agents }) => agents
  },
  {
    ...commonCollumnProps,
    name: 'Owner / Client',
    dataPath: 'clientName',
    type: 'string',
    width: 125,
    getExportValue: ({ clientName }) => clientName
  },
  {
    ...commonCollumnProps,
    name: 'Advisor Team',
    dataPath: 'teamName',
    type: 'string',
    width: 125,
    getExportValue: ({ teamName }) => teamName
  },
  {
    ...commonCollumnProps,
    name: 'Beneficiary Name',
    dataPath: 'beneficiaryName',
    type: 'string',
    width: 125,
    getExportValue: ({ beneficiaryName }) => beneficiaryName
  },
  {
    ...commonCollumnProps,
    name: 'Asset Class Level-1',
    dataPath: 'assetClassL1',
    type: 'string',
    width: 175,
    getExportValue: ({ assetClassL1 }) => assetClassL1,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Asset Class Level-2',
    dataPath: 'assetClassL2',
    type: 'string',
    width: 175,
    getExportValue: ({ assetClassL2 }) => assetClassL2,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Asset Class Level-3',
    dataPath: 'assetClassL3',
    type: 'string',
    width: 175,
    getExportValue: ({ assetClassL3 }) => assetClassL3,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Asset Class Level-4',
    dataPath: 'assetClassL4',
    type: 'string',
    width: 175,
    getExportValue: ({ assetClassL4 }) => assetClassL4,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Asset Type',
    dataPath: 'assetType',
    type: 'string',
    width: 100,
    getExportValue: ({ assetType }) => assetType,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Asset Sub Type',
    dataPath: 'assetSubType',
    type: 'string',
    width: 100,
    getExportValue: ({ assetSubType }) => assetSubType,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Advisor Id',
    dataPath: 'registeredRep',
    type: 'string',
    width: 70,
    getExportValue: ({ registeredRep }) => registeredRep
  },
  {
    ...commonCollumnProps,
    name: 'Advisor Name',
    dataPath: 'registeredRepName',
    type: 'string',
    width: 125,
    getExportValue: ({ registeredRepName }) => registeredRepName
  },
  {
    ...commonCollumnProps,
    name: 'Division Name',
    dataPath: 'regionName',
    type: 'string',
    width: 125,
    getExportValue: ({ regionName }) => regionName
  },
  {
    ...commonCollumnProps,
    name: 'COMDEX',
    dataPath: 'comdex',
    type: 'string',
    width: 100,
    getExportValue: ({ comdex }) => comdex,
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Annualized Premium',
    dataPath: 'annualizedPremium',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ annualizedPremium }) => annualizedPremium
  },
  {
    ...commonCollumnProps,
    name: 'Next Premium Amount',
    dataPath: 'premiumAmount',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ premiumAmount }) => premiumAmount
  },
  {
    ...commonCollumnProps,
    name: 'Next Premium Date',
    dataPath: 'premiumDate',
    type: 'date-only',
    exportFormat: excelStyle.DATE,
    width: 100,
    getExportValue: ({ premiumDate }) =>
      premiumDate && parseDateISOStringInLocalTimezone(premiumDate),
    facetable: true
  },
  {
    ...commonCollumnProps,
    name: 'Basis',
    dataPath: 'dollarAmountContributed',
    type: 'number',
    exportFormat: excelStyle.ACCOUNTING,
    width: 125,
    getExportValue: ({ dollarAmountContributed }) => dollarAmountContributed
  }
]
