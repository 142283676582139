import { FormattedDate, FormattedNumber } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'
import {
  useRdot360AccountContext,
  useRdot360HouseholdContext
} from '../../../../store/rdot360Context'
import { useActivityDetailUiState } from '../../activityDetailsUiState'
import { activityDropdownOptions, getSelectedAccounts } from '../../shared'

interface IHeaderClientProps {
  netAmount: number
  masked: boolean
}

const activityTypes = (activities: string[] | undefined) => {
  if (!activities) {
    return ''
  }
  const types: string[] = activities.map((key) => {
    const option = activityDropdownOptions.find((option) => key === option.key)
    return option?.text || ''
  })
  return types.join(', ')
}

export const PrintHeaderClient: React.FC<IHeaderClientProps> = ({
  netAmount,
  masked
}) => {
  const { selectedDateRange, searchText } = useActivityDetailUiState()
  const { startDate, endDate } = selectedDateRange || {
    startDate: undefined,
    endDate: undefined
  }
  const context = useRdot360HouseholdContext()
  const { selectedAccounts } = useRdot360AccountContext()
  const accounts = getSelectedAccounts(selectedAccounts, masked)
  const state = useActivityDetailUiState()
  const activityType =
    state?.categories?.length === activityDropdownOptions.length
      ? 'All'
      : activityTypes(state?.categories)
  return (
    <>
      <div className="header-column-half">
        <div>
          <strong>{context.household?.householdName}</strong>
        </div>
        <div>
          <label>Selected Account(s):</label> {accounts}
        </div>
      </div>
      <div className="header-column-half header-right">
        <div>
          <label>Activity Type:</label> {activityType}
          {activityType.length < 25 ? ' | ' : <br />}
          <label>Date Range:</label>{' '}
          <FormattedDate
            value={parseDateISOStringInLocalTimezone(startDate || '')}
            day="numeric"
            month="numeric"
            year="numeric"
          />{' '}
          <label>to</label>{' '}
          <FormattedDate
            value={parseDateISOStringInLocalTimezone(endDate || '')}
            day="numeric"
            month="numeric"
            year="numeric"
          />{' '}
          | <label>Report As Of:</label>{' '}
          <FormattedDate
            value={new Date()}
            day="numeric"
            month="numeric"
            year="numeric"
          />
        </div>
        <div>
          <label>Net Amount:</label>{' '}
          <FormattedNumber
            value={netAmount || 0}
            style="currency"
            currency="USD"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          />
        </div>
        {searchText && (
          <div>
            <label>Filtered by:</label> {searchText}
          </div>
        )}
      </div>
    </>
  )
}
