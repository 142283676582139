import { css, useTheme } from '@emotion/react'
import { IDropdownOption } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { fromPairs } from 'lodash'
import { Dropdown } from 'modules/Advisory/modules/Rdot360/components/shared'
import DetailsNavigator from 'modules/Advisory/modules/Rdot360/components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { PrintDropdownMenu } from 'modules/Advisory/modules/Rdot360/shared/PrintDropdownMenu'
import {
  AssetClassLevel,
  useHoldingsApiUtil
} from 'modules/Advisory/modules/Rdot360/store/holdingsApi'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPostitionResponse'
import {
  useGetPositionByCategoryQueryForSelectedAccounts,
  useRdot360AccountContext
} from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useTasApiUtil } from 'modules/Advisory/modules/Rdot360/store/tasApi'
import { useCallback, useMemo } from 'react'
import { useStore } from 'react-redux'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import { useInvestmentsTableStore } from '../InvestmentsTable'
import { getInvestmentsTableColumnDefs } from '../InvestmentsTable/InvestmentsTableColumns'
import { investmentsTableColumnNames } from '../InvestmentsTable/shared'
import { openInvestmentsPrintTab } from '../PrintView/PrintView'
import type { InvestmentsExportWorker } from './export'

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    columnGap: 20
  }),
  dropDownContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  dropDown: css({
    marginTop: '8px',
    '.ms-Dropdown-title': {
      width: 175,
      height: 48,
      borderRadius: 8,
      borderColor: '#AFB1B6',
      paddingTop: 8
    },
    '.ms-Dropdown-caretDownWrapper': {
      top: 8
    }
  }),
  iconStyle: css({
    width: 24,
    height: 24,
    marginBottom: 5
  }),
  searchBox: css({
    marginLeft: 'auto'
  }),
  printIcon: css({
    marginBottom: 6
  })
})
const empty: ICategoryPosition[] = []

export const InvestmentsMenu: React.FC = () => {
  const { searchText, setSearchText } = useInvestmentsDetailsUIState()
  const { selectedAccountIds } = useRdot360AccountContext()
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const {
    category,
    grouping,
    setInvestmentsViewByKey,
    viewByOptions,
    assetClassLevelOptions,
    assetClassLevelSelected,
    setAssetClassLevelSelected,
    setDefaultExpanded,
    setExpanded
  } = useInvestmentsTableStore()
  const { data, isFetching } =
    useGetPositionByCategoryQueryForSelectedAccounts(category)

  const isNonNFSAccounts = useMemo(() => {
    return selectedAccountIds.some((id) => {
      const account = accountLookupByAccountIdOrKey[id]
      return account?.CustodianCode !== 'NFS'
    })
  }, [accountLookupByAccountIdOrKey, selectedAccountIds])

  const columns = useMemo(() => getInvestmentsTableColumnDefs({}), [])

  const [firstGrouping] = grouping

  const exportToExcel = useCallback(async () => {
    const positions =
      data
        ?.flatMap((x) => x.invposacct)
        .flatMap((x) => x?.invposlist)
        .filter(isNotNullOrUndefined) || empty
    if (!positions?.length) {
      return
    }
    const columnDef = columns?.map((x) => ({
      id: x?.id || '',
      header: x?.id
    }))

    const mappedExportData = positions?.map((x, i) =>
      fromPairs<any>(
        columns?.map((column) => [column.id || '', column?.accessorFn(x, i)])
      )
    )

    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportInvestments } = wrap<InvestmentsExportWorker>(worker)
    const result = await exportInvestments(
      mappedExportData,
      firstGrouping,
      columnDef
    )
    const filename = `Investment ${format(new Date(), 'MM-dd-yyyy')}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [columns, data, firstGrouping])
  const onAssetClassLevelChange = useCallback(
    (e?: unknown, option?: IDropdownOption<any>) => {
      if (!option?.key) {
        return
      }
      setAssetClassLevelSelected(option.key as AssetClassLevel)
      setDefaultExpanded({})
      setExpanded({})
    },
    [setAssetClassLevelSelected, setDefaultExpanded, setExpanded]
  )
  const onGroupingChange = useCallback(
    (e?: unknown, option?: IDropdownOption<any>) => {
      if (!option?.key) {
        return
      }
      setInvestmentsViewByKey(option.key as string)
    },
    [setInvestmentsViewByKey]
  )
  const { invalidateTags: holdingsInvalidateTags } = useHoldingsApiUtil()
  const { invalidateTags: tasLotsInvalidateTags } = useTasApiUtil()
  const clearCache = useCallback(() => {
    holdingsInvalidateTags(['positionByCategory'])
    tasLotsInvalidateTags(['openTaxLotsByAccountAndSecurity'])
  }, [holdingsInvalidateTags, tasLotsInvalidateTags])

  const classes = useMemo(() => getClasses(), [])

  const theme = useTheme()
  const store = useStore()

  const { expanded } = useInvestmentsTableStore()

  const calcMaxDepth = useCallback(() => {
    const expandState = Object.entries(expanded || {})
    const allPaths = expandState.map((path) => path[0])
    const tablePaths = allPaths.filter((value) =>
      value.startsWith(firstGrouping)
    )
    let maxDepth = 0
    tablePaths.forEach((path) => {
      let currentDepth = (path.match(/:/g) || []).length
      if (currentDepth === 2) {
        // check if parent is expanded
        const index = path.indexOf('>')
        if (index !== -1) {
          const parentPath = path.substring(0, index)
          const found = tablePaths.find((value) => value === parentPath)
          // parent is closed, ignore this path
          if (!found) {
            currentDepth = 0
          }
        }
      }
      maxDepth = Math.max(maxDepth, currentDepth)
    })
    return maxDepth
  }, [expanded, firstGrouping])

  const print = useCallback(
    (masked = false) => {
      const maxDepth = calcMaxDepth()
      openInvestmentsPrintTab(masked, maxDepth, store)
    },
    [calcMaxDepth, store]
  )

  const isData = data && data?.length > 0
  const isDisabled = isFetching || !isData

  return (
    <div css={classes.container}>
      <DetailsNavigator />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          flexGrow: 1
        }}
      >
        {isNonNFSAccounts && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: 10
            }}
          >
            Non-NFS share quantities are as of previous day and values are
            adjusted for current market price.
          </div>
        )}
        <div css={[classes.container, { flexGrow: 1 }]}>
          <div css={classes.dropDownContainer}>
            <div css={{ fontSize: '14px', fontWeight: 500 }}>View By</div>
            <div css={classes.dropDown}>
              <Dropdown
                options={viewByOptions}
                selectedKey={firstGrouping}
                onChange={onGroupingChange}
              />
            </div>
          </div>
          {firstGrouping === investmentsTableColumnNames.assetClass && (
            <div css={classes.dropDownContainer}>
              <div css={{ fontSize: '14px', fontWeight: 500 }}>
                Asset Class Level
              </div>
              <div css={classes.dropDown}>
                <Dropdown
                  options={assetClassLevelOptions}
                  selectedKey={assetClassLevelSelected}
                  onChange={onAssetClassLevelChange}
                />
              </div>
            </div>
          )}
          <div css={classes.searchBox}>
            <Searchbox searchText={searchText} onChange={setSearchText} />
          </div>
          <div css={classes.iconStyle}>
            <Icon
              type="Refresh"
              width={24}
              height={24}
              onClick={clearCache}
              color={theme.colors.extraBlue2}
            />
          </div>
          <div css={classes.printIcon}>
            <PrintDropdownMenu print={print} isDisabled={isDisabled} />
          </div>
          <div css={classes.iconStyle}>
            <Icon
              type="Download"
              width={24}
              height={24}
              onClick={isDisabled ? undefined : exportToExcel}
              color={theme.colors.extraBlue2}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
