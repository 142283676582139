import { IPosition } from 'api/position.types'
import { useCallback } from 'react'
import {
  IAiDashboardFilterDefinition,
  IAiDashboardFilterState
} from '../../store/aiDashboard'
import { useAiPostions } from '../../store/useAiPositions'
import { AiDashboardFilter } from './AiDashboardFilter'

const filterDefinition: IAiDashboardFilterDefinition = {
  key: 'households',
  name: 'Household',
  getValue: ({ householdId }: IPosition) => householdId,
  getDisplayValue: ({ householdName }: IPosition) => householdName
}

export const AiDashboardHouseholdFilter: React.FC = () => {
  const { filters, filterFacets, filterDisplayValues, updateFilters } =
    useAiPostions()

  const onUpdate = useCallback(
    (key: keyof IAiDashboardFilterState, values: string[]) =>
      updateFilters({ [key]: values }),
    [updateFilters]
  )

  return (
    <AiDashboardFilter
      filterDefinition={filterDefinition}
      facets={filterFacets[filterDefinition.key]}
      selectedValues={filters[filterDefinition.key]}
      displayValue={filterDisplayValues[filterDefinition.key]}
      onUpdate={(values) => onUpdate(filterDefinition.key, values)}
    />
  )
}
