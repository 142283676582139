import { combineReducers } from 'redux'
import { IAccountsModuleDefintion } from '../shared/IAccountsModuleDefinition'
import ManagedAccountFeeHistory, {
  managedAccountFeeHistoryModuleReducer,
  managedAccountFeeHistoryModuleSagas
} from './ManagedAccountFeeHistory'
import MarginRateRequests, {
  marginRateRequestsModuleReducer,
  marginRateRequestsModuleSagas
} from './MarginRateRequests'

export default [
  ManagedAccountFeeHistory,
  MarginRateRequests
] as IAccountsModuleDefintion[]

export const accountsModulesReducer = combineReducers({
  managedAccountFeeHistory: managedAccountFeeHistoryModuleReducer,
  marginRateRequests: marginRateRequestsModuleReducer
})

export const accountsModulesSagas = [
  ...managedAccountFeeHistoryModuleSagas,
  ...marginRateRequestsModuleSagas
]
