import { ActionType, createAction } from 'typesafe-actions'
import { IDashboardRouteState } from '../shared/IDashboardRouteState'
import { AllocationType } from './types'

const SET_SELECTED_PRODUCT_ALLOCATIONS =
  '@modules/@dashboard/ADD_SELECTED_PRODUCT_ALLOCATION'
const SET_SELECTED_ASSET_ALLOCATIONS =
  '@modules/@dashboard/SET_SELECTED_ASSET_ALLOCATION'

const SET_ALLOCATION_TYPE = '@modules/@dashboard/SET_ALLOCATION_TYPE'

export const dashboardActions = {
  setSelectedProductAllocations: createAction(SET_SELECTED_PRODUCT_ALLOCATIONS)<
    string[] | undefined
  >(),
  setSelectedAssetAllocations: createAction(SET_SELECTED_ASSET_ALLOCATIONS)<
    string[] | undefined
  >(),
  setAllocationType: createAction(SET_ALLOCATION_TYPE)<AllocationType>()
}

const NAVIGATE_TO_DASHBOARD = '@modules/@dashboard/NAVIGATE_TO_DASHBOARD'

export const dashboardNavigationActions = {
  navigateToDashboard: createAction(NAVIGATE_TO_DASHBOARD)<
    IDashboardRouteState | undefined
  >()
}

export type DashboardActionTypes = ActionType<typeof dashboardActions>
