import { css } from '@emotion/react'
import { groupBy, orderBy, sumBy } from 'lodash'
import { PrintHeaderGlobal } from 'modules/Advisory/modules/Rdot360/shared/PrintHeaderGlobal'
import { useMemo, useState } from 'react'
import { maskAccountNumber } from '../../../../shared/utilities'
import { IActivity } from '../../../../store/types'
import { PrintDisclaimer } from './PrintDisclaimer'
import { PrintHeaderClient } from './PrintHeaderClient'
import { PrintHeaderSmall } from './PrintHeaderSmall'
import { PrintNoActivity } from './PrintNoActivity'
import { PrintTable } from './PrintTable'

const getClasses = () => ({
  body: css({
    fontFamily: 'sans-serif',
    '@media screen': {
      maxWidth: '1600px',
      margin: '2rem auto',
      '& .header-global': {
        maxWidth: '1600px'
      },
      '& .small-logo-header': {
        display: 'none'
      }
    },
    '@media print': {
      margin: 0,
      zoom: '60%',
      '@page': {
        size: 'letter landscape'
      },
      '& .print-table': {
        tableLayout: 'fixed',
        breakAfter: 'page',
        width: '100%'
      }
    },
    '& h1': {
      fontSize: '26px',
      margin: 0
    },
    '& .next-page': {
      breakAfter: 'page'
    },
    '& .header-global': {
      borderBottom: '2px solid #676767',
      paddingBottom: '20px',
      width: '100%',
      backgroundColor: '#FFF'
    },
    '& footer-table': {
      marginTop: '-1px',
      borderTop: '2px solid #676767',
      borderBottom: 0
    },
    '& footer': {
      paddingTop: '5px',
      fontSize: '9pt'
    },
    '& .header-client': {
      borderBottom: '1px solid #CDCDCD',
      marginBottom: '-50px'
    },
    '& .header-small-logo': {
      height: 0,
      padding: 0,
      margin: 0
    },
    '& .top-margin': {
      marginTop: '45px'
    },
    '& .header-columns': { display: 'flex', justifyContent: 'space-between' },
    '& .header-column-half': { width: '50%' },
    '& .header-column-half > div': { padding: '5px 0' },
    '& .header-right': { textAlign: 'right' },
    '& .header-table': { backgroundColor: '#DFE8EE' },
    '& .print-table': { borderCollapse: 'collapse' },
    '& .disclaimer': { paddingTop: '20px', fontSize: '9pt' },
    '& tr': { borderBottom: '1px solid #F0F0F0' },
    '& td': { padding: '5px 15px 5px 15px', overflowWrap: 'break-word' },
    '& th': { textAlign: 'left', padding: '15px', whiteSpace: 'nowrap' },
    '& col:nth-child(1), & th:nth-child(1), & td:nth-child(1)': {
      width: '10%'
    },
    '& col:nth-child(2), & th:nth-child(2), & td:nth-child(2)': {
      width: '50%'
    },
    '& col:nth-child(3), & th:nth-child(3), & td:nth-child(3)': {
      width: '20%'
    },
    '& col:nth-child(4), & th:nth-child(4), & td:nth-child(4)': {
      width: '10%',
      textAlign: 'right'
    },
    '& col:nth-child(5), & th:nth-child(5), & td:nth-child(5)': {
      width: '10%',
      textAlign: 'right'
    },
    '& col:nth-child(6), & th:nth-child(6), & td:nth-child(6)': {
      width: '10%',
      textAlign: 'right'
    },
    '& col:nth-child(7), & th:nth-child(7), & td:nth-child(7)': {
      width: '10%',
      textAlign: 'right'
    },
    '& .logo-col': {
      padding: '0 !important'
    },
    label: { color: '#676767' },
    '& .top-margin-table': {
      marginTop: '47px'
    },
    '& .logo-row': {
      padding: '0 0 10px 0 !important',
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: -1,
      borderBottom: '1px solid #CDCDCD'
    },
    '& .print-no-activity': {
      paddingTop: '70px',
      fontSize: '24px',
      fontWeight: 400,
      width: '100%'
    },
    '& .print-no-activity > div': {
      padding: '10px 0',
      textAlign: 'center'
    },
    '& .print-no-activity svg': {
      margin: '0 auto'
    },
    '& .left-align': {
      textAlign: 'left'
    }
  })
})

interface IPrintHTMLProps {
  data: IActivity[]
  masked: boolean
}

export const PrintHTML: React.FC<IPrintHTMLProps> = ({ data, masked }) => {
  const [dataFound, setDataFound] = useState(false)
  const classes = useMemo(() => getClasses(), [])
  const netAmount = sumBy(data, (activity) => activity.total || 0)
  const activitySorted = orderBy(data, (activity) => activity.dtentry, 'asc')
  const activityGrouped = groupBy(activitySorted, (activity) => activity.acct)
  const activityData = Object.entries(activityGrouped).map(([key, value]) => ({
    account: key,
    data: value
  }))

  return (
    <html>
      <head>
        <meta charSet="UTF-8" />
        <title>&nbsp;</title>
        <script>
          {`
          window.onload = function() {
            setTimeout(() => {
              window.print();
            }, 100)
          }
        `}
        </script>
      </head>
      <body css={classes.body}>
        <header className="header-global">
          <PrintHeaderGlobal name="Activity" />
        </header>
        <header className="header-small-logo">
          <PrintHeaderSmall />
        </header>
        <header className="header-client header-columns">
          <PrintHeaderClient netAmount={netAmount} masked={masked} />
        </header>
        {!!data.length &&
          activityData.map((activity) => (
            <PrintTable
              data={activity.data}
              account={activity.account}
              maskedAccount={masked ? maskAccountNumber(activity.account) : ''}
              key={activity.account}
              dataFound={dataFound}
              setDataFound={setDataFound}
            />
          ))}
        {!dataFound && (
          <div className="top-margin next-page">
            <PrintNoActivity />
          </div>
        )}
        <PrintDisclaimer />
      </body>
    </html>
  )
}
