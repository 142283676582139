import { css } from '@emotion/react'
import {
  AccessorFnColumnDef,
  CellContext,
  HeaderContext,
  Row,
  createColumnHelper
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { USDCellWithColor } from 'modules/Advisory/modules/Rdot360/components/USDCellWithColor'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { USD } from 'shared/components/Formatting'
import { IArrayList } from '../../GainLossesIncomeDetailView'
import { GainLossDetailColumnNames } from '../../shared'
import { AccountNumberCell } from './../../../../../Rdot360/components/shared/DetailTables/AccountNumberCell'

const columnHelper = createColumnHelper<IArrayList>()

const numberSorting = (a: any, b: any, columnId: string) => {
  const sortValue = a.getValue(columnId) > b.getValue(columnId) ? 1 : -1

  return sortValue
}

const dateSorting = (a: any, b: any, columnId: string) => {
  const sortValue =
    new Date(a.getValue(columnId)) > new Date(b.getValue(columnId)) ? 1 : -1

  return sortValue
}

const getDefaultValue = (value?: string | number | Date) =>
  value != null ? '--' : ''

const renderNumberField = (props: CellContext<IArrayList, number>) => {
  return (
    <div css={{ textAlign: 'right' }}>
      {props.getValue() ? (
        <FormattedNumber
          value={props.getValue()}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
          currencySign="accounting"
        />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </div>
  )
}

const renderAmountField = (props: CellContext<IArrayList, number>) => {
  return (
    <div>
      {props.getValue() ? (
        <USD
          value={props.getValue()}
          fractionDigits={2}
          currencySign="standard"
        />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </div>
  )
}

const renderAmountFieldColor = (props: CellContext<IArrayList, number>) => {
  return (
    <>
      {props.getValue() ? (
        <USDCellWithColor value={props.getValue()} fractionDigit={2} />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </>
  )
}

const renderDateField = (props: CellContext<IArrayList, Date>) => {
  return (
    <div>
      {props.getValue() ? (
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={props.getValue()}
        />
      ) : (
        getDefaultValue(props.getValue())
      )}
    </div>
  )
}

const SumFooter: React.FC<HeaderContext<IArrayList, number>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return (
    <div css={{ textAlign: 'right', fontWeight: 'bold' }}>
      {column.id === 'quantity' ? (
        <FormattedNumber
          value={total}
          currencySign="accounting"
          maximumFractionDigits={2}
          minimumFractionDigits={2}
        />
      ) : (
        <USD value={total} fractionDigits={2} currencySign={'standard'} />
      )}
    </div>
  )
}

const helperClasses = {
  rightEllipsis: css({
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })
}

const SumFooterColor: React.FC<HeaderContext<IArrayList, number>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return (
    <div css={helperClasses.rightEllipsis}>
      <USDCellWithColor value={total} fractionDigit={2} />
    </div>
  )
}

const renderExpnadCollapseIcon = (row: Row<any>) => {
  const icon = row.subRows.length ? (
    <Icon type="Add" width={14} height={14} color="black" />
  ) : (
    ''
  )

  return (
    <div css={{ width: 14, height: 14, margin: '0 12px' }}>
      {row.getIsExpanded() ? (
        <Icon type="Subtract" width={14} height={14} color="black" />
      ) : (
        icon
      )}
    </div>
  )
}

const getColumns = (searchText: string) =>
  [
    columnHelper.accessor((row) => row.secName, {
      id: GainLossDetailColumnNames.symbol,
      header: GainLossDetailColumnNames.symbol,
      enableHiding: true
    }),
    columnHelper.accessor((row) => row.description, {
      id: GainLossDetailColumnNames.description,
      header: GainLossDetailColumnNames.description,
      enableHiding: true
    }),
    columnHelper.accessor((row) => row.description, {
      id: GainLossDetailColumnNames.symbolDescription,
      header: GainLossDetailColumnNames.symbolDescription,
      enableGlobalFilter: true,
      cell: (props: any) => {
        const { row } = props
        return props.getValue() ? (
          <div
            {...{
              onClick: row.getToggleExpandedHandler()
            }}
            css={{
              cursor: row.subRows.length ? 'pointer' : '',
              display: 'flex'
            }}
          >
            {renderExpnadCollapseIcon(row)}
            <div
              css={{
                marginLeft: 8,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0
              }}
            >
              <div>
                <HighlightSearchText
                  text={row.original.secName}
                  search={searchText}
                />
              </div>

              <div
                css={{
                  color: '#676767',
                  fontWeight: 400,
                  lineHeight: '1.5',
                  fontSize: 12,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                <HighlightSearchText
                  text={props.getValue()}
                  search={searchText}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      },
      footer: 'Total',
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor((row) => row.account, {
      id: GainLossDetailColumnNames.account,
      header: GainLossDetailColumnNames.account,
      enableGlobalFilter: true,
      cell: (props) => {
        return (
          <div>
            {props.getValue() ? (
              <AccountNumberCell
                accountIdOrKey={props.getValue() as string}
                search={searchText}
              />
            ) : (
              getDefaultValue(props.getValue())
            )}
          </div>
        )
      },
      minSize: 50,
      size: 250
    }),
    columnHelper.accessor((row) => row.quantity, {
      id: GainLossDetailColumnNames.quantity,
      header: GainLossDetailColumnNames.quantity,
      sortingFn: numberSorting,
      cell: renderNumberField,
      enableGlobalFilter: false,
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor(
      (row) =>
        row.acquisitionDate &&
        parseDateISOStringInLocalTimezone(row.acquisitionDate),
      {
        id: GainLossDetailColumnNames.acquisitionDate,
        header: GainLossDetailColumnNames.acquisitionDate,
        sortingFn: dateSorting,
        cell: renderDateField,
        enableGlobalFilter: false,
        minSize: 50,
        size: 130
      }
    ),
    columnHelper.accessor((row) => row.acquisitionPrice, {
      id: GainLossDetailColumnNames.acquisitionPrice,
      header: GainLossDetailColumnNames.acquisitionPrice,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor((row) => row.acquisitionCost, {
      id: GainLossDetailColumnNames.acquisitionCost,
      header: GainLossDetailColumnNames.acquisitionCost,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor(
      (row) =>
        row.liquidationDate &&
        parseDateISOStringInLocalTimezone(row.liquidationDate),
      {
        id: GainLossDetailColumnNames.liquidationDate,
        header: GainLossDetailColumnNames.liquidationDate,
        sortingFn: dateSorting,
        cell: renderDateField,
        enableGlobalFilter: false,
        minSize: 50,
        size: 130
      }
    ),
    columnHelper.accessor((row) => row.liquidationAmount, {
      id: GainLossDetailColumnNames.liquidationAmount,
      header: GainLossDetailColumnNames.liquidationAmount,
      sortingFn: numberSorting,
      cell: renderAmountField,
      enableGlobalFilter: false,
      footer: SumFooter,
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor((row) => row.shortTermGainLoss, {
      id: GainLossDetailColumnNames.shortTermGainLoss,
      header: GainLossDetailColumnNames.shortTermGainLoss,
      sortingFn: numberSorting,
      cell: renderAmountFieldColor,
      enableGlobalFilter: false,
      footer: SumFooterColor,
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor((row) => row.longTermGainLoss, {
      id: GainLossDetailColumnNames.longTermGainLoss,
      header: GainLossDetailColumnNames.longTermGainLoss,
      sortingFn: numberSorting,
      cell: renderAmountFieldColor,
      enableGlobalFilter: false,
      footer: SumFooterColor,
      minSize: 50,
      size: 150
    }),
    columnHelper.accessor((row) => row.totalGainLoss, {
      id: GainLossDetailColumnNames.totalGainLoss,
      header: GainLossDetailColumnNames.totalGainLoss,
      sortingFn: numberSorting,
      cell: renderAmountFieldColor,
      enableGlobalFilter: false,
      footer: SumFooterColor,
      minSize: 50,
      size: 150
    })
  ] as AccessorFnColumnDef<IArrayList>[]

export default getColumns
