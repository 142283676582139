import {
  flexRender,
  Row,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  ExpandedState
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { SortIndicator } from 'modules/Advisory/modules/Rdot360/shared/SortIndicator'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDebounce } from 'shared/hooks/useDebounce'
import { useWidthObserver } from 'shared/hooks/useResizeObserver'
import { ITableHeaderColumnSize } from '../../../../shared/ITableHeaderColumnSize'
import { IArrayList } from '../../GainLossesIncomeDetailView'
import { GainLossDetailColumnNames } from '../../shared'
import { IndeterminateProgressIndicator } from './../../../../../Rdot360/components/shared/ProgressIndicator/IndeterminateProgressIndicator'
import DetailsRow from './DetailsRow'
import getColumns from './GainLossesDetailViewTableColumns'

const columnVisibility: VisibilityState = {
  [GainLossDetailColumnNames.symbol]: false,
  [GainLossDetailColumnNames.description]: false
}

const getAdjustedHeaderSizes = (
  sizes: ITableHeaderColumnSize[],
  tableWidth: number,
  depth = 0,
  firstColumnOffset = 5,
  lastColumnOffset = 5
) => {
  const totalSize = sum(sizes.map(({ width }) => width))
  const ratio = tableWidth ? tableWidth / totalSize : 1

  return sizes.map(({ width, id }, i) => {
    const isFirst = i === 0
    const isLast = i === sizes.length - 1
    const depthAdjustment =
      isFirst || isLast
        ? depth * (isFirst ? firstColumnOffset : lastColumnOffset)
        : 0

    return { id, width: width * ratio - depthAdjustment }
  })
}

const TableHeaderSizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            css={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}

const TableBody: React.FC<{
  rows: Row<any>[]
  getHeaderSizes: (depth?: number) => ITableHeaderColumnSize[]
  depth?: number
}> = ({ rows, getHeaderSizes, depth = 0 }) => {
  const sizes = getHeaderSizes(depth)

  return (
    <table css={[rdot360TableStyles.table]}>
      <thead>
        <TableHeaderSizesRow sizes={sizes} />
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={index}
            css={{
              paddingRight: '4px'
            }}
          >
            <td
              colSpan={row.getVisibleCells().length + 1}
              style={{
                padding: 0,
                paddingBottom: 6
              }}
            >
              <DetailsRow row={row} rowIndex={index} sizes={sizes} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const TableWrapper = (props: PropsWithChildren) => (
  <div css={{ marginTop: 5 }}>{props.children}</div>
)
const GainLossesDetailedViewTable: FC<{
  data?: IArrayList[]
  isLoading: boolean
  error: Error | unknown
  searchText: string
  setSearchText: (value: string) => void
  isUninitialized: boolean
}> = ({
  data = [],
  isLoading = false,
  error,
  searchText,
  setSearchText,
  isUninitialized
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: GainLossDetailColumnNames.totalGainLoss, desc: true }
  ])
  const debouncedSearchText = useDebounce(searchText, 100)
  const columns = useMemo(
    () => getColumns(debouncedSearchText),
    [debouncedSearchText]
  )
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const table = useReactTable<IArrayList>({
    data,
    columns,
    state: {
      sorting,
      expanded,
      globalFilter: debouncedSearchText,
      columnVisibility
    },
    enableColumnResizing: true,
    globalFilterFn: (
      row: Row<any>,
      id: string,
      filterValue: string
    ): boolean => {
      const serchString = (filterValue || '').toLowerCase()

      return (
        row.original.description
          ?.toString()
          .toLowerCase()
          .startsWith(serchString) ||
        row.original.secName
          ?.toString()
          .toLowerCase()
          .startsWith(serchString) ||
        row.original.account
          ?.toString()
          .toLowerCase()
          .startsWith(serchString) ||
        (!!row.original.accounts &&
          !!row.original.accounts.find((item: any) =>
            item.account?.toLowerCase().startsWith(serchString)
          ))
      )
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetExpanded: false,
    onGlobalFilterChange: setSearchText,
    getSubRows: (row: any): any => {
      const subRows = row.accounts
      return subRows
    }
  })

  const rows = table.getPreExpandedRowModel().rows
  const headers = table.getFlatHeaders()

  const tableRefContainer = useRef<HTMLDivElement>(null)
  const containerWidth = useWidthObserver(tableRefContainer)
  const tableWidth = Math.max(1450, containerWidth || 0)

  const getHeaderSizes = useCallback(
    (depth?: number) => {
      const sizes = getAdjustedHeaderSizes(
        headers.map((x) => ({ id: x.id || '', width: x.getSize() || 0 })),
        // -2 for the border around the table
        tableWidth - 2,
        depth
      )
      return sizes
    },
    [headers, tableWidth]
  )

  const themedStyles = useRdot360ThemedTableStyles()

  const renderTableHeaderTow = () => {
    return table.getHeaderGroups().map((headerGroup) => (
      <tr
        key={headerGroup.id}
        css={{ position: 'sticky', top: constants.headerOffsetPx }}
      >
        {headerGroup.headers.map((header, index) => {
          return (
            <th
              key={header.id}
              colSpan={header.colSpan}
              css={{
                cursor: header.column.getCanSort() ? 'pointer' : 'default',
                backgroundColor: '#DFE8EE',
                padding: '32px 8px',
                position: 'sticky',
                top: constants.headerOffsetPx,
                textAlign: index > 1 ? 'right' : 'left',
                verticalAlign: 'top',
                '&:first-of-type': {
                  paddingLeft: '20px'
                },
                '&:last-of-type': {
                  paddingRight: '20px'
                }
              }}
            >
              {header.isPlaceholder ? null : (
                <div
                  {...{
                    className: header.column.getCanSort()
                      ? 'cursor-pointer select-none'
                      : '',
                    onClick: header.column.getToggleSortingHandler()
                  }}
                  css={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: index > 1 ? 'right' : 'left'
                  }}
                >
                  <div style={{ display: 'inline-flex' }}>
                    <span>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </span>
                    <SortIndicator direction={header.column.getIsSorted()} />
                  </div>
                </div>
              )}
            </th>
          )
        })}
      </tr>
    ))
  }

  return (
    <div>
      <a
        onClick={(e) => {
          table.toggleAllRowsExpanded()
          e.preventDefault()
        }}
        href="#"
        css={{
          color: '#4C9DA8',
          cursor: 'pointer'
        }}
      >
        {table.getIsAllRowsExpanded() ? 'Collapse All' : 'Expand All'}
      </a>
      <TableWrapper>
        {isLoading && <IndeterminateProgressIndicator />}
        <div
          css={[
            rdot360TableStyles.headerContainer,
            themedStyles.headerContainer
          ]}
        >
          <table css={rdot360TableStyles.table}>
            <thead>
              <TableHeaderSizesRow sizes={getHeaderSizes()} />
              {renderTableHeaderTow()}
            </thead>
          </table>
        </div>
        <div css={[themedStyles.bodyContainer]}>
          <TableBody rows={rows} getHeaderSizes={getHeaderSizes} />
          <div
            css={[
              rdot360TableStyles.headerContainer,
              themedStyles.headerContainer,
              { bottom: 0 }
            ]}
          >
            <table css={[rdot360TableStyles.table]}>
              <thead>
                <TableHeaderSizesRow sizes={getHeaderSizes()} />
              </thead>
              {!(isUninitialized || isLoading) &&
              (data.length === 0 || error) ? (
                <td colSpan={columns.length}>
                  <SnackBar
                    type={error ? 'Failure' : 'Info'}
                    message={
                      error
                        ? (error as Error)?.message ||
                          'An unknown error occurred'
                        : 'No data available'
                    }
                  />
                </td>
              ) : (
                <tfoot>
                  <tr
                    css={[
                      rdot360TableStyles.bodyRow,
                      rdot360TableStyles.l2GroupRow,
                      themedStyles.totalRow,
                      {
                        'td:last-of-type': {
                          paddingRight: 20
                        }
                      }
                    ]}
                  >
                    {headers.map((header) => (
                      <td key={header.id}>
                        {header.column.columnDef.footer
                          ? flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )
                          : null}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </TableWrapper>
    </div>
  )
}
export default GainLossesDetailedViewTable
