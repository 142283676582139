import { PositionColumnIds } from 'features/Lists/positions/ColumnDefinitions'
import { keyBy } from 'lodash'
import { isNotNullOrUndefined } from 'shared/guards'
import { createAiPositionListFilter } from '../store'

export const getAiDashboardDefaulViewtFilters = () => {
  const onOffPlatformFilter = createAiPositionListFilter(
    PositionColumnIds.OnPlatform_OffPlatform,
    {
      type: 'facet',
      values: ['on-platform', 'off-platform', ''],
      hasValue: true
    }
  )

  const filters = keyBy(
    [onOffPlatformFilter].filter(isNotNullOrUndefined),
    ({ id }) => id
  )

  return filters
}

export const getAiDashboardDefaulViewHiddenFilters = (props?: {
  drawdownNonDrawdownValues?: string[]
}) => {
  const { drawdownNonDrawdownValues } = props || {}

  const aiFilter = createAiPositionListFilter(PositionColumnIds.securitytype, {
    type: 'facet',
    values: ['AI'],
    hasValue: true,
    hidden: true,
    disableModifications: true
  })

  const assetTypeFilter = createAiPositionListFilter(
    PositionColumnIds.assetType,
    {
      type: 'facet',
      values: ['Private Investments', 'Hedge Funds'],
      hasValue: true,
      hidden: true,
      disableModifications: true
    }
  )

  const drawdownFilter = drawdownNonDrawdownValues?.length
    ? createAiPositionListFilter(PositionColumnIds.DrawdownNonDrawdown, {
        type: 'facet',
        values: drawdownNonDrawdownValues,
        hasValue: true,
        hidden: true,
        disableModifications: true
      })
    : undefined

  const filters = keyBy(
    [aiFilter, assetTypeFilter, drawdownFilter].filter(isNotNullOrUndefined),
    ({ id }) => id
  )

  return filters
}
