import { convertColumnTypeToFilterType } from 'features/DataList/service'
import { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { AppState } from 'store'
import { fetchRetirementAssets } from '../../shared/retirementService'
import { retirementHoldingsColumns } from './retirementHoldingColumns'

export const defaultColumnsIds = retirementHoldingsColumns.map((x) => x.name)

const uiFilters = keyBy(
  retirementHoldingsColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const store = createOdataListWithFacetsStore({
  prefix: '@modules/@advisory/@modules/@retirement/@holdings',
  getOdataResults: fetchRetirementAssets,
  initialState: {
    data: {},
    ui: {
      columns: retirementHoldingsColumns,
      selectedColumns: [
        'Contract Number',
        'Company Name',
        'Plan Name',
        'Plan Provider',
        'Underlying CUSIP',
        'Underlying Holdings Name',
        'Ticker',
        'Asset Class Level 1',
        'Asset Class Level 3',
        'Market Value',
        'Valuation Date'
      ],
      filters: uiFilters,
      sortBy: {
        direction: 'asc',
        name: 'Contract Number'
      }
    },
    facets: {}
  },
  rootSelector: (state: AppState) =>
    state.modules.advisory.modules.retirement.holdings
})

export const { actions, selectors, reducer, sagas } = store
