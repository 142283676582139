import { Theme, css, useTheme } from '@emotion/react'
import { CDMv2Account } from 'api/nickname.types'
import { format } from 'date-fns'
import { useRelatedPartyPanel } from 'features/RelatedPartyPanel/store'
import { orderBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { Icon } from '../../../features/Icons/Icon'
import { IRdot360Account, isMultiMargin } from '../../../store/rdot360Context'
import { AccountStencil } from './AccountStencil'

const getClasses = (theme: Theme) => ({
  container: css({
    padding: 10,
    fontSize: '12px',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px'
  }),
  label: css({
    fontWeight: 'bold'
  }),
  note: css({
    fontWeight: '400',
    fontSize: '10px'
  }),
  partitionLine: css({
    border: 'none',
    borderTop: `1px solid ${theme.colors.extraGray1}`,
    height: '1px',
    width: '100%'
  }),
  linkText: (theme: Theme) =>
    css({
      color: theme.colors.primaryDarkBlue,
      fontWeight: theme.fontWeights.medium,
      fontSize: theme.size.sm
    })
})

export const AccountTooltip: React.FC<{
  nicknameAccount?: CDMv2Account
  searchAccount?: IRdot360Account
}> = ({ nicknameAccount, searchAccount }) => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const { addressMappings, establishDate } = nicknameAccount || {}
  const { marginAgreement, multipleMargin, CustodianCode } = searchAccount || {}

  const { mask } = useMasking()
  const nicknameWithMask = mask(searchAccount?.preferredNickname, 'nickname')

  const { open: openRelatedParties } = useRelatedPartyPanel()
  const onRelatedPartiesClicked = useCallback(() => {
    if (!nicknameAccount?.accountID || !nicknameAccount?.accountNumber) {
      return
    }
    openRelatedParties({
      idType: 'accountId',
      id: nicknameAccount?.accountID,
      displayName: nicknameAccount?.accountNumber
    })
  }, [
    nicknameAccount?.accountID,
    nicknameAccount?.accountNumber,
    openRelatedParties
  ])

  const stakeholders = useMemo(
    () =>
      orderBy(nicknameAccount?.stakeholders, (x) =>
        [
          x.accountRelationship,
          x.stakeholderType,
          x.stakeholderRelationship
        ].findIndex((x) => x)
      ),
    [nicknameAccount?.stakeholders]
  )

  return (
    <div css={classes.container}>
      <div>
        <div>{nicknameWithMask}</div>
        <div>
          {searchAccount?.CustodyAccount || nicknameAccount?.accountNumber}
        </div>
        <div
          css={[
            searchAccount?.CustodianName === 'nfs' && {
              textTransform: 'uppercase'
            }
          ]}
        >
          {searchAccount?.CustodianName}
        </div>
      </div>
      <hr css={classes.partitionLine} />

      {establishDate && (
        <div>
          <div css={classes.label}>Establishment Date</div>
          <div>
            {format(
              parseDateISOStringInLocalTimezone(establishDate),
              'yyyy-MM-dd'
            )}
          </div>
        </div>
      )}

      {!!addressMappings?.[0] && (
        <div>
          <div css={classes.label}>Account Stencil</div>
          <AccountStencil account={nicknameAccount} />
        </div>
      )}

      {!!stakeholders.length && (
        <>
          <hr css={classes.partitionLine} />
          <div>
            <table
              css={{
                borderCollapse: 'collapse',
                border: 'none'
              }}
            >
              <thead>
                <tr>
                  <th css={{ textAlign: 'left', padding: '0 3px' }}>
                    Stakeholder
                  </th>
                  <th css={{ textAlign: 'left', padding: '0 3px 0 10px' }}>
                    Relationship Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {stakeholders.map((stakeholder) => {
                  const {
                    id,
                    accountRelationship,
                    stakeholderRelationship,
                    stakeholderType,
                    party
                  } = stakeholder
                  const type =
                    accountRelationship ||
                    stakeholderType ||
                    stakeholderRelationship
                  const partyName = party?.partyName

                  if (!type || !partyName) {
                    return null
                  }

                  return (
                    <tr key={id}>
                      <td
                        css={{
                          padding: '2px 3px'
                        }}
                      >
                        {partyName}
                      </td>
                      <td css={{ padding: '2px 3px 2px 10px' }}>
                        <div style={{ display: 'flex', columnGap: 5 }}>
                          <div
                            title={type.name}
                            style={{
                              maxWidth: '250px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {type.name}
                          </div>
                          <div>({type.code})</div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <hr css={classes.partitionLine} />
        </>
      )}

      {!!nicknameAccount?.accountNumber && (
        <>
          <div
            css={{
              display: 'flex',
              cursor: 'pointer',
              gridColumnGap: 5,
              alignItems: 'center'
            }}
            title="View Related Parties"
            onClick={onRelatedPartiesClicked}
          >
            <Icon
              type="InterestedParties"
              height={12}
              width={12}
              color={theme.colors.primaryDarkBlue}
            />
            <a css={[classes.linkText, { textDecoration: 'underline' }]}>
              Related Parties
            </a>
          </div>
        </>
      )}

      {marginAgreement === 'Yes' && !multipleMargin && (
        <div css={{ marginTop: 6 }}>
          <div css={classes.note}>
            <sup>M</sup> This account has a margin agreement
          </div>
        </div>
      )}

      {isMultiMargin(searchAccount) && (
        <div css={{ marginTop: 6 }}>
          <div css={classes.note}>
            <sup style={{ color: '#8B0000' }}>M</sup> This account is part of a
            multi margin relationship
          </div>
        </div>
      )}
      {CustodianCode !== 'NFS' && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 10
          }}
        >
          Non-NFS share quantities are as of previous day and values are
          adjusted for current market price.
        </div>
      )}
    </div>
  )
}
