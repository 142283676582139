import { ITheme, Label, Stack } from '@fluentui/react'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { USDCellWithColor } from 'modules/Advisory/modules/Rdot360/components/USDCellWithColor'
import { ITodaysGainLossActivities } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPostitionResponse'
import React from 'react'
import { useClasses } from 'shared/hooks/useClasses'

const getActivityContainerStyles = (theme: ITheme) => ({
  textBolder: {
    fontWeight: 'bold'
  },
  containerSpacing: {
    padding: '2px 8px'
  },
  activityContainerCaption: {
    backgroundColor: theme.palette.themeLighter
  },
  activityContainerRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.neutralLighterAlt
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.white
    }
  }
})

const ActivityContainerRow: React.FC<ITodaysGainLossActivities> = (
  props: ITodaysGainLossActivities
) => {
  const { activity, amount } = props
  const fuiClasses = useClasses(getActivityContainerStyles)
  const activityContainerRowClass = [
    fuiClasses.containerSpacing,
    fuiClasses.activityContainerRow
  ].join(' ')

  return (
    <Stack
      horizontal={true}
      horizontalAlign="space-between"
      verticalAlign="baseline"
      className={activityContainerRowClass}
    >
      <Label styles={{ root: { fontWeight: 500 } }}>{activity}</Label>
      <USDCellWithColor value={amount} fractionDigit={2} />
    </Stack>
  )
}

const ActivityContainerCaption: React.FC<ITodaysGainLossActivities> = (
  props: ITodaysGainLossActivities
) => {
  const { activity = 'Label', amount = 0 } = props
  const fuiClasses = useClasses(getActivityContainerStyles)
  const activityContainerCaptionClass = [
    fuiClasses.containerSpacing,
    fuiClasses.activityContainerCaption
  ].join(' ')

  return (
    <Stack
      horizontal={true}
      horizontalAlign="space-between"
      verticalAlign="baseline"
      className={activityContainerCaptionClass}
    >
      <Label>{activity}</Label>
      <USDCell
        value={amount}
        fractionDigits={2}
        addedRootStyles={{ root: { fontWeight: 700 } }}
      />
    </Stack>
  )
}

export const TodaysChangePlainCard: React.FC<{
  todaysGainLossActivities?: ITodaysGainLossActivities[]
  clsmktval?: number
  rcntmktval?: number
}> = ({ todaysGainLossActivities, clsmktval, rcntmktval }) => (
  <div css={{ minWidth: 340, padding: 10 }}>
    <ActivityContainerCaption activity="Opening Value" amount={clsmktval} />

    {todaysGainLossActivities?.map((activityDetail, index) => {
      const { activity, amount = 0 } = activityDetail
      return activity ? (
        <ActivityContainerRow key={index} activity={activity} amount={amount} />
      ) : null
    })}

    <ActivityContainerCaption activity="Current Value" amount={rcntmktval} />
  </div>
)
