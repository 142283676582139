import { lazy } from 'react'
import { combineReducers, Reducer } from 'redux'
import { IDiscountShares } from '../../../../api/datahub'
import { IDataListState } from '../../../../features/DataList/contracts/IDataListState'
import { IModuleDefinition } from '../../../types'
import {
  bdaAdminPublishListReducer,
  bdaAdminPublishListSagas
} from './store/bdaAdminPublishList'
import {
  bdaAdminSecurityUserListReducer,
  bdaAdminSecurityUserListSagas
} from './store/bdaAdminSecurityUserList'
import { bdaContainerReducer, bdaContainerSagas } from './store/bdaContainer'
import {
  bdaDepartmentAllowancessReducer,
  bdaDepartmentAllowancesSagas
} from './store/bdaDepartmentAllowances'
import {
  bdaDepartmentListReducer,
  bdaDepartmentListSagas
} from './store/bdaDepartmentList'
import {
  bdaPayrollExpensesReducer,
  bdaPayrollExpensesSagas
} from './store/bdaPayrollExpenses'
import {
  bdaPayrollListReducer,
  bdaPayrollListSagas
} from './store/bdaPayrollList'
import {
  bdaTransactionsReducer,
  bdaTransactionsSagas
} from './store/bdaTransactions'
import {
  bdaTransactionListReducer,
  bdaTransactionListSagas
} from './store/bdaTransactionsList'
import {
  discountSharingFetchReducer,
  discountSharingFetchSagas
} from './store/discountSharing'
import {
  discountSharingListReducer,
  discountSharingListSagas
} from './store/discountSharingList'
import {
  discountSplitsFetchReducer,
  discountSplitsFetchSagas
} from './store/discountSplits'
import { discountSplitUIReducer } from './store/discountSplitsUI'

const BDAContainer = lazy(() => import('./BDAContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/bda/*',
    private: true,
    component: BDAContainer
  },
  name: 'BDA Dashboard'
}

export default moduleDefinition

export const bdaReducer: Reducer<{
  transactions: ReturnType<typeof bdaTransactionsReducer>
  payrollExpenses: ReturnType<typeof bdaPayrollExpensesReducer>
  departmentAllowances: ReturnType<typeof bdaDepartmentAllowancessReducer>
  bdaContainer: ReturnType<typeof bdaContainerReducer>
  bdaPayrollList: ReturnType<typeof bdaPayrollListReducer>
  bdaTransactionList: ReturnType<typeof bdaTransactionListReducer>
  bdaAdminSecurityUsersList: ReturnType<typeof bdaAdminSecurityUserListReducer>
  bdaAdminPublishList: ReturnType<typeof bdaAdminPublishListReducer>
  bdaDepartmentList: ReturnType<typeof bdaDepartmentListReducer>
  discountSharing: ReturnType<typeof discountSharingFetchReducer>
  discountSharingList: IDataListState<IDiscountShares>
  discountSplits: ReturnType<typeof discountSplitsFetchReducer>
  discountSplitUI: ReturnType<typeof discountSplitUIReducer>
}> = combineReducers({
  transactions: bdaTransactionsReducer,
  payrollExpenses: bdaPayrollExpensesReducer,
  departmentAllowances: bdaDepartmentAllowancessReducer,
  bdaContainer: bdaContainerReducer,
  bdaPayrollList: bdaPayrollListReducer,
  bdaTransactionList: bdaTransactionListReducer,
  bdaAdminSecurityUsersList: bdaAdminSecurityUserListReducer,
  bdaAdminPublishList: bdaAdminPublishListReducer,
  bdaDepartmentList: bdaDepartmentListReducer,
  discountSharing: discountSharingFetchReducer,
  discountSharingList: discountSharingListReducer,
  discountSplits: discountSplitsFetchReducer,
  discountSplitUI: discountSplitUIReducer
})

export const bdaSagas = [
  ...bdaTransactionsSagas,
  ...bdaPayrollExpensesSagas,
  ...bdaDepartmentAllowancesSagas,
  ...bdaPayrollListSagas,
  ...bdaTransactionListSagas,
  ...bdaContainerSagas,
  ...bdaAdminPublishListSagas,
  ...bdaAdminSecurityUserListSagas,
  ...bdaDepartmentListSagas,
  ...discountSharingFetchSagas,
  ...discountSharingListSagas,
  ...discountSplitsFetchSagas
]
