import { css } from '@emotion/react'
import { useIncomeFeatureStore } from 'modules/Advisory/modules/Rdot360/features/Income'
import { useGetHistoricalIncomeChartQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo } from 'react'
import { Divider } from './Divider'
import { TotalLabelValue } from './TotalLabelValue'

const getClasses = () => ({
  totalRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    margin: '10px 0'
  })
})

export const HistoricalIncomeTotals: React.FC = () => {
  const classes = useMemo(() => getClasses(), [])
  const { financialYear, includeOptionPremiums } = useIncomeFeatureStore()

  const { data: historicalChartData } =
    useGetHistoricalIncomeChartQueryForSelectedAccounts(financialYear)

  return (
    <div css={classes.totalRow}>
      <TotalLabelValue
        label="Total Interest"
        value={historicalChartData?.interestReceived}
      />
      <Divider />
      <TotalLabelValue
        label="Total Dividends"
        value={historicalChartData?.dividend}
      />
      <Divider />
      <TotalLabelValue
        label="Total Income"
        value={
          includeOptionPremiums
            ? historicalChartData?.total
            : historicalChartData?.totalExcludingOptionPremiums
        }
      />
      <Divider />
      {includeOptionPremiums && (
        <>
          <TotalLabelValue
            label="Total Option Premiums Paid"
            value={historicalChartData?.optionPremiumsPaid}
          />
          <Divider />
          <TotalLabelValue
            label="Total Option Premiums Received"
            value={historicalChartData?.optionPremiumsReceived}
          />
          <Divider />
        </>
      )}

      <TotalLabelValue
        label="Total Capital Gains Distribution"
        value={historicalChartData?.capitalGains}
      />
    </div>
  )
}
