import { Icon, Stack, Text } from '@fluentui/react'
import React, { memo } from 'react'
import { isNotNullOrEmpty } from 'shared/guards'
import { useClasses } from 'shared/hooks/useClasses'
import { IAccount } from '../../../api/account.types'
import { USD } from '../../../shared/components/Formatting'
import { getThemedClasses } from '../shared/styles'
import { ConnectedMaskedHighlight, Highlight } from './Highlight'

export interface IAccountSearchResultsProps {
  className?: string
  accounts: IAccount[]
  onAccountClick: (account: IAccount) => void
  query?: string
}

const ellipsis: React.CSSProperties = {
  maxWidth: '170px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

export const AccountSearchResults: React.FC<IAccountSearchResultsProps> = memo(
  ({ className, accounts, onAccountClick, query }) => {
    return (
      <table className={className}>
        <thead>
          <tr>
            <td />
            <td>
              <Text variant="small">
                <Stack horizontal={true} horizontalAlign="space-between">
                  <b>
                    ENTITY NAME / <br /> SHORT NAME
                  </b>
                  <b>REP</b>
                </Stack>
              </Text>
            </td>
            <td>
              <Text variant="small">
                <b>AUS</b>
              </Text>
            </td>
          </tr>
        </thead>
        <tbody>
          {accounts.map((x) => {
            const onClick = () => onAccountClick(x)
            const {
              id,
              CustodyAccount,
              LegalEntityName,
              registrationtype,
              AdvisorAddedNickName,
              Shortname,
              ClientAdvisorID,
              gfoCustodyAccount,
              RDOTAccountCategoryCode,
              CustodianName
            } = x
            return (
              <tr key={id} onClick={onClick}>
                <td style={{ verticalAlign: 'top' }}>
                  <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                    <Stack.Item verticalFill={true} align="center">
                      <Icon iconName="BankSolid" />
                    </Stack.Item>
                    <Stack.Item styles={{ root: { minWidth: 0 } }}>
                      <Stack
                        horizontal={true}
                        tokens={{ childrenGap: 3 }}
                        verticalAlign="center"
                      >
                        <Text nowrap={true} block={true} variant="medium">
                          <ConnectedMaskedHighlight
                            text={CustodyAccount || ''}
                            highlight={query}
                          />
                        </Text>
                        {registrationtype && (
                          <Text nowrap={true} block={true} variant="small">
                            ({registrationtype})
                          </Text>
                        )}
                      </Stack>
                      {AdvisorAddedNickName && (
                        <Text nowrap={true} block={true} variant="small">
                          <Highlight
                            text={AdvisorAddedNickName || ''}
                            highlight={query}
                          />
                        </Text>
                      )}
                      {gfoCustodyAccount && (
                        <Text nowrap={true} block={true} variant="small">
                          <Highlight
                            text={gfoCustodyAccount || ''}
                            highlight={query}
                          />
                        </Text>
                      )}
                      {(RDOTAccountCategoryCode === '02' ||
                        RDOTAccountCategoryCode === '03') && (
                        <Text nowrap={true} block={true} variant="small">
                          {CustodianName}
                        </Text>
                      )}
                    </Stack.Item>
                  </Stack>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="space-between"
                  >
                    <Stack.Item styles={{ root: { minWidth: 0 } }}>
                      <Text nowrap={true} block={true}>
                        <Highlight
                          text={LegalEntityName || ''}
                          highlight={query}
                        />
                      </Text>
                      <Text nowrap={true} block={true} variant="small">
                        <Highlight text={Shortname || ''} highlight={query} />
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text nowrap={true} block={true} variant="small">
                        {ClientAdvisorID}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Text variant="small">
                    <USD value={x?.AccountKPIs?.AccountTotal || 0} />
                  </Text>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
)

export const AccountSearchResultsV2: React.FC<IAccountSearchResultsProps> =
  memo(({ className, accounts, onAccountClick, query }) => {
    const classes = useClasses(getThemedClasses)
    return (
      <div
        className={className}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {accounts.map((account) => {
          const {
            id,
            CustodyAccount,
            LegalEntityName,
            registrationtype,
            AdvisorAddedNickName,
            ClientAdvisorID,
            CustodianName,
            ClientAdvisor,
            RockConnectAccBalance,
            AccountKPIs
          } = account
          const onClick = () => onAccountClick(account)
          return (
            <div key={id} className={classes.rowItem}>
              <div
                onClick={onClick}
                style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
              >
                <div style={{ alignSelf: 'center' }}>
                  <Icon iconName="BankSolid" />
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Text
                    nowrap={true}
                    block={true}
                    variant="medium"
                    title={[CustodyAccount, AdvisorAddedNickName]
                      .filter(isNotNullOrEmpty)
                      .join(' - ')}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                        alignItems: 'end'
                      }}
                    >
                      <ConnectedMaskedHighlight
                        text={CustodyAccount}
                        highlight={query}
                      />
                      <span style={{ fontSize: '11px', fontWeight: '600' }}>
                        ({registrationtype})
                      </span>
                      {AdvisorAddedNickName && (
                        <>
                          -
                          <ConnectedMaskedHighlight
                            text={AdvisorAddedNickName}
                            highlight={query}
                          />
                        </>
                      )}
                    </div>
                  </Text>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text
                      styles={{
                        root: { fontSize: '11px', fontWeight: '300' }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '3px'
                        }}
                      >
                        <div title={ClientAdvisor}>{ClientAdvisorID || ''}</div>
                        <>|</>
                        <div style={ellipsis} title="Legal Entity Name">
                          <ConnectedMaskedHighlight
                            text={LegalEntityName}
                            highlight={query}
                          />
                        </div>
                        <>|</>
                        <div style={ellipsis} title="Custodian Name">
                          {CustodianName}
                        </div>
                        <>|</>
                        <div title="Account Balance">
                          <USD
                            value={
                              RockConnectAccBalance ||
                              AccountKPIs?.AccountTotal ||
                              0
                            }
                            fractionDigits={0}
                          />
                        </div>
                      </div>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  })
