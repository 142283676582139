import {
  Stack,
  Text,
  IStyle,
  makeStyles,
  MessageBar,
  MessageBarType,
  FontSizes,
  mergeStyleSets,
  useTheme
} from '@fluentui/react'
import { format } from 'date-fns'
import { groupBy, toLower } from 'lodash'
import { useGetMostRecentClosedRevenueDate } from 'modules/Advisory/modules/Revenue/store/mostRecentClosedDateFetch'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tinycolor from 'tinycolor2'
import { USD } from '../../../../../../../../shared/components/Formatting'
import { chartColors } from '../../../../../../../../shared/services/theme'
import {
  getRevenueDashboardCategoryMode,
  revenueDashboardActions
} from '../../store/dashboard'
import { InfoHoverCard } from './InfoHoverCard'
import { getRevenueSummaryFetchError } from './store/revenueSummaryFetch'

import {
  getMonthHeadings,
  getTableItems,
  getTableTotals,
  IRevenueTableItem
} from './store/revenueSummaryTable'

const blueLight = chartColors[1]
const blueDarkHex = tinycolor(blueLight).darken(25).toHex()

export const useRevenueTableStyles = makeStyles((theme) => {
  const revenueTable: IStyle = {
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'collapse',
    border: 'none',
    'th, td': {
      padding: '2px 8px',
      textAlign: 'right',
      width: '110px'
    },
    'td.total': {
      fontWeight: 'bolder',
      borderTop: `solid 3px ${theme.palette.white}`
    },
    'tr:first-child th': {
      borderBottom: `solid 3px ${theme.palette.white}`
    },
    'td.category': {
      paddingLeft: '25px'
    },
    'td.categoryTotal, th.categoryTotal': {
      fontWeight: 'bolder',
      position: 'sticky',
      left: '175px',
      zIndex: 1,
      backgroundColor: theme.palette.neutralLighterAlt,
      borderRight: `solid 3px ${theme.palette.white}`
    },
    'tr th:first-child, tr td:first-child': {
      fontWeight: 'bold',
      textAlign: 'left',
      width: '175px',
      position: 'sticky',
      left: 0,
      zIndex: 1,
      backgroundColor: theme.semanticColors.bodyBackground
    },
    'thead th': {
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    'tr:hover td': {
      backgroundColor: `${theme.palette.neutralLight}cc !important`
    },
    'td.actionable:hover': {
      backgroundColor: `#${blueDarkHex} !important`,
      cursor: 'pointer',
      color: theme.palette.white,
      fontWeight: 'bold'
    }
  }

  return {
    revenueTable
  }
})

const useClosedHightlightClasses = (index = 0) => {
  const theme = useTheme()
  const styles = useMemo(
    () =>
      mergeStyleSets({
        closedHighlight: {
          [`tr td:nth-child(n+3):nth-child(-n+${index + 2}),
            tr th:nth-child(n+3):nth-child(-n+${index + 2})`]: {
            backgroundColor: `${blueLight}22`
          },

          [`tr td:nth-child(${index + 2}),
            tr th:nth-child(${index + 2})`]: {
            borderRight: `solid 3px ${theme.palette.white}`
          }
        }
      }),
    [index, theme]
  )

  return styles
}

export const RevenueSummaryTable: React.FC = () => {
  const dispatch = useDispatch()
  const categoryMode = useSelector(getRevenueDashboardCategoryMode)
  const tableItems = useSelector(getTableItems)
  const tableTotals = useSelector(getTableTotals)
  const monthHeadings = useSelector(getMonthHeadings)
  const classes = useRevenueTableStyles()
  const summaryError = useSelector(getRevenueSummaryFetchError)
  const { mostRecentClosedDate } = useGetMostRecentClosedRevenueDate()
  const lastClosedMonthIndex = useMemo(
    () =>
      mostRecentClosedDate
        ? monthHeadings?.findIndex((x) => x >= mostRecentClosedDate)
        : undefined,
    [monthHeadings, mostRecentClosedDate]
  )
  const closedHighlightClasses =
    useClosedHightlightClasses(lastClosedMonthIndex)
  const onCategoryTotalClick = useCallback(
    (category: string) => {
      if (!categoryMode) {
        return
      }
      dispatch(
        revenueDashboardActions.setSelectedCategory({
          category,
          type: categoryMode
        })
      )

      dispatch(revenueDashboardActions.setSelectedMonth(undefined))
    },
    [categoryMode, dispatch]
  )
  const onMonthTotalClick = useCallback(
    (date: Date) => {
      dispatch(revenueDashboardActions.setSelectedCategory(undefined))
      dispatch(revenueDashboardActions.setSelectedMonth(date))
    },
    [dispatch]
  )
  const onTotalClick = useCallback(() => {
    dispatch(revenueDashboardActions.setSelectedCategory(undefined))
    dispatch(revenueDashboardActions.setSelectedMonth(undefined))
  }, [dispatch])

  const onMonthClick = useCallback(
    (category: string, date: Date) => {
      if (!categoryMode) {
        return
      }
      dispatch(
        revenueDashboardActions.setSelectedCategory({
          category,
          type: categoryMode
        })
      )

      dispatch(revenueDashboardActions.setSelectedMonth(date))
    },
    [categoryMode, dispatch]
  )
  const items = useMemo(() => {
    if (categoryMode === 'L1') {
      return tableItems
    }
    const otherCategory = 'Uncategorized'
    const parentGroups = groupBy(
      tableItems,
      ({ parentCategory = otherCategory }) => parentCategory
    )
    const categoriesWithHeadingRows: IRevenueTableItem[] = []
    Object.entries(parentGroups).map(([parent, categories]) => {
      categoriesWithHeadingRows.push({
        category: parent,
        total: 0,
        monthlyTotals: new Array(
          categories?.[0].monthlyTotals.length || 0
        ).fill(0),
        heading: true
      })
      categories.map((category) => categoriesWithHeadingRows.push(category))
    })
    return categoriesWithHeadingRows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableItems])

  const getOpenOrClosedForDate = (date?: Date) =>
    date && mostRecentClosedDate && date <= mostRecentClosedDate
      ? 'Closed'
      : 'Open'

  return (
    <>
      {summaryError && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          An error occurred while loading: {summaryError.message}
        </MessageBar>
      )}
      <table
        className={[
          closedHighlightClasses.closedHighlight,
          classes.revenueTable
        ].join(' ')}
      >
        <thead>
          <tr>
            <th>
              <Stack horizontal={true}>
                <Text style={{ fontWeight: 'bold' }}>Category</Text>
                <InfoHoverCard />
              </Stack>
            </th>
            <th className="categoryTotal">Total</th>
            {monthHeadings?.map((month, index) => {
              const openOrClosed = getOpenOrClosedForDate(month)
              return (
                <th key={`month${index}`} className={toLower(openOrClosed)}>
                  <div style={{ fontWeight: 'bold' }}>
                    {format(month, `MMM ''yy`)}
                  </div>
                  <div
                    style={{ fontSize: FontSizes.small, fontWeight: 'normal' }}
                  >
                    {openOrClosed}
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {items?.map((item, index) => (
            <tr key={index}>
              {item.heading ? (
                <td>
                  <Text
                    style={{ fontWeight: 'bold' }}
                    nowrap={true}
                    block={true}
                  >
                    {item.category}
                  </Text>
                </td>
              ) : (
                <td className={'category'}>
                  <Text nowrap={true} block={true}>
                    {item.category}
                  </Text>
                </td>
              )}
              {!item.heading && item.total !== 0 ? (
                <td
                  className={'actionable categoryTotal'}
                  onClick={() => onCategoryTotalClick(item.category)}
                >
                  <USD
                    currencySign={'accounting'}
                    value={item.total}
                    fractionDigits={0}
                  />
                </td>
              ) : (
                <td className={'categoryTotal'}>{item.heading ? '' : '--'}</td>
              )}
              {item.monthlyTotals.map((month, index2) =>
                tableTotals.monthlyTotals[index2] !== 0 ? (
                  <td
                    key={`${index}-${index2}`}
                    className={!item.heading ? 'actionable' : undefined}
                    onClick={() =>
                      !item.heading &&
                      monthHeadings &&
                      onMonthClick(item.category, monthHeadings[index2])
                    }
                  >
                    {item.heading ? (
                      ''
                    ) : (
                      <USD
                        currencySign={'accounting'}
                        value={month}
                        fractionDigits={0}
                      />
                    )}
                  </td>
                ) : (
                  <td key={`${index}-${index2}`}>--</td>
                )
              )}
            </tr>
          ))}
          <tr>
            <td className={'total'}>{tableTotals.category}</td>
            {tableTotals.total !== 0 ? (
              <td
                className={'total actionable categoryTotal'}
                onClick={onTotalClick}
              >
                <USD
                  currencySign={'accounting'}
                  value={tableTotals.total}
                  fractionDigits={0}
                />
              </td>
            ) : (
              <td className={'total categoryTotal'}>--</td>
            )}
            {tableTotals.monthlyTotals.map((total, index) =>
              total !== 0 ? (
                <td
                  key={`total${index}`}
                  className={'total actionable'}
                  onClick={() =>
                    monthHeadings && onMonthTotalClick(monthHeadings[index])
                  }
                >
                  <USD
                    currencySign={'accounting'}
                    value={total}
                    fractionDigits={0}
                  />
                </td>
              ) : (
                <td className={'total'} key={`total${index}`}>
                  --
                </td>
              )
            )}
          </tr>
        </tbody>
      </table>
    </>
  )
}
