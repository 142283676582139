import createCache from '@emotion/cache'
import { ThemeProvider, CacheProvider } from '@emotion/react'
import { useCallback } from 'react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider, useStore } from 'react-redux'
import { AnyAction, Store } from 'redux'
import { whenIdle } from 'shared/async'
import { theme } from 'shared/theme'
import { useActivityDetails } from '../../../../hooks/useActivityDetails'
import { IActivity } from '../../../../store/types'
import { PrintHTML } from './PrintHTML'

export const useActivityPrint = () => {
  const store = useStore()
  const { activityData } = useActivityDetails()
  const print = useCallback(
    (masked = false) => {
      openActivityPrintTab(activityData?.activities || [], masked, store)
    },
    [activityData?.activities, store]
  )

  return { print }
}

const generateHTML = async (
  activityData: IActivity[],
  masked: boolean,
  store: Store<unknown, AnyAction>
) => {
  // Emotion renders the style tags in this div with dynamic classnames for each css prop
  const emotionContainer = document.createElement('div')
  const cache = createCache({
    key: 'print',
    speedy: false,
    container: emotionContainer
  })
  const data = activityData || []
  const container = document.createElement('div')
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <IntlProvider locale="en">
        <ThemeProvider theme={theme}>
          <CacheProvider value={cache}>
            <PrintHTML data={data} masked={masked} />
          </CacheProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  )
  await whenIdle()
  container.appendChild(emotionContainer)
  const html = container.innerHTML
  root.unmount()

  return html
}

export const openActivityPrintTab = async (
  activityData: IActivity[],
  masked: boolean,
  store: Store<unknown, AnyAction>
) => {
  const innerHtml = await generateHTML(activityData, masked, store)
  const blob = new Blob([innerHtml], { type: 'text/html' })
  const url = URL.createObjectURL(blob)
  open(url, '_blank', 'noreferrer')
  setTimeout(() => URL.revokeObjectURL(url), 5000)
}
