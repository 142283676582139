import { Theme, css, useTheme } from '@emotion/react'
import { sum } from 'lodash'
import { FC, useCallback, useMemo } from 'react'
import { isNotNullOrFalse } from 'shared/guards'
import { currentYear } from '../../../components/shared/filterOptions'
import Toggle from '../../../components/shared/Toggle'
import { useIncomeFeatureStore } from '../../../features/Income'
import { IndeterminateCheckbox } from '../../../shared/IndeterminateCheckbox'
import { IIncomeMonthlySummaryResponse } from '../../../store/holdingsApi/IIncomeMonthlySummaryResponse'
import { IncomeTotalLabel } from './IncomeTotalLabel'
import { TotalInterestDividend } from './TotalInterestDividend'

export const getClasses = (theme: Theme) => ({
  subHeaderContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    margin: '3px 0'
  }),
  subHeaderLabel: css({
    display: 'flex',
    marginTop: '4'
  }),
  toggleContainer: css({
    display: 'flex'
  }),
  totalHeader: css({
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '4px 8px 8px 0',
    textOverflow: 'ellipsis'
  }),
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  tertiaryGray1: css({
    color: theme.colors.tertiaryGray1
  }),
  primaryDarkBlue: css({
    color: theme.colors.primaryDarkBlue
  }),
  margin2: css({
    margin: 2
  }),
  checkboxContainer: css({
    marginBottom: '4px',
    marginTop: '4px',
    display: 'flex'
  }),
  checkboxLabel: css({
    marginLeft: '4px',
    fontSize: '12px'
  })
})
export const IncomeWidgetHeader: FC<{
  data?: IIncomeMonthlySummaryResponse
}> = ({ data = {} }) => {
  const {
    incomeMode,
    setIncomeMode,
    includeOptionPremiums,
    setIncludeOptionPremiums,
    setFinancialYear
  } = useIncomeFeatureStore()

  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const isHistorical = incomeMode === 'historical'

  const incomeTotal =
    incomeMode === 'projected'
      ? data?.total || 0
      : sum(
          [
            data?.totalExcludingOptionPremiums,
            includeOptionPremiums && data?.optionPremiumsPaid,
            includeOptionPremiums && data?.optionPremiumsReceived
          ].filter(isNotNullOrFalse)
        ) || 0

  const handleIncomeTypeChangeClick = useCallback(() => {
    setIncomeMode(incomeMode === 'historical' ? 'projected' : 'historical')
    setFinancialYear(!isHistorical ? currentYear : 2)
  }, [incomeMode, isHistorical, setFinancialYear, setIncomeMode])

  const handleCheckboxChange = useCallback(
    (e: any) => {
      setIncludeOptionPremiums(e.target.checked)
    },
    [setIncludeOptionPremiums]
  )

  const isOptionsPremiumsAvailable = useCallback(
    (data: IIncomeMonthlySummaryResponse = {}) =>
      !!data.optionPremiumsPaid || !!data.optionPremiumsReceived,
    []
  )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: 12
      }}
    >
      <div css={classes.subHeaderContainer}>
        <IncomeTotalLabel
          financialYearLabel={`12-Month ${incomeMode}`}
          incomeTotal={incomeTotal}
        />

        <div css={classes.toggleContainer}>
          <div
            css={[
              classes.margin2,
              isHistorical ? classes.primaryDarkBlue : classes.tertiaryGray1
            ]}
          >
            Historical
          </div>
          <Toggle
            checked={!isHistorical}
            onChange={handleIncomeTypeChangeClick}
            role="checkbox"
          />
          <div
            css={[
              classes.margin2,
              !isHistorical ? classes.primaryDarkBlue : classes.tertiaryGray1
            ]}
          >
            Projected
          </div>
        </div>
      </div>

      <div
        css={{
          display: 'flex',
          marginBottom: 4,
          flexDirection: 'row',
          flexWrap: 'wrap',
          flexGrow: 1
        }}
      >
        <TotalInterestDividend
          interest={
            isHistorical ? data?.interestReceived || 0 : data?.interest || 0
          }
          dividend={data?.dividend}
          capGain={isHistorical && data?.capitalGains}
        />

        {isOptionsPremiumsAvailable(data) && isHistorical && (
          <div css={classes.checkboxContainer}>
            <IndeterminateCheckbox
              id="optionPremiumsLabelCheckbox"
              className="checkbox"
              checked={includeOptionPremiums}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="optionPremiumsLabelCheckbox"
              css={classes.checkboxLabel}
            >
              Include Option Premiums
            </label>
          </div>
        )}
      </div>
    </div>
  )
}
