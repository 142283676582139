import { Link } from '@fluentui/react'
import {
  Cell,
  CellContext,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table'
import { keyBy, sumBy } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { convertTaxStatus } from '../../shared/taxStatus'
import { useRdot360AccountContext } from '../../store/rdot360Context/useRdot360AccountContext'
import {
  EnhancedBalanceDetailResponseValue,
  getTodaysChange
} from '../../store/rdot360Context/useRdot360BalancesContext'
import { AccountSelectorAccount } from './AccountSelectorAccount'
import { AccountSelectorGroup } from './AccountSelectorGroup'
import {
  accountSelectorColumnNames,
  useAccountSelectorTableStore
} from './store/store'
import { accountSelectorStyles } from './styles'
import {
  rdotCategoryToClientDashboardCategoryMap,
  IAccountSelectorAccount
} from './types'

const GroupCell: React.FC<CellContext<IAccountSelectorAccount, unknown>> = ({
  row
}) => {
  const title =
    row.groupingColumnId && row.getValue<string>(row.groupingColumnId)
  const { selectedIdsLookup, setSelectedIds } = useAccountSelectorTableStore()
  const selectedSubRows =
    row?.subRows?.filter(
      (x) => selectedIdsLookup[x.id] && !x.original.placeholder
    ) ?? 0

  const subRows = row?.subRows?.filter((x) => !x.original.placeholder)
  const isAllSelected =
    !!selectedSubRows.length && selectedSubRows.length === subRows.length
  const isSomeSelected =
    !isAllSelected &&
    !!selectedSubRows.length &&
    selectedSubRows.length !== subRows.length

  const value = sumBy(
    selectedSubRows,
    ({ getValue }) => getValue<number>(accountSelectorColumnNames.value) ?? 0
  )

  const todaysChange = useMemo(() => {
    const balancesTodaysChange = getTodaysChange(
      selectedSubRows.map(
        (item) => item.original.balance as EnhancedBalanceDetailResponseValue
      )
    )

    return balancesTodaysChange
  }, [selectedSubRows])

  const change = sumBy(
    selectedSubRows,
    ({ getValue }) =>
      getValue<number>(accountSelectorColumnNames.changeValue) ?? 0
  )

  const onRowSelectionChange = useCallback(() => {
    const selection = subRows.reduce(
      (a, x) => ({ ...a, [x.id]: !isAllSelected }),
      {} as Record<string, boolean>
    )

    const newSelection = Object.entries({
      ...selectedIdsLookup,
      ...selection
    })
      .filter(([, value]) => value)
      .map(([key]) => key)

    setSelectedIds(newSelection)
  }, [isAllSelected, selectedIdsLookup, setSelectedIds, subRows])

  if (!row.groupingColumnId) {
    return <></>
  }

  return (
    <AccountSelectorGroup
      title={title}
      checked={isAllSelected}
      indeterminate={isSomeSelected}
      onChange={onRowSelectionChange}
      selectedSubrowsCount={selectedSubRows.length}
      subrowsCount={subRows?.length ?? 0}
      groupBalance={value}
      groupBalanceChange={change}
      isExpanded={row.getIsExpanded()}
      toggleExpanded={row.getToggleExpandedHandler()}
      todaysChange={todaysChange}
    />
  )
}

const AccountCell: React.FC<CellContext<IAccountSelectorAccount, unknown>> = ({
  row
}) => {
  const { account, balance } = row.original
  const value = balance?.netWorth
  const change = balance?.netWorthChange
  const todaysChange = balance?.todayschange
  const { selectedIdsLookup, setSelectedIds } = useAccountSelectorTableStore()
  const checked = !!selectedIdsLookup[row.id]
  const onRowSelectionChange = useCallback(() => {
    const newSelection = Object.entries({
      ...selectedIdsLookup,
      [row.id]: !checked
    })
      .filter(([, value]) => value)
      .map(([key]) => key)
    setSelectedIds(newSelection)
  }, [checked, row.id, selectedIdsLookup, setSelectedIds])
  return (
    <AccountSelectorAccount
      checked={checked}
      onChange={onRowSelectionChange}
      accountNumber={account?.accountId}
      balance={value}
      change={change}
      todaysChange={todaysChange}
    />
  )
}

const getColumnDefs = (): ColumnDef<IAccountSelectorAccount>[] => [
  {
    header: accountSelectorColumnNames.accountNumber,
    enableHiding: true,
    enableGrouping: false,
    accessorFn: ({ account }) => account?.CustodyAccount,
    cell: AccountCell,
    aggregatedCell: GroupCell
  },
  {
    header: accountSelectorColumnNames.clientDashboardCategoryCode,
    enableHiding: true,
    enableGrouping: true,
    aggregationFn: 'max',
    accessorFn: ({ clientDashboardCategoryCode }) =>
      clientDashboardCategoryCode || '04'
  },
  {
    header: accountSelectorColumnNames.clientDashboardCategory,
    enableHiding: true,
    enableGrouping: true,
    aggregationFn: 'unique',
    accessorFn: ({ clientDashboardCategoryCode }) =>
      rdotCategoryToClientDashboardCategoryMap[
        clientDashboardCategoryCode ?? '04'
      ]
  },
  {
    header: accountSelectorColumnNames.CustodianName,
    enableHiding: true,
    enableGrouping: true,
    aggregationFn: 'unique',
    accessorFn: ({ account }) =>
      account?.CustodianName === 'nfs' ? 'NFS' : account?.CustodianName
  },
  {
    header: accountSelectorColumnNames.taxable,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) => convertTaxStatus(account?.taxstatus)
  },
  {
    header: accountSelectorColumnNames.legalEntityId,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) => account?.LegalEntityName
  },
  {
    header: accountSelectorColumnNames.accountRegistration,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) =>
      account?.registrationDesc ?? account?.registrationtype
  },
  {
    header: accountSelectorColumnNames.ausClass,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'unique',
    accessorFn: ({ account }) => account?.accounttype
  },
  {
    header: accountSelectorColumnNames.value,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'sum',
    accessorFn: ({ balance }) => balance?.netWorth ?? 0
  },
  {
    header: accountSelectorColumnNames.changeValue,
    enableHiding: true,
    enableGrouping: false,
    aggregationFn: 'sum',
    accessorFn: ({ balance }) => balance?.netWorthChange ?? 0
  }
]

const noVisible = Object.entries(accountSelectorColumnNames).reduce(
  (a, [key]) => ({ ...a, [key]: false }),
  {} as VisibilityState
)

const defaultVisibilityState: VisibilityState = {
  ...noVisible,
  [accountSelectorColumnNames.accountNumber]: true
}

const GroupRowContainer: React.FC<{
  cell: Cell<IAccountSelectorAccount, unknown>
  isCollapsed?: boolean
}> = ({ cell, isCollapsed }) => {
  return (
    <div
      css={[
        accountSelectorStyles.groupRowOuterContainer,
        isCollapsed && accountSelectorStyles.groupRowOuterContainerCollapsed
      ]}
    >
      <div
        css={[
          accountSelectorStyles.groupRowInnerContainer,
          isCollapsed && accountSelectorStyles.groupRowInnerContainerCollapsed
        ]}
      >
        <div css={[accountSelectorStyles.groupItem]}>
          {flexRender(cell.column.columnDef.aggregatedCell, cell.getContext())}
        </div>
      </div>
    </div>
  )
}

const AccountRowContainer: React.FC<{
  cell: Cell<IAccountSelectorAccount, unknown>
}> = ({ cell }) => {
  return (
    <div css={[accountSelectorStyles.accountRowContainer]}>
      <div css={[accountSelectorStyles.accountItem]}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    </div>
  )
}

export const AccountSelector: React.FC = memo(() => {
  const { selectedAccountIds } = useRdot360AccountContext()
  const {
    searchText,
    setSearchText,
    accountsWithBalances,
    grouping,
    setGrouping,
    sorting,
    setSorting,
    setSelectedIds
  } = useAccountSelectorTableStore()
  const [expanded, setExpanded] = useState<ExpandedState>(true)
  const columns = useMemo(getColumnDefs, [])
  const data = accountsWithBalances

  const dataLookup = useMemo(() => keyBy(data, ({ id }) => id), [data])

  // TODO this is weird if an update happens after checking a box
  useEffect(() => {
    const filteredSelection = selectedAccountIds.filter((x) => dataLookup[x])
    setSelectedIds(filteredSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountIds])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      grouping,
      expanded,
      columnVisibility: defaultVisibilityState
    },
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    manualPagination: true,
    autoResetExpanded: false,
    onGlobalFilterChange: setSearchText,
    getRowId: (originalRow) => originalRow.id,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const rows = table.getRowModel().rows
  const isAllExpanded = table.getIsAllRowsExpanded()

  return (
    <div>
      {searchText.length !== 0 && data?.length === 0 ? (
        <div
          css={{
            border: 'solid 1px #00000044',
            backgroundColor: '#EFF6FB',
            padding: '14px',
            borderLeft: 'solid 4px #4C9DA8',
            textAlign: 'center'
          }}
        >
          No Match Found
        </div>
      ) : (
        <>
          <div
            css={{
              marginBottom: '5px',
              cursor: 'pointer',
              justifyContent: 'flex-end',
              display: 'flex'
            }}
          >
            <Link
              onClick={(e) => {
                table.toggleAllRowsExpanded()
                e.preventDefault()
              }}
            >
              {isAllExpanded ? 'Collapse All' : 'Expand All'}
            </Link>
          </div>
          <div>
            {rows.map((row) => {
              const isGroupRow = row.getIsGrouped()
              const [cell] = row.getVisibleCells()
              if (!isGroupRow && row.original.placeholder) {
                return null
              }

              const hasSubRows =
                row.subRows?.filter((row) => !row.original.placeholder).length >
                0

              return isGroupRow ? (
                <GroupRowContainer
                  key={row.id}
                  cell={cell}
                  isCollapsed={!hasSubRows || !row.getIsExpanded()}
                />
              ) : (
                <AccountRowContainer key={row.id} cell={cell} />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
})
