import { ExpandedState, createColumnHelper } from '@tanstack/react-table'
import { sum } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PercentCell,
  USDCell,
  WidgetTable
} from '../../features/WidgetTable/WidgetTable'
import { useInvestmentsTableStore } from '../../modules/Investments/features/InvestmentsTable'
import { getViewByKey } from '../../modules/Investments/features/InvestmentsTable/shared'
import { getTaxonomyColor } from '../../shared/taxonomy'
import { ICategoryPositionResponseValue } from '../../store/holdingsApi/ICategoryPostitionResponse'
import { HeaderContainer } from '../shared/DetailTables/HeaderContainer'

const getHeaderText = (selectedType: string) => {
  if (selectedType === 'P') {
    return 'Product Type'
  } else if (selectedType === 'S') {
    return 'Sector Class'
  } else {
    return 'Asset Class'
  }
}

export const AssetSummaryTable: React.FC<{
  data?: ICategoryPositionResponseValue[]
  isLoading?: boolean
  isUninitialized?: boolean
  value?: string
  selectedType: string
}> = ({ data, isUninitialized, isLoading, selectedType }) => {
  const navigate = useNavigate()
  const { setDefaultExpanded, setInvestmentsViewByKey } =
    useInvestmentsTableStore()

  const onClick = useCallback(
    (value?: string) => {
      const viewByKey = getViewByKey(selectedType)
      const parameter: ExpandedState = { [`${viewByKey}:${value}`]: true }
      navigate('../investments')
      setDefaultExpanded(parameter)
      setInvestmentsViewByKey(viewByKey)
    },
    [navigate, selectedType, setDefaultExpanded, setInvestmentsViewByKey]
  )
  const columnHelper = createColumnHelper<ICategoryPositionResponseValue>()

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ assetcat }) =>
          assetcat === 'Cash' ? 'Cash & Equivalents' : assetcat,
        {
          id: 'assetcat',
          cell: (props) => (
            <div
              css={{
                display: 'flex'
              }}
              title={props.getValue()}
            >
              <div
                css={{
                  width: '10px',
                  height: '10px',
                  backgroundColor:
                    props.getValue() === 'Total'
                      ? 'none'
                      : `#${getTaxonomyColor(
                          selectedType,
                          props.getValue() || 'Others'
                        )}`,
                  marginTop: '2px'
                }}
              />
              <div
                css={{
                  color: '#1D679D',
                  cursor: 'pointer',
                  paddingLeft: '15px'
                }}
                onClick={() => onClick(props.getValue())}
              >
                {props.getValue()}
              </div>
            </div>
          ),
          header: () => (
            <div css={{ textAlign: 'left' }}>{getHeaderText(selectedType)}</div>
          ),
          footer: () => <div css={{ fontWeight: 'bold' }}>Total</div>,
          size: 200
        }
      ),
      columnHelper.accessor(({ mktval }) => mktval, {
        id: 'mktval',
        cell: (props) => <USDCell value={props.getValue()} />,
        header: (props) => (
          <HeaderContainer
            title="Value"
            isSorted={props.column.getIsSorted()}
            rightAlign={true}
          />
        ),
        footer: (props) => {
          const total = sum(
            props.table
              .getRowModel()
              .rows.map(
                ({ getValue }) => getValue<number>(props.column.id) || 0
              )
          )
          return (
            <div css={{ fontWeight: 'bold' }}>
              {total !== 0 ? <USDCell value={total} /> : ''}
            </div>
          )
        },
        size: 100
      }),
      columnHelper.accessor(({ mktvalpct }) => mktvalpct, {
        id: 'mktvalpct',
        cell: (props) =>
          props.getValue() !== undefined ? (
            <PercentCell value={props.getValue()} />
          ) : (
            ''
          ),
        header: (props) => (
          <HeaderContainer
            title="Portfolio %"
            isSorted={props.column.getIsSorted()}
            rightAlign={true}
          />
        ),
        size: 100
      })
    ],
    [columnHelper, onClick, selectedType]
  )

  return (
    <WidgetTable
      data={data}
      columns={columns}
      isUninitialized={isUninitialized}
      isLoading={isLoading}
      isFooter={!isUninitialized && data && data?.length > 0}
      sort={[{ id: 'mktval', desc: true }]}
      emptyRowCount={8}
    />
  )
}
