import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { isString } from 'lodash'
import { flow } from 'lodash/fp'
import { stringify } from 'query-string'
import { tryAcquireAccessToken } from '../../shared/services/auth'
import type { AppState } from '../shared'
import { getMicrosoftGraphApiConfig } from '../system'

export type AxiosBaseArgs = Pick<
  AxiosRequestConfig,
  | 'method'
  | 'headers'
  | 'params'
  | 'data'
  | 'url'
  | 'paramsSerializer'
  | 'timeout'
>

export const arrayCommaParamsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

export const axiosBaseQuery =
  ({
    getBaseUrl,
    getAuthToken
  }: {
    getBaseUrl: (state: AppState) => string | undefined
    getAuthToken: (state: AppState) => Promise<string | undefined>
  }): BaseQueryFn<AxiosBaseArgs | string, unknown, unknown> =>
  async (configOrString, { getState, signal }) => {
    const config: AxiosBaseArgs = isString(configOrString)
      ? { url: configOrString, method: 'get' }
      : configOrString

    const { headers } = config
    try {
      const state = getState() as AppState
      const baseURL = getBaseUrl(state)
      const token = await getAuthToken(state)
      const result = await axios({
        ...config,
        baseURL,
        headers: { ...headers, Authorization: `Bearer ${token}` },
        signal
      })
      return { data: result.data, meta: result.headers }
    } catch (e) {
      const axiosError = e as AxiosError<{ error?: Error }>
      const error = axiosError?.response?.data?.error
      return error ? { error } : { error: axiosError }
    }
  }

export const getGraphApiBaseUrl = (
  state: AppState,
  version: 'v1.0' | 'beta' = 'v1.0'
) => {
  const base = flow(getMicrosoftGraphApiConfig, (x) => x?.root)(state)
  const v1 = new URL(version, base)
  return v1.href
}

export const getGraphApiAuthToken = (state: AppState) => {
  const scopes = flow(getMicrosoftGraphApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('No scopes provided in configuration for graph api')
  }

  return tryAcquireAccessToken(scopes)
}
